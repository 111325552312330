import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './SendNotification.css';
import Moralis from "moralis";


const CssTextFieldAdmin = styled(TextField)({
  ".	MuiTextField-root": {
    color: "black",
  },
  "& label.Mui-focused": {
    color: "#AD75FC",
  },

  "& MuiInputBase-input": {
    color: "#7963E0 !important",
  },
  "& MuiInputBase-root": {
    color: "red !important",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#AD75FC",
    color: "#7963E0",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "gray",
    },
    "&:hover fieldset": {
      borderColor: "#AF75FF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#AD75FC",
    },
  },
});

const SendNotification = () => {

    const [titleAll, setTitleAll] = useState('')
    const [bodyAll, setBodyAll] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [todosTokens, setTodosTokens] = useState('')


  //send messages to all tokens
  async function getTodosTokens() {
    await Moralis.Cloud.run("getFCMTokens", {}).then((response) => {
      console.log(response);
      setTodosTokens(response);
    });
  }
  useEffect(() => {
    getTodosTokens();
  }, []);



    // Call the cloud function
    const sendNotifications = async () => {
      let array = Object.values(todosTokens);
      console.log(array);
      await Moralis.Cloud.run("SendMessageToAllUsers", { tokens: array, titleNotif: titleAll, bodyNotif: bodyAll }).then((response) => {
        console.log(response);
      });
    };


  return (
    <>
    <h1 style={{ color: "#7963E0" , marginBottom: '2rem'}}>Send push notifications</h1>
          {/* <button onClick={sendNotifications} style={{ marginBottom: "2rem" }}>
            Enviar notificaciones a todos!
          </button> */}

          <CssTextFieldAdmin
            label="Titulo del mensaje"
            fullWidth
            variant="filled"
            style={{ marginBottom: "2rem"}}
            type="text"
            className="input-makeNft md-3"
            onChange={e => setTitleAll(e.target.value)}
          />
          <CssTextFieldAdmin
            label="Cuerpo del mensaje"
            fullWidth
            variant="filled"
           
            style={{ marginBottom: "2rem" }}
            /* error={titulo === ''} */ /* helperText={errorAmount ? errorTextAmount : ''} */ type="text"
            className="input-makeNft md-3"
            onChange={(e) =>setBodyAll(e.target.value)}
          />
         
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} sx={{color: 'red'}} />

          <br />
          <br />

          <button onClick={sendNotifications} className="send-button-admin">
            Send
          </button>

          
    </>
  )
}

export default SendNotification