import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import "./balance-header.css";
import Matic from "../../assets/Matic.png";
import NFTBig from "../../assets/NFT-Big.png";
import { Link } from "react-router-dom";
//context
import { UserContext } from "../../context/userContext";
import { CompartirAddresshatsapp } from "../../utils/Share-social";

const BalanceHeader = () => {
    const { currentAccount, balanceInMatic, fetchNFTOwners, nfts} = useContext(UserContext);
    const [initialLoading, setInitialLoading] = useState(true);
    const [open, setOpen] = useState(false);

  
    const url =
      "https://widget.onramper.com?color=13054c&apiKey=pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0&defaultCrypto=MATIC&supportSell=false&supportSwap=false&defaultFiat=USD&defaultAmount=32&onlyGateways=Moonpay&wallets=MATIC:" +
      currentAccount +
      "&onlyCryptos=MATIC&onlyFiat=EUR,USD,BRL,GBP,CAD&isAddressEditable=false";
  
    function handleClick() {
      setOpen(true);
      navigator.clipboard.writeText(currentAccount);
      setTimeout(() => {
        setOpen(false);
      }, 600);
    }
  
    useEffect(() => {
      setTimeout(() => {
        fetchNFTOwners();
        setInitialLoading(false);
      }, [1500]);
    }, []);
  
    return (
      <>
        <div className="balance-address-header">
          <div id="page-wrap">
            <Container>
              <Row>
                <Col lg="6" md="6" className="balance-headers">
                  <Row className="correccion">
                    <Col className="d-flex flex-row align-items-center space-evenly-between ">
                      <label style={{ fontWeight: 800 }}>Balance</label>
                    </Col>
                    <Col className="d-flex flex-row align-items-center space-evenly-between justify-content-center balance-img">
                      <Link to="/my-nfts">
                        <img
                          src={NFTBig}
                          alt="Nfts"
                        />
                        <label style={{ fontWeight: 700 }}>{nfts === 100 ? "+" : null}&nbsp;{nfts}</label>
                      </Link>
                    </Col>
                    <Col className="d-flex flex-row align-items-center space-evenly-between justify-content-center balance-img">
                      <Link to="/balance">
                        <img
                          src={Matic}
                          alt="Matic"
                        />
                        <label style={{ fontWeight: 700 }}>
                          &nbsp;{balanceInMatic.toString().slice(0, 6)}
                        </label>
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col lg="2" md="1" className="d-xl-block d-lg-block d-md-block d-none"></Col>
                <Col lg="4" md="5" className="d-xl-block d-lg-block d-md-block d-none">
                  <Stack style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <label style={{ fontWeight: 500 }}>My Address</label>
                  </Stack>
                  <Stack direction="horizontal" className="AdressAndLogos">
                    <label onClick={handleClick}>
                      {currentAccount.slice(0, 7)}...{currentAccount.slice(35)}
                    </label>
                    <Tooltip
                      title="Copied"
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <i className="fa-regular fa-clone ml-2" onClick={handleClick} ></i>
                    </Tooltip>
                    {/*<i className="fa-solid fa-share-nodes"></i>*/}
                    <div>
                      <CompartirAddresshatsapp
                        _url={`${currentAccount}  https://pieceofcakewallet.com/address/${currentAccount}`}
                      />
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Container>
  
          </div>

        </div>
      </>
    );
  };

export default BalanceHeader