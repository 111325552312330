import "./Visualizer-6551.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import { ReactHiererchyChart } from 'react-hierarchy-chart';
import { Container, Col, Row } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { BiCopy } from "react-icons/bi";
import PacmanLoader from "react-spinners/PacmanLoader";
import WalletBase from "../../img/walletBase.png";
import Checked from "../../img/Checked.svg";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import { TiThMenu } from "react-icons/ti";

import { UserContext } from "../../context/userContext";

const Visualizer6551 = () => {
    const { currentAccount } = useContext(UserContext);
    const [tree, setTree] = useState(null);
    const [isVertical, setIsVertical] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [renderizado, setRenderizado] = useState(false);
    const walletRef = useRef(null);

    useEffect(() => {
        if(renderizado){
            console.log("Ya renderizo!");
        }else{
            setRenderizado(true);
            console.log("currentAccount", currentAccount);
        }
    }, [currentAccount]);

    useEffect(() => {
        if(renderizado){
            startWebsocket(currentAccount, "Polygon Mainnet");
            console.log("Llamo a startWebsocket");
        }else{
            setRenderizado(true);
            console.log("Esperando");
        }
    }, [renderizado]);

    useEffect(() => {
      console.log("tree: ", tree);
    }, [tree]);

    useEffect(() => {
      if (!isLoading) {
        if (walletRef.current) {
          walletRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    }, [isLoading, isVertical]);

    const handleError = (e) => {
      e.target.src =
        "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"; // Aquí pon el url de tu imagen personalizada
    };

    function handleCopy(address) {
      navigator.clipboard.writeText(address);
      alert("copied");
    }
  
    const startWebsocket = async (address, chainId) => {
      setIsLoading(true);
      try {
        const websocketEndpoint = `wss://t1so8a8nn2.execute-api.us-west-2.amazonaws.com/production?walletRoot=${address}&depth=0&chain=${chainId}`; //wss://t1so8a8nn2.execute-api.us-west-2.amazonaws.com/production ---- //wss://hadoifc8gk.execute-api.us-west-2.amazonaws.com/Prod
        const websocket = new WebSocket(websocketEndpoint);
        let messageArray = [];
  
        websocket.onopen = () => {
          console.log('Conexión establecida al WebSocket', websocket);
        };
  

        websocket.onmessage = async (event) => {
          const message = JSON.parse(event.data);
          console.log('mensaje', message)
  
  
          if(message.statusCode === 200){
            console.log('200', message.message)
          }
          if(message.statusCode === 201){
  
            messageArray.push(message);
  
          } else if (message.statusCode === 202) {
            console.log('received 202', message.message)
              console.log('array', messageArray)
              let fullMessage = "";
              messageArray.forEach((msg) => {
                  const buffer = Buffer.from(msg.message, "base64");
                  const decodedChunk = buffer.toString("utf-8");
                  fullMessage += decodedChunk;
                });
              console.log('fullmessage',fullMessage)
              try {
                const parsedData = JSON.parse(fullMessage);
                setTree([parsedData]);
                console.log(parsedData);
  
  
              } catch (error) {
                console.error("Error parsing JSON:", error);
              }
  
              setIsLoading(false);
            }
  
  
        };
  
        websocket.onerror = (error) => {
          console.error("Error en la conexión WebSocket:", error);
          setIsLoading(false);
        };
  
        return () => {
          console.log("cierro el webSocket")
          websocket.close();
        };
      } catch (error) {
        alert(error);
        setIsLoading(false);
      }
    };
    
    return (
      <>
        <div>
          <Navigation />
          <GoBack value={'6551 Visualizer'} link={'/home'}/>
            <Container>
              <Col sm={1} md={3}>
                  <TiThMenu className="Menu" onClick={() => setIsVertical(false)} />
                  <TiThMenu
                    className="Menu-inverted"
                    onClick={() => setIsVertical(true)}
                  />
                </Col>
            </Container>
            <div style={{ overflowX: "auto", width: "100%" }}>
            <Container>
              <div className="hierarchy-viewer" style={{ marginLeft: "2rem" }}>
                {!isLoading && tree ? (
                  <ReactHiererchyChart
                    nodes={tree}
                    lineColor="red"
                    direction={isVertical ? "vertical" : "horizontal"}
                    randerNode={(node) => {
                      return (
                        <>
                          <div className={`contenedor`}>
                            <div className="contenedor_tarjeta">
                              <div className="remplazohref">
                                <figure style={{ height: "100%" }}>
                                  {node.metadata || node.token_wallet ? (
                                    <div className="frontal">
                                      <div className="img-container">
                                        {node.metadata !== null &&
                                        node.token_owner ? (
                                          <img
                                            src={
                                              JSON.parse(
                                                node?.metadata
                                              )?.image?.slice(0, 12) ===
                                              "ipfs://ipfs/"
                                                ? "https://ipfs.io/ipfs/" +
                                                  JSON.parse(
                                                    node?.metadata
                                                  )?.image?.slice(12)
                                                : JSON.parse(
                                                    node?.metadata
                                                  )?.image?.slice(0, 7) === "ipfs://"
                                                ? "https://ipfs.io/ipfs/" +
                                                  JSON.parse(
                                                    node?.metadata
                                                  )?.image?.slice(7)
                                                : JSON.parse(node?.metadata)?.image
                                            }
                                            alt="card-user"
                                            style={{
                                              borderRadius: "15px",
                                            }}
                                            onError={handleError}
                                          />
                                        ) : (
                                          <>
                                            <img
                                            src={WalletBase}
                                            alt=""
                                            ref={walletRef}
                                            id="wallet-inicio"
                                          />
                                          </>
                                        )}
                                        <div className="title-frontal">
                                          {node.metadata && (
                                            <p style={{ color: "white" }}>
                                              {JSON.parse(node.metadata).name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <img
                                      src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"
                                      alt=""
                                    />
                                  )}
                                  <figcaption className="trasera">
                                    <Container className="back-container">
                                      <div className="back">
                                        <div style={{textAlign: 'right'}}>
                                          {node.hasTba === "true" &&  <img src={Checked} alt="" width="20px" className="mx-1" />}

                                        </div>
                                        {node.token_wallet && (
                                          <label className="label-back">
                                            Wallet Address:
                                            <span className="span-back">
                                              {node.token_wallet}
                                            </span>
                                            <Tooltip title="Copy">
                                              <span>
                                                <BiCopy
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleCopy(node.token_wallet)
                                                  }
                                                />
                                              </span>
                                            </Tooltip>
                                          </label>
                                        )}
                                        {node.token_owner && (
                                          <label className="label-back">
                                            Owner Address :
                                            <span className="span-back">
                                              {node.token_owner}
                                            </span>
                                            <Tooltip title="Copy">
                                              <span>
                                                <BiCopy
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleCopy(node.token_owner)
                                                  }
                                                />
                                              </span>
                                            </Tooltip>
                                          </label>
                                        )}

                                        {node.token_id && (
                                          <label className="label-back">
                                          
                                            Token Id:
                                            <span className="span-back">
                                              {node.token_id}
                                            </span>
                                          </label>
                                        )}

                                        {node.amount && (
                                          <label className="label-back">
                                          
                                            Token Amount:
                                            <span className="span-back">
                                              
                                              {node.amount}
                                            </span>
                                          </label>
                                        )}
                                      </div>
                                    </Container>
                                  </figcaption>
                                </figure>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  />
                ) : (
                  <Container className="loader-container">
                    <PacmanLoader
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      color="#ba74f2"
                      className="loader"
                    />
                  </Container>
                )}
              </div>
            </Container>
          </div>
        </div>
      </>
    );
}
  
export default Visualizer6551;