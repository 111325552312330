import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './NftDetails.css';
import { ethers } from 'ethers';
import { Container, Col, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import GridLoader from "react-spinners/GridLoader";
import Navigation from '../navigation/Navigation'
import RecentDestinations from '../recent-destinations/recent-destinations'
import GoBack from '../goBack/GoBack'
import Scanner from '../scanner/scanner'
import FooterOpciones from '../footer-completo/footer-opciones'
import PerroReggae from '../../assets/perroReggae.jpg'
import USDT from '../../assets/NFT.png'
import Camera from '../../assets/Camera.png'
import Tabs from '../../utils/tabs/Tabs'
import TransferNFT from '../../utils/TransferNFT/TransferNFT'
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ImageGroup, Image } from 'react-fullscreen-image'
import Moralis from 'moralis';
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { useNavigate } from 'react-router-dom'

import Modales from "../../utils/modal/modal";
import { SocialMediaTwitter, SocialMediaFacebook, SocialMediaLinkedin, SocialMediaWhatsapp, SocialMediaTelegram, SocialMediaEmail, CompartirAddresshatsapp } from "../../utils/Share-social"

//context
import { UserContext } from "../../context/userContext";
import Loader from '../../utils/loader/Loader';
import ImageModal from '../../utils/modal/ImageModal'
import FooterDesktopCompleto from '../footer-desktop-completo/footer-desktop-completo';
import BalanceHeader from '../balance-header/balance-header';


const NftDetails = () => {
  let indexParams = useParams();
  let { hashUrl } = useParams();
  const index = indexParams["*"]
  const indexParam = indexParams["index"]

  //trim the URL from useParams hook
  const fields = index.split('/');
  const urlParams = fields[0] + '/' + fields[1];


  const { listOfNft, provider, isVisible, loader, openConfirmationModal, currentAccount, setIsVisible } = useContext(UserContext);
  const [nft, setNft] = useState('')
  const [url, setUrl] = useState('')
  const [video, setVideo] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [stats, setStats] = useState('');
  const [properties, setProperties] = useState('')
  const [rankings, setRankings] = useState('')
  const [metadataHashLanding, setMetadataHashLanding] = useState('');
  const [modalIsShown, setModalIsShown] = useState(false);
  const [fullScreen, setFullScreen] = useState(false)
  const [svgImg, setSvgImg] = useState(false)
  const [tokenAddress, setTokenAddress] = useState('')
  const [tokenId, setTokenId] = useState('')
  const [receiver, setReceiver] = useState("");
  const navigate = useNavigate();

  const isVideo = async (_img, dato) => {
    const imagen = ((_img.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + (_img.slice(12)) : ((_img.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + (_img.slice(7)) : _img
    try {
      var req = await fetch(imagen, { method: 'HEAD' });
      if (req.headers.get('content-type') === 'video/mp4') {
        setVideo(true);
      } else if (req.headers.get('content-type') === 'image/svg+xml') {
        setSvgImg(true)
        setVideo(false)
      } else {
        setVideo(false)
      }
    } catch {
      const _metadata = JSON.parse(dato.metadata)
      _metadata.image = 'https://ipfs.io/ipfs/QmbDz7TiiwtaF2E8x5Ngj2XfjDL8PCh5zPYLv2ctGHxMt2'
      const _dato = dato
      _dato.metadata = JSON.stringify(_metadata)
      setNft(_dato)
    }
    setInitialLoading(false)
  }

  const openFullscreen = () => {
    setFullScreen(!fullScreen)
  }

  const getAttributes = async (nft) => {
    let newProperties = [];
    let newStats = [];
    let newRankings = [];
    setUrl(((JSON.parse(nft.metadata)).image).toString())
    let metadata = JSON.parse(nft.metadata)
    if (metadata.attributes) {
      metadata.attributes.forEach((attr) => {
        if (attr.display_type === "number") {
          newStats = [...newStats];
          newStats.push({
            display_type: "number",
            trait_type: attr.trait_type,
            value: Number(attr.value),
          });
        } else if (typeof attr.value === "string") {
          newProperties = [...newProperties];
          newProperties.push({
            trait_type: attr.trait_type,
            value: attr.value,
          });
        } else if (typeof attr.value === "number") {
          newRankings = [...newRankings];
          newRankings.push({
            trait_type: attr.trait_type,
            value: Number(attr.value),
          });
        } else {
          console.error("hay algo mal aca");
        }
        setStats(newStats);
        setProperties(newProperties);
        setRankings(newRankings);
      });
    }
  }


  const getResults = async () => {
    if (!listOfNft) return
    listOfNft.forEach((dato) => {
      if (dato.token_hash === indexParam) {
        setMetadataHashLanding(((dato.token_uri).split("/"))[4])
        setNft(dato)
        isVideo(JSON.parse(dato.metadata).image, dato)
      }
    });

  }

  const getSharingInfo = async (_nft) => {
    if (_nft.token_address && _nft.token_id) {
      setTokenAddress(_nft.token_address);
      setTokenId(_nft.token_id);
    }
  }

  React.useEffect(() => {
    getResults();
    getAttributes(nft)
  }, [hashUrl, provider])

  React.useEffect(() => {
    getAttributes(nft);
    getSharingInfo(nft);
  }, [nft])

  const modalToParent = () => {
    setModalIsShown(false);
  };


  if (initialLoading) {
    return (<GridLoader color='#AE74FF' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />);

  } else {
    return (
      <>

        {(openConfirmationModal.open) &&
          <Modales
            modalToParent={modalToParent}
            action={"Transfer Done"}
            _from={currentAccount}
            _icon={"check"}
            _title={"Transaction Success"}
            _text={"Your NFT was transfered succesfully."}
          ></Modales>
        }
        <div className='min-height-margin'>
          <Navigation />
          <BalanceHeader />
          <GoBack value='NFT Details' link={urlParams === 'my-nfts/send' ? '/my-nfts/send' : '/my-nfts'} />
          <Container className='nft-detail'>
            {/* Lista de NFTs pertenecientes a esta address */}
            {isVisible && <RecentDestinations setReceiver={setReceiver} param="send" />}
            <Row className="my-2">
              <Col sm={12} md={6} lg={5} className="p-1 d-flex flex-column align-items-center justify-content-center">
                  <div className='title-wrapper d-xxl-none d-xl-none d-lg-none d-md-none'>
                    <span className='title-nft'>{(nft) && JSON.parse(nft.metadata).name}</span>
                    <span>Supply: {nft.amount}</span>
                  </div>
                  <div className="img-container">
                    {video ?
                      <ReactPlayer
                        height='300px'
                        width='420px'
                        url={nft && (((JSON.parse(nft?.metadata)?.image)?.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : (((JSON.parse(nft?.metadata)?.image)?.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image}
                        loop
                        playing
                        controls
                      />
                      : (!svgImg) ? <img src={nft && (((JSON.parse(nft?.metadata)?.image)?.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : (((JSON.parse(nft?.metadata)?.image)?.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image} id='imagePrev' className='nftImage' alt='nftImage' onClick={openFullscreen} /> : <img src={nft && (((JSON.parse(nft?.metadata)?.image)?.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : (((JSON.parse(nft?.metadata)?.image)?.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image} id='imagePrev' className='nftImage' alt='nftImage' onClick={openFullscreen} />
                    }
                    {fullScreen && <ImageModal _image={(((JSON.parse(nft?.metadata)?.image)?.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : (((JSON.parse(nft?.metadata)?.image)?.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image} />}
                  </div>
              </Col>
              <Col sm={12} md={6} lg={7} className='center-flex-bottom'>
                <div className='description-size'>
                  <div className='title-wrapper-desktop d-xl-block d-lg-block d-md-block d-none'>
                    <span className='title-nft'>{(nft) && JSON.parse(nft.metadata).name}</span>
                    <span >Supply: {nft.amount}</span>
                  </div>
                  <div className="description-wrapper-details">
                    <label style={{ fontWeight: '700', fontSize: '15px' }}>Description</label>
                    <p style={{ fontSize: '12px', margin: '0 0 10px 0' }}>{JSON.parse(nft.metadata).description}</p>
                  </div>
                  <TransferNFT receiver={receiver} setReceiver={setReceiver} _nft={nft} />
                  {(loader) &&
                    <Modales modalToParent={modalToParent}
                      _function={"processing"}
                      _title={" "}
                      _icon={"no"}
                      _name={"Processing transaction"}
                      _description={"Please do not close this site"}
                    ></Modales>
                  }
                  <div className='share-wrapper'>
                    <label style={{ color: '#6F45C1' }}>Share: </label>
                    <SocialMediaWhatsapp _url={`https://pieceofcakewallet.com/landingpage/${tokenAddress}/${tokenId}`} />
                    <SocialMediaTwitter _url={`https://pieceofcakewallet.com/landingpage/${tokenAddress}/${tokenId}`} />
                    <SocialMediaLinkedin _url={`https://pieceofcakewallet.com/landingpage/${tokenAddress}/${tokenId}`} />
                    <SocialMediaTelegram _url={`https://pieceofcakewallet.com/landingpage/${tokenAddress}/${tokenId}`} />
                    <SocialMediaFacebook _url={`https://pieceofcakewallet.com/landingpage/${tokenAddress}/${tokenId}`} />
                    <SocialMediaEmail _url={`https://pieceofcakewallet.com/landingpage/${tokenAddress}/${tokenId}`} />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Tabs stats={stats} rankings={rankings} properties={properties} />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterOpciones />
        <FooterDesktopCompleto />
      </>
    )
  }
}


export default NftDetails