import React, { useContext } from 'react'
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/userContext";

import './footer-desktop-completo.css'
// import { Container, Row, Col } from 'react-bootstrap'

import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Stack } from "react-bootstrap";
import "./footer-desktop-completo.css"
import Instagram from '../../assets/icons/instagram icon.png';
import Linkedin from '../../assets/icons/linkedin icon.png';
import Discord from '../../assets/icons/Symbol.png';
import Twitter from '../../assets/icons/Twitter.png';

const FooterDesktopCompleto = () => {

  const { register } = useForm()

  const { handleChange } = useContext(UserContext);

  return (
    <section className='freeze-footer d-none d-lg-block footer-desktop'>
      <div className='background-footer'>
        <Container id="footer-container" style={{alingItems: 'center'}}>
          {/* <Stack className='rights-reserved'> */}
          <Row className="w-75">
            <Col className="d-flex justify-content-center px-0" lg={3} xl={3}>
              © 2023 DApps Factory
            </Col>
            <Col className="d-flex justify-content-center px-0" lg={3} xl={3} id="rights-reserved">
              All rights reserved /
            </Col>
            <Col className="d-flex justify-content-center px-0 opacity-footer-text" lg={3} xl={3}>
              <a href={"https://www.pieceofcakewallet.com/privacypolicy"} target="_blank" rel="noreferrer">Privacy policy</a>
            </Col>
            <Col className="d-flex justify-content-center px-0 opacity-footer-text" lg={3} xl={3}>
              <a href={"https://www.pieceofcakewallet.com/termsandconditions"} target="_blank" rel="noreferrer">Terms and Conditions</a>
            </Col>
          </Row>
          {/* </Stack> */}
        </Container>
      </div>
    </section>
  )
}

export default FooterDesktopCompleto