import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useWalletConnectClient } from '../../context/walletConnectContext';
import Tooltip from '@mui/material/Tooltip';

const SessionCard = ({ topic = undefined, icon, name, url, address, chain }) => {

    const { disconnect_session } = useWalletConnectClient();

    let chainName;

    switch (chain) {
        case '1':
            chainName = 'Ethereum Mainnet'
            break;
        case '137':
            chainName = 'Polygon Mainnet'
            break
        case '100':
            chainName = 'Gnosis'
            break
        case '10':
            chainName = 'Optimism'
            break
        case '42161':
            chainName = 'Arbitrum'
            break
        default:
            chainName = `Chain id: ${chain}`;
            break;
    }

    return (
        <>
            <Row className="p-2 my-1 session-card justify-content-around">
                <Col lg={2} xs={2} className="d-flex justify-content-center align-items-start pt-2"><img src={icon} width="45px" height="45px" alt="Connect" /></Col>
                <Col lg={5} xs={8} className="px-3">
                    <Row><p className="m-0 p-0">{name}</p></Row>
                    <Row><p className="m-0 p-0">{url}</p></Row>
                    <Row><p className="m-0 p-0">{chainName}</p></Row>
                    <Row><p className="m-0 p-0">{address.slice(0, 9)}...{address.slice(33)}</p></Row>
                </Col>
                <Col lg={2} xs={2} className="d-flex justify-content-center align-items-start pt-3" onClick={() => disconnect_session(topic)}>
                    <Tooltip title="Delete session" arrow>
                        <i className="fa-solid fa-trash-can" aria-hidden="true" /> 
                    </Tooltip>
                </Col>
            </Row>
        </>
    )
}

export default SessionCard