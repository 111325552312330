import React, { useContext, useState } from 'react'
import './Navigation.css'
import { Container, Row, Col, NavDropdown, DropdownButton, Dropdown } from 'react-bootstrap'
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import Burger from '../../assets/hamburguesa.png'
import Installpwa from "../../utils/InstalPWA/instalpwa";
import { useForm } from "react-hook-form";
import Torta from "../../assets/TortaSola.png";
import PieceOfCake from "../../assets/PoC.png";
import { UserContext } from "../../context/userContext";
import Notification from '../../utils/notification/Notification';


const Navigation = () => {

    const { disconnect, handleChange } = useContext(UserContext);
    const { register } = useForm();

    //dropdown for transfer
    const [show, setShow] = useState(false);
    /*const showDropdown = (e) => {
        setShow(true);
    }
    const hideDropdown = e => {
        setTimeout(()=>{
            setShow(false)
          }, 1000);
    }*/

    const reverseDropdown = e => {
        setShow(!show);
        setWalletShow(false);
        setNftsShow(false);
    }


    //dropdown for my nfts
    const [nftsShow, setNftsShow] = useState(false);

    const reverseNftsDropdown = e => {
        setNftsShow(!nftsShow);
        setShow(false);
        setWalletShow(false);
    }


    //dropdown for my wallet
    const [walletShow, setWalletShow] = useState(false);
    /*const showWalletDropdown = (e) => {
        setWalletShow(true);
    }
    const hideWalletDropdown = e => {
        setWalletShow(false);
    }*/

    const reverseWalletDropdown = e => {
        setWalletShow(!walletShow);
        setNftsShow(false);
        setShow(false);
    }

    return (
        <Container>
            <Row className='d-flex align-items-center'>
                <Col className='d-flex flex-row' xs={8} sm={8} md={8} lg={3}>
                    <Link to='/home' className='logo'>
                        <img src={Torta} alt="" />
                        <img src={PieceOfCake} alt="" />
                    </Link>
                </Col>
                <Col md={9} className='d-none d-lg-block d-xl-block d-xxl-block  header-desktop'>
                        <div><a id="home" className="menu-item" href="/home">Home</a></div>
                    <NavDropdown title="NFTs"
                        id="collasible-nav-dropdown"
                        show={nftsShow}
                        /*onMouseEnter={showDropdown}
                        onMouseLeave={hideDropdown}*/
                        onClick={reverseNftsDropdown}
                    >
                        <Link id="contact" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/my-nfts">My NFTs</Link>
                        <NavDropdown.Divider />
                        <Link id="about" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/view-friend-nft">Search friend's NFT</Link>
                        <NavDropdown.Divider />
                        <Link id="about" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/6551-visualizer">6551 Visualizer</Link>
                    </NavDropdown>
                    <NavDropdown title="Send"
                        id="collasible-nav-dropdown"
                        show={show}
                        /*onMouseEnter={showDropdown}
                        onMouseLeave={hideDropdown}*/
                        onClick={reverseDropdown}
                    >
                        <Link id="contact" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/send">NFTs / Matic</Link>
                        <NavDropdown.Divider />
                        <Link id="about" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/email">Encrypted Email</Link>
                    </NavDropdown>
                    <NavDropdown title="My Wallet"
                        id="collasible-nav-wallet-dropdown"
                        show={walletShow}
                        /*onMouseEnter={showWalletDropdown}
                        onMouseLeave={hideWalletDropdown}*/
                        onClick={reverseWalletDropdown}
                    >
                        <Link id="contact" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/profile">Profile</Link>
                        <NavDropdown.Divider />
                        <Link id="about" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/receive">My address QR</Link>
                        <NavDropdown.Divider />
                        <Link id="contact" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/balance">Balance</Link>
                        <NavDropdown.Divider />
                        <Link id="contact" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/agenda">Agenda</Link>
                        <NavDropdown.Divider />
                        <Link id="contact" className="menu-item" style={{display:'flex', justifyContent:'center'}} to="/connect-wallet">Wallet connect</Link> 
                    </NavDropdown>
                    <Notification />

                    <label id="contact" className="send-button menu-item" onClick={async () => await disconnect()}>Logout</label>
                    <Installpwa></Installpwa>
                </Col>
                <Col className='burger-pwa d-xxl-none d-xl-none d-lg-none' xs={4} sm={4}>
                <Notification />

                    <Menu right customBurgerIcon={<img src={Burger} alt='' />} >
                        <Link id="home" className="menu-item" to="/home">Home</Link>
                        <Link id="contact" className="menu-item" to="/profile">Profile</Link>
                        <Link id="contact" className="menu-item" to="/my-nfts">My NFTs</Link>
                        <Link id="contact" className="menu-item" to="/send">Send</Link>
                        <Link id="about" className="menu-item" to="/receive">Receive</Link>
                        <Link id="about" className="menu-item" to="/view-friend-nft">Search friend's NFT</Link>
                        <Link id="about" className="menu-item" to="/6551-visualizer">6551 Visualizer</Link>
                        <Link id="about" className="menu-item" to="/email">Inbox Encrypted Email</Link>
                        {/*<a id="about" className="menu-item" href="/send-message">Send Encrypted Email</a>*/}
                        <Link id="contact" className="menu-item" to="/balance">Balance</Link>
                        <Link id="contact" className="menu-item" to="/agenda">Agenda</Link>
                        <Link id="contact" className="menu-item" to="/connect-wallet">Wallet connect</Link>
                        <label id="contact" className="menu-item" onClick={async () => await disconnect()}>Logout</label>
                    </Menu>
                    <Installpwa></Installpwa>
                </Col>

            </Row>
        </Container>
    )
}

export default Navigation