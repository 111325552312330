import React from 'react'
import './NFT.css'
import { Container, Col, Row } from 'react-bootstrap'
import Navigation from '../navigation/Navigation';
import GoBack from '../goBack/GoBack';
import Footer from '../footer/footer';
import Perro from '../../assets/perroReggae.jpg'
import FooterOpciones from '../footer-completo/footer-opciones';

const Nft = () => {
  return (
    <>
     <div style={{ minHeight:'calc(100vh - 98px )'}}>
    <Navigation />
    <GoBack value="NFT"/>
    <Container>
        <Row >
          <Col className='nfts-list'>
            <img src={Perro} alt='Perrito' width='100px' />
          </Col>
          <Col className='nfts-list'>
            <img src={Perro} alt='perrito' width='100px'/>
          </Col>
        </Row>
        <Row>
          <Col className='nfts-list'>
            <img src={Perro} alt='Perrito' width='100px' />
          </Col>
          <Col className='nfts-list'>
            <img src={Perro} alt='perrito' width='100px'/>
          </Col>
        </Row>
        <Row>
          <Col className='nfts-list'>
            <img src={Perro} alt='Perrito' width='100px' />
          </Col>
          <Col className='nfts-list'>
            <img src={Perro} alt='perrito' width='100px'/>
          </Col>
        </Row>
        
     </Container>
     </div>
     <FooterOpciones />
     
    </>
  )
}

export default Nft