import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import GridLoader from "react-spinners/GridLoader";
import Tooltip from "@mui/material/Tooltip";
import "./Home.css";
import FooterOpciones from "../footer-completo/footer-opciones";
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";
import Matic from "../../assets/Matic.png";
import NFTBig from "../../assets/NFT-Big.png";
import QRCode from "../../assets/QRcode.png";
import Wallet from "../../assets/Group.png";
import Balance from "../../assets/balance.png";
import Camera from "../../assets/Camera.png";
import Card from "../../assets/Card.png";
import Navigation from "../navigation/Navigation";
import { Link } from "react-router-dom";
//context
import { UserContext } from "../../context/userContext";
import { CompartirAddresshatsapp } from "../../utils/Share-social";

import Image from 'react-bootstrap/Image'

import TwoPhones from "../../assets/landing/landingHome.png";
import BalanceHeader from "../balance-header/balance-header";



const Home = () => {
  const {
    currentAccount,
    listOfNft,
    balanceInMatic,
    fetchNFTOwners,
    loader /*, reSyncMetadata*/,
  } = useContext(UserContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { register } = useForm();

  const { setImage, setShowImage, handleChange, nfts } =
    useContext(UserContext);

  const navigate = useNavigate();

  const url =
    "https://widget.onramper.com?color=13054c&apiKey=pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0&defaultCrypto=MATIC&supportSell=false&supportSwap=false&defaultFiat=USD&defaultAmount=32&onlyGateways=Moonpay&wallets=MATIC:" +
    currentAccount +
    "&onlyCryptos=MATIC&onlyFiat=EUR,USD,BRL,GBP,CAD&isAddressEditable=false";

  function handleClick() {
    setOpen(true);
    navigator.clipboard.writeText(currentAccount);
    setTimeout(() => {
      setOpen(false);
    }, 600);
  }

  useEffect(() => {
    setTimeout(() => {
      fetchNFTOwners();
      //reSyncMetadata()
      setInitialLoading(false);
    }, [1500]);
  }, []);

  if (initialLoading) {
    return (
      <GridLoader
        color="#AE74FF"
        style={{ position: "fixed", margin: "auto", inset: "45%" }}
      />
    );
  } else {
    return (
      <>
        <div id="Home" className="min-height-margin">
          <Navigation pageWrapId={"page-wrap"} outerContainerId={"Home"} />

          <BalanceHeader/>

          <Container  className='d-md-none d-lg-none d-xl-none d-xxl-none d-sm-block'>
            <Stack style={{ marginTop: "10px", marginBottom: "10px" }}>
              <label style={{ fontWeight: 500 }}>My Address</label>
            </Stack>
            <Stack direction="horizontal" className="AdressAndLogos">
              <label onClick={handleClick}>
                {currentAccount.slice(0, 7)}...{currentAccount.slice(35)}
              </label>
              <Tooltip
                title="Copied"
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <i className="fa-regular fa-clone ml-2" onClick={handleClick} ></i>
              </Tooltip>
              {/*<i className="fa-solid fa-share-nodes"></i>*/}
              <div>
                <CompartirAddresshatsapp
                  _url={`${currentAccount}  https://pieceofcakewallet.com/address/${currentAccount}`}
                />
              </div>
            </Stack>
          </Container>

          {loader ? (
            <GridLoader
              color="#AE74FF"
              style={{ position: "fixed", margin: "auto", inset: "45%" }}
            />
          ) : (
            <Container
              className="d-flex flex-column align-items-center justify-content-evenly"
              style={{ minHeight: "50vh" }}
            >
              <Row className="img-box-row">
                <Col md="5" className="d-xl-block d-lg-block d-md-block d-none" >
                  <Image className='img-fluid' src={TwoPhones} alt="two phones" />
                </Col>
                <Col md="7">
                  <Row>
                    <Col md="4" xs="6">
                      <Link to="/my-nfts">
                        <div className="Box">
                          <img src={NFTBig} alt="Nfts"  />
                          <label> MY NFT </label>
                        </div>
                      </Link>
                    </Col>
                    <Col md="4" xs="6">
                      <label htmlFor="file-upload">
                        <div className="Box">
                          <img src={Camera} alt="Nfts"  />
                          <label> CREATE NFT</label>
                          <input
                            type="file"
                            id="file-upload"
                            onInput={handleChange}
                            style={{ display: "none" }}
                            {...register("picture")}
                          />
                        </div>
                      </label>
                    </Col>
                    <Col md="4" xs="6">
                      <Link to="/send">
                        <div className="Box">
                          <img src={Wallet} alt="Nfts"  />
                          <label> SEND</label>
                        </div>
                      </Link>
                    </Col>
                    <Col md="4" xs="6">
                      <Link to="/receive">
                        <div className="Box">
                          <img src={QRCode} alt="Nfts"  />
                          <label> RECEIVE </label>
                        </div>
                      </Link>
                    </Col>
                    <Col md="4" xs="6">
                      <Link to="/balance">
                        <div className="Box">
                          <img src={Balance} alt="Nfts"  />
                          <label> BALANCE </label>
                        </div>
                      </Link>
                    </Col>
                    <Col md="4" xs="6">
                      <a href={url} target="_blank">
                        <div className="Box">
                          <img src={Card} alt="Nfts"  />
                          <label> ADD FUNDS </label>
                        </div>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        </div>
        <FooterOpciones />
        <FooterDesktopCompleto />
      </>
    );
  }
};

export default Home;
