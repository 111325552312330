import React, {useContext} from 'react'
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/userContext";
import { useWalletConnectClient } from '../../context/walletConnectContext';
import './footer-opciones.css'
// import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Home from '../../assets/icons/home.png';
import Create from '../../assets/icons/Create.png';
import NFT from '../../assets/NFT-Big.png';
import Send from '../../assets/icons/Send.png';
import Modales from "../../utils/modal/modal";

const FooterOpciones = () => {

  const { register } = useForm()
  
  const { handleChange  } = useContext(UserContext);

  const { openTransactionModal, setOpenTransactionModal } = useWalletConnectClient();

  // comunication with modal submit
  const modalToParent = () => {
    setOpenTransactionModal({
      open: false,
      data: openTransactionModal.data,
    });
  };

  return (
    <>
    {/* Modal de transaccion */}
    {(openTransactionModal.open) &&
        <Modales
          modalToParent={modalToParent}
          _title={'Wallet connect transaction'}
          _function={'wallet_connect'}
          _transactionData={openTransactionModal.data}
        ></Modales>}

      <section className='freeze-footer d-xxl-none d-xl-none d-lg-none'>
        <div className="footer-opciones">
          <div>
            <Link to='/home'>
              <div>
                <img src={Home} alt='Home' width='25px' />
                <p>Home</p>
              </div>
            </Link>
          </div>
          <div>
            <Link to='/my-nfts'>
              <div>
              <img src={NFT} alt='Home' width='26px' />
                <p>NFT</p>
              </div>
            </Link>
          </div>
          <div >     
              <label htmlFor='file-upload' className="footer-upload">
                <img src={Create} alt='Home' width='25px' />
                <p>Create</p>
                <input type="file" id='file-upload' onInput={handleChange} style={{display: 'none'}}{...register('picture')} />
              </label>
          </div>
          <div>
            <Link to='/send'>
              <div>
                <img src={Send} alt='Home' width='25px' />
                <p>Send</p>
              </div>
            </Link>
          </div>
        </div>
       {/*  <Footer /> */}
      </section>
      
    </>
  )
}

export default FooterOpciones