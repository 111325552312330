import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import LogoDapps from "../../assets/LogoDapps.png";
import LogoDappsSvg from "../../assets/Torta.png";
import { Container, Col, Row, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import './ClaimNft.css';
//context
import { UserContext } from "../../context/userContext";
import Moralis from 'moralis';

const ClaimNft = () => {
  const { connectClaim, currentAccount, setCode, code, setTemplateId } = useContext(UserContext);

  let { hash } = useParams();
  let navigate = useNavigate();
  const ClaimNft = "You are logged in with: " + currentAccount;
  const ClaimOtraCuenta = "This NFT was claimed by other account";
  const ClaimSuccessfull = "Your claim was successfull!";
  const [info, setInfo] = useState();
  const [reclamado, setReclamado] = useState();
  const [reclamadoPor, setReclamadoPor] = useState();
  const [searchParams] = useSearchParams();




  const goMisNfts = () => {
    navigate('/my-nfts');
  }

  const getCodeInfo = async () => {
    const info = await Moralis.Cloud.run("GetCodeInfo", { id: code });
    console.log(info);
    console.log(info.attributes)
    console.log(info.attributes.claimed)
    setInfo(info);
    setReclamado(info.attributes.claimed)
    setReclamadoPor(info.attributes.to)
  }

  useEffect(() => {
    getCodeInfo();
    setCode(hash);
    if (searchParams.get('templateId')) {
      setTemplateId(searchParams.get('templateId'));
    }
  }, [hash, code]);


  if (!currentAccount) {
    return (<>
      {/*<h1 style={{textAlign: 'center', fontSize: '1rem', marginTop: '0.5rem'}}>{ClaimNft}</h1>*/}
      <div className="centrar-todo">
        <Container>
          <Stack
            className="d-flex flex-column align-items-center justify-content-evenly tamaño-imagenes"
            style={{ marginBottom: "1rem", alignItems: "center" }}>
            {/*           <img src={LogoDapps} alt="Dapps Factory" width="256px" />
   */}          <img src={LogoDappsSvg} alt="Dapps Factory" />
          </Stack>
          <Stack className="form-width">
            <Button onClick={connectClaim}>Login and claim your NFT for free!</Button>
          </Stack>
          <Stack>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>{(reclamado) ? <>{"This NFT was alredy claimed by an account ended in '0x..." + reclamadoPor?.slice(39, 42) + "'"}</> : <></>}</div>
          </Stack>
        </Container>
      </div>
    </>
    );
  } else {
    return (<>
      <h1 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '0.5rem' }}>{ClaimNft}</h1>
      <div className="centrar-todo">
        <Container>
          <Stack
            className="d-flex flex-column align-items-center justify-content-evenly tamaño-imagenes"
            style={{ marginBottom: "1rem", alignItems: "center" }}>
            {/*           <img src={LogoDapps} alt="Dapps Factory" width="256px" />
   */}          <img src={LogoDappsSvg} alt="Dapps Factory" />
          </Stack>
          <Stack className="form-width">
            <Button className="my-3" onClick={goMisNfts}>Go to my NFTs</Button>
          </Stack>
          <Stack>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>{(currentAccount === reclamadoPor) ? <>{ClaimSuccessfull}</> : <>{ClaimOtraCuenta + " (ended in '0x..." + reclamadoPor?.slice(39, 42) + "')"}</>}</div>
          </Stack>
        </Container>
      </div>
    </>
    );
  };
}


export default ClaimNft;