import React, { createContext, useState, useEffect } from "react";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {

    const [elToken, setElToken] = useState('')

    const firebaseConfig = {
        apiKey: "AIzaSyBBO9fN1V11W3QYIzcYOC6SAZgnnkUYlt8",
        authDomain: "push-certifications.firebaseapp.com",
        projectId: "push-certifications",
        storageBucket: "push-certifications.appspot.com",
        messagingSenderId: "431599903650",
        appId: "1:431599903650:web:7e3353d2898723230b08fb",
        measurementId: "G-9YPVBHJP0X"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

     
    const getTokenFCM = async () => {

        let loop = 0;

        if(loop === 0){
            console.log('Requesting permission...');
            Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
            } else {
              console.log('error')
            }
            loop++;
          })
        }
          getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_ID })
          .then((currentToken) => {
              if (currentToken) {
                setElToken(currentToken)
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            });
      
          onMessage(messaging, (payload) => {
            console.log("Message received. ", payload);
            // ...
          });
    }

    useEffect(() => {
      getTokenFCM()
    }, [])
    



  return (
    <FirebaseContext.Provider
      value={{
       getTokenFCM,
       elToken,

      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};