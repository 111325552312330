import React, { useContext, useState } from "react";
import "./Profile.css";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import { Container, Col, Row, Stack } from "react-bootstrap";
import Pencil from "../../assets/bx_pencil.png";
import UserIcon from "../../assets/Frame.png";
import Eye from "../../assets/eye.png";
import CopyIcon from "../../assets/bx_copy.png";
import LockIcon from "../../assets/lock.png";
import Torta2 from "../../assets/TortaSola.png";
import Tooltip from "@mui/material/Tooltip";
import FooterOpciones from "../footer-completo/footer-opciones";
import { UserContext } from "../../context/userContext";
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";
import BalanceHeader from "../balance-header/balance-header";

const Profile = () => {
  const { currentAccount, privateKey } = useContext(UserContext);
  const [showPrivate, setShowPrivate] = useState(false);

  const handlePrivate = () => {
    setShowPrivate(!showPrivate);
  };
  const logInInfo = JSON.parse(localStorage.getItem("openlogin_store"));

  return (
    <div className='min-height-margin'>
      <Navigation />
      <BalanceHeader />
      <GoBack value="Profile" link="/home" />
      <Container
        
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: '2rem'
        }}
      >
        <div 
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Image */}

          <img
            src={Torta2}
            alt=""
            style={{
              height: "150px",
              width: "150px",
              borderRadius: "50%",
              marginTop: "1rem",
              marginBottom: "1rem",
              border: "2px solid #B66DF3",
            }}
          />

          {/* Name */}
          {logInInfo.name !== undefined ? (
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <span
                style={{
                  fontSize: "21px",
                  fontWeight: "600",
                  marginRight: "1rem",
                }}
              >
                {logInInfo.name}
              </span>
              {/* <img src={Pencil} alt='ok' style={{width: '25px', height: '25px'}}/> */}
            </div>
          ) : null}
        </div>

        <Row style={{width: '100%'}}>
        {/* EMAIL */}
          <Col sm='12' md='4' lg='4' xl='4'>
        {logInInfo.email !== undefined ? (
          <div style={{ display: "flex", alignItems: "center",  height: "100px", marginTop: '2rem', marginRight: '3rem'}}>
            <img
              src={UserIcon}
              alt="ok"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#B66DF3",
                borderRadius: "50%",
                padding: "7px",
                marginRight: "1rem",
              }}
            />
            <span style={{ fontSize: "0.8rem" }}>User Login: </span> &nbsp;
            <span style={{ fontWeight: "500", paddingLeft: "10px" }}>
              {logInInfo.email}
            </span>
          </div>
        ) : (
          <h1>Logged with Metamask</h1>
        )}
        </Col>
        {/* ADDRESS */}
        <Col sm='12' md='4' lg='4' xl='4'>
        <div style={{ display: "flex", alignItems: "center",  height: "100px", marginTop: '2rem', marginRight: '3rem' }}>
          <Tooltip
            title="Copied"
            /* open={open} */ disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <img
              src={CopyIcon}
              alt="ok"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#CA2AE5",
                borderRadius: "50%",
                padding: "7px",
                marginRight: "1rem",
              }}
            />
          </Tooltip>
          <label style={{ wordBreak: "break-word", fontWeight: "500" }}>
            {currentAccount}
          </label>
        </div>
        </Col>

        {/* PRIVATE KEY */}
        <Col sm='12' md='4' lg='4' xl='4'>
        {logInInfo.email !== undefined ? (
          <div style={{ display: "flex", alignItems: "center",height: "100px", marginTop: '2rem' }}>
            <img
              src={LockIcon}
              alt="ok"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#7FB73B",
                borderRadius: "50%",
                padding: "7px",
                marginRight: "1rem",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  marginRight: "0.5rem",
                  fontWeight: "500",
                  color: "#7FB73B",
                }}
              >
                Private Key
              </label>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    fontWeight: "400",
                    marginRight: "1rem",
                    wordBreak: "break-word",
                  }}
                >
                  {showPrivate ? privateKey : "**************************"}
                </span>
                <img
                  src={Eye}
                  alt="ok"
                  style={{ width: "22px" }}
                  onClick={handlePrivate}
                />
              </div>
            </div>
          </div>
        ) : null}
        </Col>
        </Row>
      </Container>
      <FooterOpciones />
      <FooterDesktopCompleto />
    </div>
  );
};

export default Profile;
