import axios from 'axios';
import React, {useEffect, useState, useContext} from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import './LandingPage.css';
import { Container, Col, Row, Stack } from 'react-bootstrap';
import FooterOpciones from '../footer-completo/footer-opciones'
import Navigation from '../navigation/Navigation'
import NavigationLanding from '../navigation-landingpage/Navigation-Landing';
import ReactPlayer from 'react-player';
import GridLoader from "react-spinners/GridLoader";
import Tabs from '../../utils/tabs/Tabs'
import Footer from '../footer/footer';
import Button from "react-bootstrap/Button";
import { useMoralisWeb3Api } from "react-moralis";
import Moralis from 'moralis';
import { UserContext } from "../../context/userContext";

const LandingPage = () => {
    let { tokenAddress, tokenId } = useParams();
    const { currentAccount } = useContext(UserContext);
    const Web3Api = useMoralisWeb3Api();
    const [metadata, setMetadata] = useState();
    const [video, setVideo] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [stats, setStats] = useState([]);
    const [properties, setProperties] = useState([]);
    const [rankings, setRankings] = useState([]);
    const [nftNotFound, setNftNotFound] = useState(false);
    let navigate = useNavigate();

    const getNftMetadata = async (_address, _tokenId) => {
      const options = {
        address: _address,
        token_id: _tokenId,
        chain: Moralis.Chains.POLYGON_MAINNET,
      };
      const NFT = await Web3Api.token.getTokenIdMetadata(options);
      
      if (!NFT) {
        setTimeout(() => {
          setInitialLoading(false)
      }, [500])
        setNftNotFound(true);
        return
      }

      setMetadata(JSON.parse(NFT.metadata))
      await getAttributes(JSON.parse(NFT.metadata))
      await isVideo(JSON.parse(NFT.metadata).image, _address, _tokenId)
    }
  
    React.useEffect(() => {
      Moralis.start({
        appId: "5feROU94jIskQH4R7ZCOfDR35RUgs2hs1Qe4e3N7",
        serverUrl: "https://uau7s0vyz28v.usemoralis.com:2053/server",
     }).then(()=>{getNftMetadata(tokenAddress, tokenId)})
  }, [tokenAddress, tokenId])

  const isVideo = async (_img, _address, _tokenId) => {
    const imagen = ((_img.slice(0,12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/"  + (_img.slice(12)) : ((_img.slice(0,7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + (_img.slice(7)) : _img
    try{
      var req = await fetch(imagen, { method: 'HEAD' });
      if (req.headers.get('content-type') === 'video/mp4') {
        setVideo(true);
      } else {
        setVideo(false)
      }
    }
    catch {
      const options = {
        address: _address,
        token_id: _tokenId,
        chain: Moralis.Chains.POLYGON_MAINNET,
      };
      const NFT = await Web3Api.token.getTokenIdMetadata(options);
      const _metadata = JSON.parse(NFT.metadata)
      _metadata.image = 'https://ipfs.io/ipfs/QmbDz7TiiwtaF2E8x5Ngj2XfjDL8PCh5zPYLv2ctGHxMt2'
      setMetadata(_metadata)
    }
    setInitialLoading(false);
  };

    const goLogin = () => {
      navigate('/home');
    }

    const getAttributes = async (nft) => {
      let newProperties = [];
      let newStats = [];
      let newRankings = [];
      console.log('llega a getAttributes: ', nft)
  
      if (nft?.attributes) {
        nft.attributes.forEach((attr) => {
          if (attr.display_type === "number") {
            newStats = [...newStats];
            newStats.push({
              display_type: "number",
              trait_type: attr.trait_type,
              value: Number(attr.value),
            });
          } else if (typeof attr.value === "string") {
            newProperties = [...newProperties];
            newProperties.push({
              trait_type: attr.trait_type,
              value: attr.value,
            });
          } else if (typeof attr.value === "number") {
            newRankings = [...newRankings];
            newRankings.push({
              trait_type: attr.trait_type,
              value: Number(attr.value),
            });
          } else {
            console.error("hay algo mal aca");
          }
          setStats(newStats);
          setProperties(newProperties);
          setRankings(newRankings);
        });
      }
    }

    if (initialLoading) {
      return <GridLoader color='#AE74FF' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
    } else if(nftNotFound) {
      return <div>No se encontro NFT con esa address y tokenId</div>
    }
    else {
      return (<>{(currentAccount === "") ?
            <>
              <div>
                <Stack style={{display: 'flex', flexDirection: 'row', margin: '0.5rem'}}>
                  <NavigationLanding />
                </Stack>
                <Footer/>
                <Container>
                  <Row className="my-2">
                    <Col md={12} className="p-2 d-flex flex-column align-items-center justify-content-center">
                      <h1 className='title-nft'>{metadata?.name}</h1>
                      <div className="img-container">
                        {video ?
                          <ReactPlayer
                            height='300px'
                            width='420px'
                            url={((metadata?.image)?.slice(0,12) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(12) :  ((metadata?.image)?.slice(0,7) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(7) : metadata?.image}
                            loop
                            playing
                            controls
                          />
                          : <img src={((metadata?.image)?.slice(0,12) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(12) :  ((metadata?.image)?.slice(0,7) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(7) : metadata?.image} style={{ maxHeight: "50vh", width: '100%' }} alt='nftImage' />
                        }
                      </div>
                      <div className="description-wrapper">
                        <label style={{ fontWeight: '500', fontSize: '0.9rem', marginLeft: '0.5rem'}}>Description</label>
                        <p style={{ fontWeight: '200', fontSize: '0.8rem', marginLeft: '0.5rem' }}>{metadata?.description}</p>
                      </div>
                      <div className='tab-div'>
                        <Tabs properties={properties} rankings={rankings} stats={stats} />
                      </div>
                    </Col>
                    <div className="form-width" style={{textAlign:'center', marginTop: '1rem'}}>
                      <Button onClick={goLogin} className='send-button-landing'>Login | Create your Wallet</Button>
                    </div>
                    
                  </Row>
                </Container>
              </div>
            </>
            :
            <>
              <div className='min-height-margin'>
              <Navigation />
                <Container>
                  <Row className="my-2">
                    <Col md={12} className="p-2 d-flex flex-column align-items-center justify-content-center">
                      <h1 className='title-nft'>{metadata?.name}</h1>
                      <div className="img-container">
                        {video ?
                          <ReactPlayer
                            height='300px'
                            width='420px'
                            url={((metadata?.image)?.slice(0,12) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(12) :  ((metadata?.image)?.slice(0,7) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(7) : metadata?.image}
                            loop
                            playing
                            controls
                          />
                          : <img src={((metadata?.image)?.slice(0,12) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(12) :  ((metadata?.image)?.slice(0,7) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((metadata)?.image)?.slice(7) : metadata?.image} style={{ maxHeight: "50vh", width: '100%' }} alt='nftImage' />
                        }
                      </div>
                      <div className="description-wrapper">
                        <label style={{ fontWeight: '500', fontSize: '0.9rem', marginLeft: '0.5rem'}}>Description</label>
                        <p style={{ fontWeight: '200', fontSize: '0.8rem', marginLeft: '0.5rem' }}>{metadata?.description}</p>
                      </div>
                      <div className='tab-div'>
                        <Tabs properties={properties} rankings={rankings} stats={stats} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <FooterOpciones />
            </>
          }</>
      )
    }
}

export default LandingPage