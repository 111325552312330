import React, {useState} from 'react'
import './Notification.css'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Notification = () => {
    const [notifAmount, setNotifAmount] = useState(3) 
    const [showNotifications, setShowNotifications] = useState(false);

  return (
    <>
    <div className="all-notif-container">
    <div className="notif-container" onClick={() => setShowNotifications(!showNotifications)}>
      {notifAmount === 0 ? <NotificationsNoneIcon /> : <NotificationsIcon />}
      {notifAmount > 0 && <span className="notif-number">{notifAmount}</span>}
    </div>
    {showNotifications && (
      <div className="notif-list">
        <div className="notif-item">Notification 1</div>
        <div className="notif-item">Notification 2</div>
        <div className="notif-item">Notification 3</div>
      </div>

)}
</div>
  </>
  )
}

export default Notification