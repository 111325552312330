import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "./NftAmigo.css";
import { Container, Stack, Form, Button, Row, Col } from "react-bootstrap";
import Navigation from "../navigation/Navigation";
import Scanner from "../scanner/scanner";
import GoBack from "../goBack/GoBack";
import FooterOpciones from '../footer-completo/footer-opciones'
import GridLoader from "react-spinners/GridLoader";
import InputGroup from 'react-bootstrap/InputGroup';
import { ethers } from 'ethers';
import RecentDestinations from "../recent-destinations/recent-destinations";

//context
import { UserContext } from "../../context/userContext";
import FooterDesktopCompleto from '../footer-desktop-completo/footer-desktop-completo';
import BalanceHeader from '../balance-header/balance-header';

const NftAmigoLoader = () => {
  const { provider } = useContext(UserContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [hideQr, setHideQr] = useState(true)
  const [isValid, setIsValid] = useState(false);
  const [loader, setLoader] = useState(false)
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
    setInitialLoading(false);
    }, [500])
  }, [provider])

  const handleSubmit = (event) => {   
    const form = event.currentTarget;
    
      setModalIsShown(true);
    
    event.preventDefault();
    event.stopPropagation();
    setFormSubmitted(true);
  };

  const goToAddress = (_address) => {
    navigate(`/view-friend-nft/${_address}`)
  }

  
  const camaraToAddContact = (dataReceiveFromParent) => {
    setReceiver(dataReceiveFromParent);
    document.getElementById('receiver').value = dataReceiveFromParent;
  };

  const validate = () => {
    return receiver.length === 42;
  }

  useEffect(() => {
    const isValid = validate();
    setIsValid(isValid);
    if(receiver !== ''){
      setHideQr(true)
    } 
  }, [receiver]);



  //sacar el "!"
  if (initialLoading) {
    return <GridLoader color='#AE74FF' style={{position: 'fixed',margin: 'auto', inset: '45%'}}/>;
  } else {
    return (
        <>
            <div className='min-height-margin'>
                <Navigation />
                <BalanceHeader />
                <GoBack value="View Friend's NFT" link='/home'/>
            
                <Container style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <RecentDestinations _title='My Friends' setReceiver={setReceiver} param='agenda'/>
    
                <Stack style={{ textAlign: "center" }} gap={2}>
                    {!hideQr ? <Scanner scannerToParent={camaraToAddContact} /> : null}
                </Stack>
    
                <Form className="max-width-form form-send" noValidate id="SendForm" autoComplete="off" onSubmit={handleSubmit}>
                    <Form.Group className="mb-2 mt-4">
                        <Row>
                            <Col className="mb-2 max-button-col">
                    
                                <InputGroup className="mb-3 mt-2" hasValidation>
                        
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Address"
                                        name="receiver"
                                        id="receiver"
                                        value={receiver}
                                        onChange={(e) => {
                                        setReceiver(e.target.value);
                                        console.log("Receiver: " + e.target.value);
                                        }}
                                        isInvalid={!ethers.utils.isAddress(receiver) && (receiver.length || formSubmitted)}
                                        isValid={ethers.utils.isAddress(receiver)}
                                    />
                        
                                    <InputGroup.Text id="basic-addon1" onClick={()=>setHideQr(!hideQr)}><i className="fa-solid fa-camera"></i></InputGroup.Text>
                        
                                    <Form.Control.Feedback type="invalid">Invalid Address</Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center text-align-center'>
                            <Button onClick={()=>{goToAddress(receiver)}} type="submit" disabled={!isValid} className="max-width-form w-80 send-button" style={{width: '95%', padding: '0.1px'}}>
                                {loader ? <GridLoader color='white' style={{ fontSize: '1rem'}} />: 'Search'}
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
                </Container>
            </div>
            <FooterOpciones />
            <FooterDesktopCompleto />
        </>
    );}
};

export default NftAmigoLoader;
