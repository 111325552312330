import * as React from 'react'
import './Email.css'
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GoBack from '../goBack/GoBack';
import BandejaDeEntrada from '../BandejaDeEntrada/BandejaDeEntrada';
import BandejaDeSalida from '../BandejaDeEntrada/BandejaDeSalida';
import Navigation from '../navigation/Navigation';
import BalanceHeader from '../balance-header/balance-header';
import FooterOpciones from '../footer-completo/footer-opciones';
import FooterDesktopCompleto from '../footer-desktop-completo/footer-desktop-completo';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { MessageContext } from "../../context/messageContext";
import GridLoader from "react-spinners/GridLoader";

// const AntTab = styled((props) => )({  < Tab />
//   '&.Mui-selected': {
//   backgroundColor: 'secondary',
// },
// });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: '#7963E0',
    borderRadius: '0% 0% 25px 25px'
  },
}));


const Email = () => {
  const [value, setValue] = React.useState(null);
  const { messagesSent, messagesReceived, waitLoader, messagesReceivedV3DesencriptadoParcial, messagesSentV3DesencriptadoParcial } = React.useContext(MessageContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (value === null) {
      if (localStorage.oldTabValue != null) setValue(localStorage.oldTabValue)
      else setValue('1')
    } else {
      localStorage.oldTabValue = value;
    }
  }, [value]);

  return (
    <>
      <Navigation />
      <BalanceHeader />
      <GoBack value="Encrypted Email" link="/home" />

      <Box sx={{ width: '100%', typography: 'body1' }}>
        {(value === null || waitLoader)
          ? <GridLoader color="#AE74FF" size={20} style={{ position: "fixed", margin: "auto", inset: "45%" }} />
          :
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
                <AntTab label="Inbox" value="1" sx={{ marginLeft: '5%'}} />
                <AntTab label="Sent" value="2" sx={{ marginRight: '5%'}} />
              </TabList>
            </Box>
            <TabPanel value="1">
              <BandejaDeEntrada messagesReceived={messagesReceivedV3DesencriptadoParcial} />
              {/*<Link to='/send-message'>
                <Fab variant="extended" className='btn-write'>
                  <EditIcon sx={{ mr: 1 }} />
                    Compose
                </Fab>
              </Link>*/}
            </TabPanel>
            <TabPanel value="2">
              <BandejaDeSalida messagesSent={messagesSentV3DesencriptadoParcial} />
              {/*<Link to='/send-message'>
                <Fab variant="extended" className='btn-write'>
                  <EditIcon sx={{ mr: 1 }} />
                    Compose
                </Fab>
              </Link>*/}

            </TabPanel>

          </TabContext>
        }

      </Box>
      <FooterOpciones />
      <FooterDesktopCompleto />
    </>
  )
}

export default Email