//export const contractAddressEncriptado = "0x9e5Dd3226D0D84d83BD6685dC18197cD0cf55B81"

//export const contractAddressEncriptado = "0x0c865f39B0374DCA389957d85499BF53030619eB"

//export const contractAddressEncriptado = "0x7bBFfC370e3cd3B7eBff40958690F2f75C3c5495"

export const contractAddressEncriptado = "0xc677Dbf094496d84cC9833dC109ada21486dF87d"

export const contractABIEncriptado = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "previousAdmin",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newAdmin",
				"type": "address"
			}
		],
		"name": "AdminChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "beacon",
				"type": "address"
			}
		],
		"name": "BeaconUpgraded",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "version",
				"type": "uint8"
			}
		],
		"name": "Initialized",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "implementation",
				"type": "address"
			}
		],
		"name": "Upgraded",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"components": [
					{
						"internalType": "string",
						"name": "asunto",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "body",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "archivoAdjunto",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "firmaMensaje",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "timeHash",
						"type": "string"
					},
					{
						"internalType": "bool",
						"name": "visto",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "hideMessage",
						"type": "bool"
					}
				],
				"indexed": false,
				"internalType": "struct MensajeriaEncriptadaV3.Mensaje",
				"name": "_mensaje",
				"type": "tuple"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "messageId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "symetricKeyEncriptedSender",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "symetricKeyEncriptedReciever",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "asuntoSymetric",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "horaSymetric",
				"type": "string"
			}
		],
		"name": "messageSent",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			}
		],
		"name": "pushNotification",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_encryptedSymetricKeyEnvio",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_encryptedSymetricKeyRecepcion",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "_addressTo",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "_asunto",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_mensajeBody",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_archivoAdjunto",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_firmaMensaje",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_timeHash",
				"type": "string"
			}
		],
		"name": "enviarMensaje",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initialize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newImplementation",
				"type": "address"
			}
		],
		"name": "upgradeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newImplementation",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "upgradeToAndCall",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_idMensaje",
				"type": "uint256"
			}
		],
		"name": "getMensaje",
		"outputs": [
			{
				"components": [
					{
						"internalType": "string",
						"name": "asunto",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "body",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "archivoAdjunto",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "firmaMensaje",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "timeHash",
						"type": "string"
					},
					{
						"internalType": "bool",
						"name": "visto",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "hideMessage",
						"type": "bool"
					}
				],
				"internalType": "struct MensajeriaEncriptadaV3.Mensaje",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_numeroPagina",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_largoPagina",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_flagEnvioRecepcion",
				"type": "uint256"
			}
		],
		"name": "getPaginaMensajesEnviadosRecibidos",
		"outputs": [
			{
				"components": [
					{
						"components": [
							{
								"internalType": "string",
								"name": "encryptedSymetricKey",
								"type": "string"
							},
							{
								"internalType": "uint256",
								"name": "idMensaje",
								"type": "uint256"
							},
							{
								"internalType": "address",
								"name": "contraParte",
								"type": "address"
							}
						],
						"internalType": "struct MensajeriaEncriptadaV3.EtiquetaLink",
						"name": "etiquetaEncriptada",
						"type": "tuple"
					},
					{
						"internalType": "string",
						"name": "asuntoSymetric",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "timeSymetric",
						"type": "string"
					}
				],
				"internalType": "struct MensajeriaEncriptadaV3.HeaderFront[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint8",
				"name": "_FlagEnvioRecepcion",
				"type": "uint8"
			}
		],
		"name": "getTodosMensajesEnviadosRecibidos",
		"outputs": [
			{
				"components": [
					{
						"internalType": "string",
						"name": "encryptedSymetricKey",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "idMensaje",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "contraParte",
						"type": "address"
					}
				],
				"internalType": "struct MensajeriaEncriptadaV3.EtiquetaLink[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "proxiableUUID",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]