import React, { useContext, useState } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Camera from '../../assets/Camera.png'
import GalleryLogo from '../../assets/galleryIcon.png'
import Navigation from '../../components/navigation/Navigation'
import FooterOpciones from '../../components/footer-completo/footer-opciones'
import GoBack from '../../components/goBack/GoBack'
import { Link } from "react-router-dom";
import { UserContext } from '../../context/userContext';
import ImageUploader from '../camera/imageUploader';
import FooterDesktopCompleto from '../../components/footer-desktop-completo/footer-desktop-completo';


const CameraGallery = () => {
    

    const { register, handleSubmit } = useForm()
    const { setImage, setShowImage } = useContext(UserContext);

    const navigate = useNavigate();

    const onSubmit = (datas) => {
        const data = datas.picture[0]
        getBase64(data);
    }

    const handleChange = (image) => {
        const data = image.target.files[0];
        getBase64(data)
    }
    
    const getBase64 = file => {
        let reader = new FileReader();
        if (file) reader.readAsDataURL(file)
        reader.onload = () => {
            onLoad(reader.result);
        };
    };
    
    const onLoad = fileString => {
        setImage(fileString)
        console.log("imagen", fileString)
        navigate("/make-nft/make")
    };


    return (
        <>
        <div>
        <Navigation pageWrapId={"page-wrap"} outerContainerId={"Home"} />
        <GoBack value="Create NFT" link='/home'/>

        <Container className="container-send">
                <Row>
                    <Col>
                    <Link to='/make-nft/camera'>
                        <div className='Box-send'>
                            <img src={Camera} alt="Nfts" width="50%" />
                            <h2>Camera</h2>
                        </div>
                    </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <label htmlFor='file-upload'>
                        <div className='Box-send'>
                            <img src={GalleryLogo} alt="Nfts" width="50%" style={{marginBottom: '1rem'}}/>
                            <h2>Gallery</h2>
                            <input type="file" id='file-upload' onInput={handleChange} style={{display: 'none'}}{...register('picture')} />
                        </div>
                            </label>
                    
                    </Col>
                </Row>
            </Container>
            <FooterOpciones />
            <FooterDesktopCompleto />
        </div>
        </>
    )
}

export default CameraGallery