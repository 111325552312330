import React, { useContext, useEffect, useState} from "react";
import "./Balance.css";
import { Container, Row, Col, Stack } from "react-bootstrap";
import Matic from "../../assets/Matic.png";
import NFT from "../../assets/NFT.png";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import { UserContext } from "../../context/userContext";
import FooterOpciones from "../footer-completo/footer-opciones";
// import { useMoralisQuery } from "react-moralis";
//import BalanceTabs from '../../utils/tabs/BalanceTabs'
// import usePagination from "../../utils/pagination/pagination"
// import usePagination from '@mui/material/usePagination';
// import Pagination from '@mui/material/Pagination';
import GridLoader from "react-spinners/GridLoader";
import BalanceHeader from "../balance-header/balance-header";
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";
// import Moralis from 'moralis';
// import { useNavigate} from 'react-router-dom';
// import Button from "react-bootstrap/Button";
//import InputDataDecoder from "ethereum-input-data-decoder";
import { contractAddressEncriptado, contractABIEncriptado } from '../../utils/contracts/encryptedMessages';

const Balance = () => {
  const {  listOfNft, balanceInMatic, transactions, nftTransactions, currentAccount, nfts } = useContext(UserContext);
  const [loadingState, setLoadingState] = useState(true)
  /*const [contratoDappsFactory, setContratoDappsFactory] = useState([])
  const [decodeTransactions, setDecodeTransactions] = useState([])
  const [transaccionesFiltradas, setTransaccionesFiltradas] = useState([])
  const InputDataDecoder = require('ethereum-input-data-decoder');
  const abi_1155Balance = require('../../utils/contracts/erc1155Balance.json');*/
  // let navigate = useNavigate();
  const defender = "0x057163929ce520d7827d5c334432293fc3eec3c5";
  const usuario = currentAccount;


  /*const fetchTransactionsPositive = async () => {
    console.log(usuario)
    const _contratoDappsFactory = await Moralis.Cloud.run("GetTransactions+Info", {address: "0x8b7855f52df16aef1a5f64b7016cea7b01ce876b" ? ("0x8b7855f52df16aef1a5f64b7016cea7b01ce876b").toLowerCase() : ""})
    console.log(_contratoDappsFactory)
    setContratoDappsFactory(_contratoDappsFactory)
    const decoder = new InputDataDecoder(abi_1155Balance);
    let _decodeTransactions = [];
    for(const transaction of _contratoDappsFactory){
      const data = transaction.attributes.input
      const result = decoder.decodeData(data);
      result.block_timestamp = (transaction.attributes.block_timestamp).toString();
      _decodeTransactions.push(result)
      //console.log(result);
    }
    setDecodeTransactions(_decodeTransactions)
  }

  const filterTransactions = (transactions) => {
    let _transaccionesFiltradas = [];
    for(let i = 0; i < transactions.length; i++) {
      console.log(transactions[i].method === 'safeTransferFrom')
      if(transactions[i].method === 'safeTransferFrom') {
        console.log(("0x" + transactions[i].inputs[1]).toString() === (usuario).toString())
        if(((transactions[i].inputs[1]).toString()).toLowerCase() === (((usuario).toString()).slice(2)).toLowerCase()){
          _transaccionesFiltradas.push(transactions[i]);
        }
      }
    }
    setTransaccionesFiltradas(_transaccionesFiltradas)
  }


  useEffect(() => {
    //if(transactions.result){
      //setLoadingState(false)
      fetchTransactionsPositive()
      filterTransactions(decodeTransactions)
      //setPaginationLength(transactions.result.length)
      setTimeout(() => {
        setLoadingState(false);
      }, [2000])
    //}
  }, [usuario])

  useEffect(() => {
    console.log(decodeTransactions)
    filterTransactions(decodeTransactions)
  }, [decodeTransactions])


  useEffect(() => {
    console.log(transaccionesFiltradas)
    //setLoadingState(false)
  }, [transaccionesFiltradas])*/


  const checkData = (transaction) => {
    switch (transaction.result.method) {
      case 'createCollectionAndMint':
        return <><h6>Minted NFT:</h6><h6>"{(transaction.result.inputs[1])}"</h6><h6 style={{fontSize:'0.8rem'}}>(NFTs minted: {parseInt(transaction.result.inputs[2])})</h6></>
      case 'safeTransferFrom':
        return <><h6>NFT transfered to:</h6><h6>0x{transaction.result.inputs[1].slice(0,4) + '...' + transaction.result.inputs[1].slice(34)}</h6><h6 style={{fontSize:'0.8rem'}}>{transaction.email ?  '(' + transaction.email + ')' : ''}</h6><h6 style={{fontSize:'0.8rem'}}>NFT ID: {parseInt(transaction.result.inputs[2])}</h6></>
      case 'setApprovalForAll':
        return `NFT transfered by email`
      case null:
        return `No info of this transaction`
        default:
        if (transaction.to_address) {
          return `Interacted with ${transaction?.to_address.slice(0,4) + '...' + transaction?.to_address?.slice(32)}`
        }
    }
  }

  // const goBalancePositive = () => {
  //   navigate('/balance-positive');
  // }
  //console.log("blub", transactions?.result?.length)

  useEffect(() => {
    if(transactions?.result){
      console.log("Wallet", transactions.result)
      setLoadingState(false)
    }
    /*setTimeout(() => {
      setLoadingState(false);
    }, [15000])*/
  }, [transactions])
  
  const conversorMatic = 10**18;


  return (
    <>
      <div className='min-height-margin'>
        <Navigation />
        <BalanceHeader />
        <GoBack value="Balance" link='/home' />

        <Stack className="transacciones">
          <div className="container d-xxl-none d-xl-none d-lg-none">
            <h5>Latest Transactions</h5>
            <h5>Matic</h5>
            {/*<i className="fa-solid fa-sliders"></i>*/}
          </div>
          <div className="d-none d-lg-block" style={{textAlign: 'center', margin: '2.5rem  0', width: '100%'}}>
            <h3 className='title-with-gradient-underline'>Bala<span>nce - Latest transact</span>ions</h3>
          </div>
          <div>
             
            {loadingState ? <GridLoader color='#AE74FF' size={20} style={{position: 'fixed',margin: 'auto', inset: '45%'}} /> : 
             <Container>
             {transactions.result?.map((transaction, index) => (
               <Row className="transaccion" key={index}>
                 <div className="container">
                   {(transaction.from_address === usuario) ?
                     (transaction.input === '0x') ?
                       (transaction.to_address === defender) ?
                         <>
                           <Col md={6} xs={6}>
                             <h6 className="wordbreak">NFT transfered by e-mail</h6>
                             <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                           </Col>
                           <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                         </>
                         :
                         <>
                           <Col md={6} xs={6}>
                             <h6 className="wordbreak">Matic transfered to:</h6>  
                             <h6 className="wordbreak">{(transaction.to_address.slice(0,6) + '...' + transaction.to_address.slice(36))}</h6>      
                             <h6 className="wordbreak" style={{fontSize:'0.7rem'}}>{transaction.email ? '(' + transaction.email + ')' : ''}</h6>  
                             <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                           </Col>
                           <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                         </>
                       :
                       (transaction.to_address === (contractAddressEncriptado).toLowerCase()) ?
                       <>
                         <Col md={6} xs={6}>
                           <h6 className="wordbreak">Send encripted message</h6>
                           <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                         </Col>
                         <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>  
                       </>
                       :
                         <>
                         <Col md={6} xs={6}>
                           <h6 className="wordbreak">{checkData(transaction)}</h6>
                           <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                         </Col>
                         <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                       </>
                    :
                       <>
                         <Col md={6} xs={6}>
                           <h6 className="wordbreak">Matic received from:</h6>  
                           <h6 className="wordbreak">{(transaction.from_address.slice(0,6) + '...' + transaction.from_address.slice(36))}</h6>        
                           <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                         </Col>
                         <span style={{color: 'green', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                       </>
                   }
                 </div>
               </Row>
             ))}

             </Container>
            }
           
          </div>
        </Stack>
        <FooterOpciones />
        <FooterDesktopCompleto />
      </div>
    </>
  );
};

export default Balance;

