import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import "./App.css";
import Moralis from "moralis";
import Home from "./components/home/Home";
import Agenda from "./components/agenda/Agenda";
import AddContact from "./components/add-contact/Add-contact";
import AddFunds from "./components/add-funds/Add-funds";
import Login from "./components/login/Login";
import Receive from "./components/receive/Receive";
import Send from "./components/send/Send";
import SendSelect from "./components/SendSelect/SendSelect";
import Nft from "./components/NFT/NFT";
import MisNFT from "./components/MisNFT/MisNFT";
import Balance from "./components/Balance/Balance";
import CameraNFT from "./utils/camera/CameraNFT";
import MakeNFT from "./utils/MakeNft/MakeNFT";
import RequireConnected from "./pages/RequireConnected";
import MintSuccess from "./utils/MakeNft/MintSuccess";
import NftDetails from "./components/nftDetails/NftDetails";
import CameraGallery from "./utils/MakeNft/CameraGallery";
import ImageUploader from "./utils/camera/imageUploader";
import LandingPage from "./components/landing-page/LandingPage";
import LandingPageV2 from "./components/landing-page/LandingPageV2";
import ClaimNft from "./components/claim-nft/ClaimNft";
import FullScreenScanner from "./components/fullscreenScanner/FullScreenScanner";
import Address from "./components/address/address";
import SendMessage from "./components/send-message/SendMessage";
import Test from "./utils/test/test";
import Email from "./components/email/Email";
import UploadCsv from "./utils/UploadCsv/UploadCsv";
import BalancePositive from "./components/Balance/BalancePositive";
import Profile from "./components/profile/Profile";
import Landing from "./pages/landing/Landing";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Terms/Privacy";
import FirstTime from "./pages/firstTime/FirstTime";
import NftAmigoLoader from "./components/nft-amigo/NftAmigoLoader";
import NftAmigoAddress from "./components/nft-amigo/NftAmigoAddress";
import EmailOpen from "./components/email-open/EmailOpen";
import EmailOpenSalida from "./components/email-open/EmailOpenSalida";
import AdminPanel from "./pages/admin/AdminPanel";
import WalletConnect from "./pages/WalletConnect";
import Visualizer6551 from "./components/6551-visualizer/Visualizer-6551";

// eslint-disable-next-line no-undef

function App() {
  useEffect(() => {
    Moralis.start({
      appId: "5feROU94jIskQH4R7ZCOfDR35RUgs2hs1Qe4e3N7",
      serverUrl: "https://uau7s0vyz28v.usemoralis.com:2053/server",
    });
  }, []);
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route path="/" exact element={<Outlet />} />
        {/* Solo si estas loggeado asd*/}
        <Route element={<RequireConnected />}>
          <Route
            index
            element={<Navigate to="/home" state={{ from: location }} replace />}
          />
          <Route path="/home" exact element={<Home />} />
          <Route path="/receive" exact element={<Receive />} />
          <Route path="/send" exact element={<SendSelect />} />
          <Route path="/send-token" exact element={<Send />} />
          <Route path="/nft" exact element={<Nft />} />
          <Route path="/my-nfts" exact element={<MisNFT />} />
          <Route path="/my-nfts/send" exact element={<MisNFT />} />
          
          <Route path="/my-nfts/:index" exact element={<NftDetails />} />
          <Route path="/my-nfts/send/:index" exact element={<NftDetails />} />
          <Route path="/scanner/:index" exact element={<FullScreenScanner />} />
          <Route path="/balance" exact element={<Balance />} />
          <Route path="/send-message" exact element={<SendMessage />} />
          <Route path='/email' exact element={<Email />} />
          <Route path='/message/:indice' exact element={<EmailOpen />} />
          <Route path='/message-sent/:indice' exact element={<EmailOpenSalida />} />
          <Route path="/make-nft" exact element={<CameraGallery />} />
          <Route path="/make-nft/camera" exact element={<CameraNFT />} />
          <Route path="/make-nft/gallery" exact element={<ImageUploader />} />
          <Route path="/make-nft/make" exact element={<MakeNFT />} />
          <Route path="/mint-success" exact element={<MintSuccess />} />
          <Route path="/agenda" exact element={<Agenda />} />
          <Route path="/add-contact" exact element={<AddContact />} />
          <Route path="/add-funds" exact element={<AddFunds />} />
          <Route path="/test" exact element={<Test />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/admin" exact element={<UploadCsv />} />
          <Route path='/admin-panel' exact element={<AdminPanel />} />
          <Route path="/balance-positive" exact element={<BalancePositive />} />
          <Route path="/connect-wallet" exact element={<WalletConnect />} />
          <Route path="/6551-visualizer" exact element={<Visualizer6551 />} />
          <Route path="/view-friend-nft" exact element={<NftAmigoLoader />} />
          <Route
            path="/view-friend-nft/:addressSearched"
            exact
            element={<NftAmigoAddress />}
          />
          <Route
            path="/landingpagev2/:ownerAddress/:tokenAddress/:tokenId"
            exact
            element={<LandingPageV2 />}
          />
        </Route>
        <Route path="/login" exact element={<Login />} />
        <Route path="/landing" exact element={<Landing />} />
        <Route path="/login/:param" exact element={<Login />} />
        <Route
          path="/landingpage/:tokenAddress/:tokenId"
          exact
          element={<LandingPage />}
        />
        <Route path="/claim-nft/:hash" exact element={<ClaimNft />} />
        <Route path="/address/:hash" exact element={<Address />} />
        <Route path="/privacypolicy" exact element={<Privacy />} />
        <Route path="/termsandconditions" exact element={<Terms />} />
        <Route path="/firstlogin" exact element={<FirstTime />} />
      </Routes>
      {/*<FooterDesktopCompleto/>
      <FooterOpciones />*/}
    </>
  );
}
export default App;
