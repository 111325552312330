import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import { MessageProvider } from "./context/messageContext";
import { MoralisProvider } from "react-moralis";
import { FirebaseProvider } from "./context/firebaseContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { WCProvider } from "./context/walletConnectContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MoralisProvider
      appId="5feROU94jIskQH4R7ZCOfDR35RUgs2hs1Qe4e3N7"
      serverUrl="https://uau7s0vyz28v.usemoralis.com:2053/server"
    >
      <Router>
        <FirebaseProvider>
          <UserProvider>
            <WCProvider>
              <MessageProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </MessageProvider>
            </WCProvider>
          </UserProvider>
        </FirebaseProvider>
      </Router>
    </MoralisProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
