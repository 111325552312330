import React from "react";
import "./Landing.css";
import {Link} from 'react-router-dom';
import { Container, Col, Row, Stack } from "react-bootstrap";
import Torta from "../../assets/TortaSola.png";
import PieceOfCake from "../../assets/PoC.png";
import Image1 from "../../assets/landing/landingHome.png";
import View from "../../assets/landing/user-tag.png";
import Create from "../../assets/landing/edit.png";
import Send from "../../assets/landing/scan-barcode.png";
import Share from "../../assets/landing/Vector.png";
import Lock from "../../assets/landing/lock-circle.png";
import Monitor from "../../assets/landing/monitor-mobbile.png";
import Phone from '../../assets/landing/phoneWallet.png'
import LogoDapps from '../../assets/landing/byDapps.png'
import Footer from '../../components/footer/footer';
import {SocialMediaTwitter, SocialMediaFacebook, SocialMediaLinkedin, SocialMediaWhatsapp, SocialMediaTelegram, SocialMediaEmail, CompartirAddresshatsapp} from "../../utils/Share-social"


const Landing = () => {
  return (
    <>
      <Container className="container-landing">
        <Row className="d-flex align-items-start mt-3">
          <Col xs={3} sm={3} md={3} lg={3} style={{padding: '0px'}}>
            <img src={Torta} alt="" width="100%" />
          </Col>
          <Col xs={9} sm={9} md={9} lg={9}>
            <img src={PieceOfCake} alt="" width="100%" />
          </Col>
        </Row>
        <Row className="d-flex flex-row align-items-center">
          <Col xs={12} sm={12} md={6} style={{marginTop: '1rem', marginBottom: '2rem'}}>
            <img src={Image1} alt="" width="100%" />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <h1
              style={{ fontSize: "25px", fontWeight: "bold", color: "#B66DF3", fontFamily: "Roboto", letterSpacing: "1.4"}}
            >
              USER FRIENDLY WALLET
            </h1>
            <p style={{ fontSize: "18px",  fontWeight: "medium"}}>
              Frictionless Experience
              <br/>
              View, Create and Transfer NFT
              <br/>
              Easy Login
              <br/>
              Simple and Secure
            </p>
            <div className="d-flex justify-content-center">
              <Link to='/login'>
                <button className="send-button" style={{ color: "white", width: '100%' }}>
                  Create Now!
                </button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: "#0B032F", paddingBottom: "2rem"}}>
        <Container>
          <Row>
            <Col>
              <h2
                style={{
                  color: "#DEB7FF",
                  fontSize: "25px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                Features
              </h2>
              <p style={{ textAlign: "center" }}>
                Easy-to-use platform.
              </p>
              <Row>
                <Col xs={6} sm={6} md={4}>
                  <Stack style={{ alignItems: "center", marginBottom: "1rem"}}>
                    <div className="icon-wrapper">
                      <img src={View} alt="" className="icon-landing" />
                    </div>
                    <label className="icon-text">View NFT</label>
                  </Stack>
                </Col>
                <Col xs={6} sm={6} md={4}>
                  <Stack style={{ alignItems: "center", marginBottom: "1rem" }}>
                    <div className="icon-wrapper">
                      <img src={Create} alt="" className="icon-landing" />
                    </div>
                    <label className="icon-text">Create NFT</label>
                  </Stack>
                </Col>
                <Col xs={6} sm={6} md={4}>
                  <Stack style={{ alignItems: "center", marginBottom: "1rem" }}>
                    <div className="icon-wrapper">
                      <img src={Send} alt="" className="icon-landing" />
                    </div>
                    <label className="icon-text">Send NFT</label>
                  </Stack>
                </Col>
                <Col xs={6} sm={6} md={4}>
                  <Stack style={{ alignItems: "center", marginBottom: "1rem" }}>
                    <div className="icon-wrapper">
                      <img src={Share} alt="" className="icon-landing" />
                    </div>

                    <label className="icon-text">Share NFTS<br/> with friends</label>
                  </Stack>
                </Col>
                <Col xs={6} sm={6} md={4}>
                  <Stack style={{ alignItems: "center", marginBottom: "1rem" }}>
                    <div className="icon-wrapper">
                      <img src={Lock} alt="" className="icon-landing" />
                    </div>
                    <label className="icon-text">Easy Login</label>
                    <label className="icon-text-info">(google, facebook, etc)</label>
                  </Stack>
                </Col>
                <Col xs={6} sm={6} md={4}>
                  <Stack style={{ alignItems: "center", marginBottom: "1rem" }}>
                    <div className="icon-wrapper">
                      <img src={Monitor} alt="" className="icon-landing" />
                    </div>
                    <label className="icon-text">No onboarding<br/> needed</label>
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='purple-back'>
      
            <div style={{maxWidth: '50%'}}>
                <img src={Phone} alt='' style={{width: '100%', padding: '1.5rem'}} className='render-login'/>
            </div>
            <div className='d-flex flex-column justify-content-around align-items-start p-2' >
                    <h3 className='purple-text'>All your NFTs in one wallet.</h3>
                    <h3 className='purple-text'>Create & Transfer NFT </h3>
                    <h3 className='purple-text'>Share your NFTs with friends.</h3>
                    <Link to='/login'>
                      <button className="log-button" >Login Now</button>
                    </Link>
            </div>
        
      </div>
      <Container>
        <Row style={{marginTop: '1rem', marginBottom: '2rem'}}>
            <Col>
                <a href="https://www.dappsfactory.io" rel="noopener noreferrer" target="_blank">
                  <img src={LogoDapps} alt='' style={{width: '100%', padding: '1rem 4rem'}} />
                </a>
                <label style={{width: '100%', textAlign: 'center', marginBottom: '1rem'}}>Created and Developed @2022</label>
                <Footer />
                <div className='share-wrapper landing-share'>
                  <label style={{color: '#6F45C1'}}>Contact us: </label>
                  <SocialMediaWhatsapp _url={``} />
{/*                   <SocialMediaTwitter _url={`https://pieceofcakewallet.com/landingpage/${metadataHashLanding}`} /> */} 
                 <SocialMediaLinkedin _url={`https://www.linkedin.com/company/dappsfactory/`} />
                  <SocialMediaTelegram _url={`https://t.me/DAppsFactory`} />
                  <SocialMediaFacebook _url={`https://dappsfactory.io/`} />
                  <SocialMediaEmail _url={`hola@dappsfactory.io`} />
                </div>
            </Col>
        </Row>
       
      </Container>
    </>
  );
};

export default Landing;
