import React, { useContext, useEffect, useState } from "react";
import Navigation from '../../components/navigation/Navigation';
import GoBack from '../../components/goBack/GoBack';
import FooterOpciones from '../../components/footer-completo/footer-opciones';
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import GridLoader from "react-spinners/GridLoader";
import { Box, Tab} from '@mui/material'
import { styled } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TextField from '@mui/material/TextField';
import { UserContext } from "../../context/userContext";
import { useGeolocated } from "react-geolocated";
import { MdOutlineAddCircleOutline, MdOutlineHighlightOff } from 'react-icons/md';
import ReactPlayer from 'react-player'
import Modales from "../modal/modal";
import Loader from '../loader/Loader'
import './MakeNFT.css'
import FooterDesktopCompleto from "../../components/footer-desktop-completo/footer-desktop-completo";

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#AD75FC',
  },
  '& MuiInputBase-input': {
    color: 'white',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: '#AD75FC',
    color: 'white'
  },
  
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'gray',
    },
    '&:hover fieldset': {
      borderColor: '#AF75FF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#AD75FC',
    },
  },
});

const MakeNFT = () => {
  const { image, currentAccount,  openConfirmationModal, loader, setLoader, transferError, handleChange, estimateGas } = useContext(UserContext);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [modalIsShown, setModalIsShown] = useState(false);
  const [value, setValue] = useState('1');
  const [stats, setStats] = useState([])
  const [rankings, setRankings] = useState([])
  const [properties, setProperties] = useState([])
  const [attributes, setAttributes] = useState([])
  const [video, setVideo] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [lat, setLat] = useState()
  const [lon, setLon] = useState()
  const [helper, setHelper] = useState()
  const { register } = useForm()


  //HANDLE FORM ERRORS
  const [errorName, setErrorName] = useState(false);
  const [errorTextName, setErrorTextName] = useState('')
  const [errorDesc, setErrorDesc] = useState(false);
  const [errorTextDesc, setErrorTextDesc] = useState('')
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorTextAmount, setErrorTextAmount] = useState('')
  const [errorBase, setErrorBase]  = useState(false)
  const [errorBaseText, setErrorBaseText]  = useState('')
  const [errorBaseValue, setErrorBaseValue]  = useState(false)
  const [errorBaseTextValue, setErrorBaseTextValue]  = useState('')

  const isVideo = async () => {
    setHelper(true)
    var req = await fetch(image, { method: 'HEAD' });
    if (req.headers.get('content-type') === 'video/mp4') {
        setVideo(true);
        setLoader(false)
        setHelper(false)
    } else {
        setVideo(false)
        setLoader(false)
        setHelper(false)
    }
}


    useEffect(() => {    
        isVideo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])




  const modalToParent = () => {
    setModalIsShown(false);
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };


  // Use geolocated
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  const handleSubmit = (event) => {
    estimateGas(name,amount,imageUrl)
    setModalIsShown(true);
  };

  useEffect(() => {
    if(coords){
      console.log(coords)
    setLat()
    setLon()
    }
    
  },[coords])
  


  // Funciones para las propiedade
  const handlePropChange = (i, e) => {
    let newFormValues = [...properties];
    newFormValues[i][e.target.name] = e.target.value;
    setProperties(newFormValues);
    
  }
  const removeProperties = (i) => {
    let newFormValues = [...properties];
    var index = newFormValues.map(values => {
      return values.id;
    }).indexOf(i);
    newFormValues.splice(index, 1);
    setProperties(newFormValues)
  }
  const addProperties = () => {
    setProperties([...properties, { "trait_type": "", "value": "", "id": Date.now() }]);
  }

  // Funciones para las Stats
  const handleStatChange = (i, e) => {
   
    let newFormValues = [...stats];

    newFormValues[i]["display_type"] = "number";
    if (e.target.name === "trait_type") newFormValues[i]["trait_type"] = e.target.value;
    if (e.target.name === "value") newFormValues[i]["value"] = Number(e.target.value);
    setStats(newFormValues);
  }
  const removeStats = (i) => {
    let newFormValues = [...stats];
    var index = newFormValues.map(values => {
      return values.id;
    }).indexOf(i);
    newFormValues.splice(index, 1);
    setStats(newFormValues);
  }
  const addStats = () => {
    setStats([...stats, { "display_type": "number", "trait_type": "", "value": Number("0"), "id": Date.now() }]);
  }

  // Funciones para los rankings
  const addRankings = () => {
    setRankings([...rankings, { "trait_type": "", "value": Number("0"), "id": Date.now() }]);
  }
  const removeRanking = (i) => {
    let newFormValues = [...rankings];
    var index = newFormValues.map(values => {
      return values.id;
    }).indexOf(i);
    newFormValues.splice(index, 1);
    setRankings(newFormValues);
  }
  const handleRankingChange = (i, e) => {
    let newFormValues = [...rankings];
    if (e.target.name === "trait_type") newFormValues[i]["trait_type"] = e.target.value;
    if (e.target.name === "value") newFormValues[i]["value"] = Number(e.target.value);
    setRankings(newFormValues);
  }

  // Cuando algun array cambia:
  React.useEffect(() => {
    let newAttributes = [...stats, ...properties, ...rankings, { "trait_type": "lat", "value": coords?.latitude}, { "trait_type": "lon", "value": coords?.longitude}];
    console.log(newAttributes);
    setAttributes(newAttributes);
  }, [properties, stats, rankings, coords])


  return (
    <div className='min-height-margin' >

      <Navigation />
     
      <GoBack value="Create NFT" link='/home'/>
      {(openConfirmationModal.open) &&
        <Modales 
          modalToParent={modalToParent}
          action={"Minted"}
          _from={currentAccount}
          _icon={"check"}
          _title={"NFT Created"}
          _text={"Your NFT was created succesfully"}
        ></Modales>
      }
      {(transferError) &&
        <Modales 
          modalToParent={modalToParent}
          _title={"Transaction cancelled"}
          _text={"There was an error creating your NFT, please check if you have enought balance for this transaction."}
        ></Modales>
      }

      <div style={{marginLeft: '2px', marginRight: '2px', display: "flex", flexDirection: "column"}}>
      <Container>
        <Row>
          <label htmlFor='file-upload'>
          
          <Col className="d-flex justify-content-center mt-4 mb-3">
            {video 
                    ? <ReactPlayer
                        height='150px'
                        url={image && image}
                        width='25%'
                        className='fullscreen-video'
                        loop
                        playing
                        
                    /> :  <img src={image} alt="200" width="25%" />
                }

                    <input type="file" id='file-upload' onInput={handleChange} style={{display: 'none'}}{...register('picture')} />

          </Col>
        
                </label>
        </Row>
        <Form>
            <CssTextField  label="NFT Name" fullWidth  variant="outlined" autoComplete="off" className="input-makeNft mb-3" error={errorName} helperText={errorName ? errorTextName : ''} onChange={(e) => {
                if(e.target.value === ''){
                  setErrorName(true)
                  setErrorTextName('The name field is empty.')
                } else{
                  setErrorName(false)
                  setName(e.target.value);
                }
                
                
              }}/>
          <CssTextField  label="NFT Description" fullWidth multiline={true} autoComplete="off" variant="outlined" error={errorDesc} helperText={errorDesc ? errorTextDesc : ''} className="input-makeNft mb-3" onChange={(e) => {
            if(e.target.value === ''){
              setErrorDesc(true)
              setErrorTextDesc('The description field is empty.')
            } else{
              setErrorDesc(false)
              setDescription(e.target.value);
            }
                
              }}/>
          <CssTextField  label="Number of NFTs to create" fullWidth  variant="outlined" error={errorAmount} helperText={errorAmount ? errorTextAmount : ''} type="number"  className="input-makeNft md-3" onChange={(e) => {
                if(e.target.value <= 0){
                  setErrorAmount(true)
                  setErrorTextAmount('The supply must be more than 1.')
                } else{
                  setErrorAmount(false)
                  setAmount(e.target.value);
                }
                  }}/>
        </Form>
        <Row>
          <Col>
              {/* <Tabs/> */}
              {/* Menu de Tab que muestra propiedades, stats y levels */}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'start', justifyContent: 'center'  }}>
                    <TabList sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', padding: '10px 0px' }} indicatorColor="secondary" onChange={handleChangeValue} aria-label="NFTDetails">
                        <Tab label="Stats" value="1" className="tab-details" style={value === '1' ? {color: 'white'} : { color: 'gray'}}/>
                        <Tab label="Rankings"  value="2" className="tab-details" style={value === '2' ? {color: 'white'} : { color: 'gray'}}/>
                        <Tab label="Properties" value="3" className="tab-details" style={value === '3' ? {color: 'white'} : { color: 'gray'}}/>
                    </TabList>
                    </Box>
   
                  {/*  Tab de Stats  */}
                  <TabPanel value="1" index={0}>
                  {stats.length > 0 && stats.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>
                      <div className="d-inline mx-2">
                        <CssTextField  label="Key" fullWidth name="trait_type" defaultValue={element.trait_type} error={errorBase} helperText={errorBase ? errorBaseText : ''} variant="outlined"  className="input-makeNft mb-3" sx={{width: '15rem'}} onChange={(e) => {
                          if(e.target.value == ''){
                            setErrorBase(true)
                            setErrorBaseText('Please complete the field.')
                          } else{
                            setErrorBase(false)
                            handleStatChange(index, e);

                          }
                        }}/>
                      </div>
                      <div className="div-props mx-2">
                        <CssTextField  label="Value" name='value' variant="outlined" error={errorBaseValue} helperText={errorBaseValue ? errorBaseTextValue : ''} type='number' className="input-makeNft mb-3" onChange={e => {
                          if(e.target.value == ''){
                            setErrorBaseValue(true)
                            setErrorBaseTextValue('Please complete the field.')
                          } else{
                            setErrorBaseValue(false)
                            handleStatChange(index, e);
                          }
                          }} sx={{width: '15rem', marginRight: '1rem'}}/>
                        <MdOutlineHighlightOff color="white"  style={{marginRight: '1rem', fontSize: '1rem'}} onClick={() => removeStats(element.id)}/>
                      </div>
                      </div>
                  ))}
                  <button style={{ fontSize: '1rem', fontWeight: 'bold', color: 'white', backgroundColor: '#AD75FC', width: '100%', border: '0px', borderRadius: '5px', padding: '5px 3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => addStats()}>
                    <MdOutlineAddCircleOutline color="white"  style={{marginRight: '1rem', fontSize: '1rem'}}/>
                    <label style={{fontSize: '14px'}}>ADD STAT</label>
                  </button>
                  </TabPanel>

                  <TabPanel value="2" index={1}>
                  {rankings.length > 0 && rankings.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>
                      <div className="d-inline mx-2">
                        <CssTextField  label="Key" fullWidth name="trait_type" variant="outlined" type='text' error={errorBase} helperText={errorBase ? errorBaseText : ''} className="input-makeNft mb-3" sx={{width: '15rem'}} onChange={(e) => {
                          if(e.target.value == ''){
                            setErrorBase(true)
                            setErrorBaseText('Please complete the field.')
                          } else{
                            setErrorBase(false)
                            handleRankingChange(index, e);
                          }
                        }}/>
                      </div>
                      <div className="div-props mx-2">
                        <CssTextField  label="Value"  name='value' variant="outlined" error={errorBaseValue} helperText={errorBaseValue ? errorBaseTextValue : ''} className="input-makeNft mb-3" onChange={e =>{
                          if(e.target.value == ''){
                            setErrorBaseValue(true)
                            setErrorBaseTextValue('Please complete the field.')
                          } else{
                            setErrorBaseValue(false)
                            handleRankingChange(index, e)  
                            }
                           }} sx={{width: '15rem', marginRight: '1rem'}}/>
                        <MdOutlineHighlightOff color="white"  style={{marginRight: '1rem', fontSize: '1rem'}} onClick={() => removeRanking(element.id)}/>
                      </div>
                      </div>
                  ))}
                  <button style={{ fontSize: '1rem', fontWeight: 'bold', color: 'white', backgroundColor: '#AD75FC', width: '100%', border: '0px', borderRadius: '5px', padding: '5px 3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => addRankings()}>
                    <MdOutlineAddCircleOutline color="white"  style={{marginRight: '1rem', fontSize: '1rem'}}/>
                    <label style={{fontSize: '14px'}}>ADD RANKING</label>
                  </button>
                  </TabPanel>

                  <TabPanel value="3" index={2}>
                  {properties.length > 0 && properties.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>
                      <div className="d-inline mx-2">
                        <CssTextField  label="Key" fullWidth name="trait_type" variant="outlined" error={errorBase} helperText={errorBase ? errorBaseText : ''} className="input-makeNft mb-3" sx={{width: '15rem'}} onChange={(e) => {
                          if(e.target.value == ''){
                            setErrorBase(true)
                            setErrorBaseText('Please complete the field.')
                          } else{
                            setErrorBase(false)
                            handlePropChange(index, e);
                          }
                        }}/>
                      </div>
                      <div className="div-props mx-2">
                        <CssTextField  label="Value"  name='value' variant="outlined" error={errorBaseValue} helperText={errorBaseValue ? errorBaseTextValue : ''}  className="input-makeNft mb-3" onChange={e => {
                          if(e.target.value == ''){
                            setErrorBaseValue(true)
                            setErrorBaseTextValue('Please complete the field.')
                          } else{
                            setErrorBaseValue(false)
                            handlePropChange(index, e);
                          }
                          }} sx={{width: '15rem', marginRight: '1rem'}}/>
                        <MdOutlineHighlightOff color="white"  style={{marginRight: '1rem', fontSize: '1rem'}} onClick={() => {removeProperties(element.id)}}/>
                      </div>
                      </div>
                  ))}
                  <button style={{ fontSize: '1rem', fontWeight: 'bold', color: 'white', backgroundColor: '#AD75FC', width: '100%', border: '0px', borderRadius: '5px', padding: '5px 3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => addProperties()}>
                    <MdOutlineAddCircleOutline color="white"  style={{marginRight: '1rem', fontSize: '1rem'}}/>
                    <label style={{fontSize: '14px'}}>ADD PROPERTY</label>
                  </button>
                  </TabPanel>
                </TabContext>
              </Box>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mb-5">
            <button disabled={!name || !amount || !description || errorName || errorDesc || errorAmount} className="upload" color="black" onClick={handleSubmit} >Create NFT</button> 
          </Col>
        </Row>
        {(modalIsShown) &&
                <Modales modalToParent={modalToParent}
                  _function={"Create"}
                  // _icon={"Pregunta"}
                  _url={imageUrl}
                  _name={name}
                  _icon={"no"}
                  _description={description}
                  _amount={amount}
                  _currentAccount={currentAccount}
                  _attributes={attributes}
                  _title={"Please Confirm Transaction"}
                ></Modales>
              }

      {(loader) &&
        <Modales modalToParent={modalToParent}
        _function={"processing"}
        _title={" "}
        // _icon={"Pregunta"}
        _icon={"no"}
        _name={"Processing transaction"}
        _description={"Please do not close this site"}
      ></Modales>
      }
             
        </Container>

      </div>
      <FooterOpciones />
      <FooterDesktopCompleto />
      </div>
   
  );
};

export default MakeNFT;