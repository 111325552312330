import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Moralis from "moralis";
import { Link } from 'react-router-dom';
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PocLogo from "../../../assets/logos/pocCompleto.png";
import SendIcon from "@mui/icons-material/Send";
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import SendNotification from "../sendNotification/SendNotification";
import ListOfNotifications from "../listOfNotifications/ListOfNotifications";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

const CssTextFieldAdmin = styled(TextField)({
  ".	MuiTextField-root": {
    color: "black",
  },
  "& label.Mui-focused": {
    color: "#AD75FC",
  },

  "& MuiInputBase-input": {
    color: "#7963E0 !important",
  },
  "& MuiInputBase-root": {
    color: "red !important",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#AD75FC",
    color: "#7963E0",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "gray",
    },
    "&:hover fieldset": {
      borderColor: "#AF75FF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#AD75FC",
    },
  },
});


const Templates = (props) => {
  const [titulo, setTitulo] = useState('titulo');
  const [descripcion, setDescripcion]  = useState('description')

  const [selected, setSelected] = useState('template')

  const [datos, setDatos] = useState('')
  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  let { index } = useParams();


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerWidth = 240;

  const drawer = (
    <div>
      <Toolbar sx={{ backgroundColor: "#13054C" }}>
        <img
          src={PocLogo}
          alt=""
          height="65px"
          style={{ marginLeft: "-2rem" }}
        />
      </Toolbar>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const retrieveTable = async () =>{
    let tabla = await Moralis.Cloud.run('getNotifTablebyId', {objectId: index})

    setDatos(tabla[0])
    setTitulo(tabla[0].attributes.Title)
    setDescripcion(tabla[0].attributes.Description)
  }

  useEffect(() => {
    retrieveTable()
  }, [])
  

  const updateTable = async () =>{
    Moralis.Cloud.run('UpdateTableNotifications', {objectId: index, title: titulo, description: descripcion})
    .then((response) => {response.success === true ? alert('Message updated') : alert('error'); navigate('/admin-panel')} )
  }


  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ backgroundColor: "#13054C" }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                color: "black",
              },
              "& .MuiDrawer-docked": {
                color: "black",
              },
            }}
          >
            {drawer}
            <List>
              <Link to='admin-panel'>
              <ListItemButton onClick={()=>setSelected('send')}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Send Notification" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>
              <Link to='admin-panel/list'>
              <ListItemButton onClick={()=>setSelected('list')}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="List of templates" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>
            </List>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
            <List>
              <Link to='/admin-panel'>
              <ListItemButton  onClick={()=>setSelected('send')}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Send Notification" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>
              <Link to='/admin-panel/list'>
              <ListItemButton onClick={()=>setSelected('list')}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="List of templates" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>

            </List>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <Toolbar />

          <h1 style={{color: '#7963E0', marginBottom: '2rem'}}>Edit Template</h1>

            {datos !== '' ?<> <CssTextFieldAdmin
            label={datos?.attributes.Title}
            fullWidth
            variant="filled"
            style={{ marginBottom: "2rem", color: "red" }}
            /* error={titulo === ''} */ /* helperText={errorAmount ? errorTextAmount : ''} */ type="text"
            className="input-makeNft md-3"
            onChange={(e) => {
              setTitulo(e.target.value);
            }}
          />
           <CssTextFieldAdmin
            label={datos?.attributes.Description}
            fullWidth
            variant="filled"
            style={{ marginBottom: "2rem", color: "red" }}
            /* error={titulo === ''} */ /* helperText={errorAmount ? errorTextAmount : ''} */ type="text"
            className="input-makeNft md-3"
            onChange={(e) => {
              setDescripcion(e.target.value);
            }}
          /></>: null} 

      

          <button onClick={updateTable} className="send-button-admin">
            Save Template
          </button>
          
          
        </Box>
      </Box>




      

    </div>
  )
}

export default Templates