
import React, { useContext } from 'react';
import Modal from 'react-modal';
import "./modal.css";
import { useNavigate } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";

// context
import { useWalletConnectClient } from '../../context/walletConnectContext'
import { UserContext } from '../../context/userContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function Modales({ _to, _amount, _from, _token_id, _token_address, _function, _icon, _title, _text, _url, _name, _description, _attributes, _emailTo, _currentAccount, _id, modalToParent, action, _nft, _transactionData }) {

  let subtitle;

  /* walletConnect */
  const { accept_transaction, reject_transaction } = useWalletConnectClient();
  let transactionName;
  if (_transactionData) {
    switch (_transactionData.request.method) {
      case "personal_sign":
        transactionName = "sign a message"
        break;
      case "eth_sign":
        transactionName = "sign a message"
        break;
      case "eth_sendTransaction":
        transactionName = "send a transaction"
        break;
      case "eth_signTransaction":
        transactionName = "sign a transaction"
        break;
      case "eth_signTypedData":
        transactionName = "sign a message"
        break;
      default:
        transactionName = _transactionData.request.method
        break;
    }
  };


  const [modalIsOpen, setIsOpen] = React.useState(true);

  const { Transfer, transfer_nft_721, transfer_nft_1155, mintNFT, deleteContact, setOpenConfirmationModal, estimacionDeGas } = useContext(UserContext)
  const navigate = useNavigate();

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    modalToParent();
  }

  function closeModalCreate() {
    setOpenConfirmationModal({open: false})
    setIsOpen(false);
    modalToParent();
    navigate("/home")
    window.location.reload()
  }

  function closeModalCreateOnFail() {
    window.location.reload()
  }

  function closeModalCreateOnSuccess() {
    navigate("/email")
    window.location.reload()
  }

  async function handleClick() {
    if (_function === "Transfer") {
      console.log(_to, _amount)
      Transfer(_to, _amount);
    }
    if (_function === "transfer_nft_721") {
      transfer_nft_721(_token_address, Number(_token_id), _from, _to);
    }
    if (_function === "transfer_nft_1155") {
      transfer_nft_1155(_token_address, Number(_token_id), _from, _to, _amount);
    }
    if (_function === "Create") {
      console.log(_url, _name, _description, _attributes, _amount, _currentAccount)
      mintNFT(_url, _name, _description, _attributes, _amount, _currentAccount);
      console.log(estimacionDeGas)
      console.log(typeof(estimacionDeGas))
    }
    if (_function === 'Delete_Contact') {
      console.log('LLEGA ID', _id)
      deleteContact(_id);
      setOpenConfirmationModal({open: true})
    }
    closeModal();
    //window.location.reload()
  };

  const handle_transaction = async (_accept) => {
    try {
      if (!_accept) await reject_transaction();
      else await accept_transaction();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='modal-base'>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        {(_icon === "no") && null}
        {(_icon === "check") && <i className="fa-regular fa-circle-check" />}
        {(_icon === "error") && <i className="fa-regular fa-circle-xmark" />}
        {(_icon !== "no" && _icon !== "check" && _icon !== "error") && <i className="fa-regular fa-circle-question" />}

        {(_title !== "" && _title !== undefined) ? (
          <h2 ref={(_subtitle) => (subtitle = _subtitle)} >{_title}</h2>
        ) : (
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Successfully {action}!</h2>
        )}
        <p style={{ whiteSpace: "pre-line" }}>
          {_text !== "" ? (_text) : ("footer_banner--googleBanner--3dAQe footer_banner--banner--H-B_t modal--modal--OAB3b modal--modalShadow--HbHkT modal--modalBare--2SeY8")
          }
        </p>
        {_function === 'Create' &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>NFT: </label> &nbsp; {_name}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Supply: </label> &nbsp; {_amount}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Price: </label> &nbsp; {estimacionDeGas === 'estimating..' ? 'estimating.. MATIC' : parseFloat(estimacionDeGas)/(10**18) + ' MATIC'}
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }
        {_function === 'Transfer' &&
          <>
            <p >
              <label style={{ fontWeight: "bold", color: 'black' }}>Amount: </label> &nbsp; {_amount} Matic.
            </p>
            <p >
              <label style={{ fontWeight: "bold", color: 'black' }}>Address: </label> &nbsp; {_to.slice(0, 5)}...{_to.slice(37)}
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }

        {(_function === "transfer_nft_1155" || _function === "transfer_nft_721") &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>NFT: </label> &nbsp; {_name}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Address: </label> &nbsp; {_to.slice(0, 5)}...{_to.slice(37)}
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }

        {_function === "Delete_Contact" &&
          <>

            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }

        {_function === "sendViaEmail" &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>NFT: </label> &nbsp; {_name}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Email: </label> &nbsp; {_to}
            </p>
          </>
        }
        {_function === "processing" &&
          <>
            <GridLoader color='#3C4AA5' style={{ marginBottom: "2rem" }} />
            <p style={{ whiteSpace: "pre-line", fontWeight: 'bold', marginTop: "2rem" }}>
              {_name}
            </p>
            <p style={{ whiteSpace: "pre-line", marginTop: "1rem", marginBottom: '1rem', fontWeight: '700' }}>
              <label style={{ fontWeight: "700" }}>{_description} </label> &nbsp;
            </p>
          </>
        }

        {_function === "wallet_connect" &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>WalletConnect wants you to &nbsp; {transactionName}</label>
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={() => handle_transaction(false)}>Reject</button>
              <button className='accept-button' onClick={() => handle_transaction(true)}>Sign</button>
            </div>
          </>
        }

        {
          _function === "sent_encrypted_message" &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Message succesfully sent</label>
            </p>
            <div className='buttons'>
              <button className='accept-button' onClick={closeModalCreateOnSuccess}>Continue</button>
            </div>
          </>
        }

        {
          _function === "encrypted_message_error" &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Error sending the message</label>
            </p>
            <div className='buttons'>
              <button className='accept-button' onClick={closeModalCreateOnFail}>Continue</button>
            </div>
          </>
        }

        {(_function === "" || _function === undefined) && (
          <div className='buttons'>
            <button className='done-button' variant='outlined' onClick={closeModalCreate}>Done</button>
          </div>
        )}

      </Modal>
    </div>
  );
}

export default Modales;