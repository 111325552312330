import React, { useContext, useEffect } from "react";
import LogoDappsSvg from "../../assets/Torta2.png";
import { Container, Row, Col, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
//context
import { UserContext } from "../../context/userContext";
import { FirebaseContext } from "../../context/firebaseContext";
import ByDapps from '../../assets/landing/byDapps.png'
import { GiCheckMark } from 'react-icons/gi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";

const Login = () => {
  const { connect, disconnect, currentAccount, loader } = useContext(UserContext);
  const { getTokenFCM, test } = useContext(FirebaseContext)
  
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAccount !== '') {
      navigate('/home')
    }
  }, [currentAccount])

 


  return (
    <>
      <div className="centrar-todo login">
        <Container>
          <Row className="tamaño-imagenes row-login" style={{ marginBottom: "1rem", alignItems: "center" }}>
            <Col lg='6'>
              <img src={LogoDappsSvg} alt="Dapps Factory" width="100%" className='img-login' />
              
            </Col>
            <Col lg='6'>
              <label style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#B66DF3", fontFamily: "Roboto", textAlign: 'justify' }}>
                USER FRIENDLY WALLET
              </label>
              <Container>
                <ul className='list-ul'>
                  <li className='li-login'><GiCheckMark className='check-login' /> &nbsp;View NFT</li>
                  <li className='li-login'><GiCheckMark className='check-login' /> &nbsp;Create NFT</li>
                  <li className='li-login'><GiCheckMark className='check-login' /> &nbsp;Send NFT</li>
                  <li className='li-login'><GiCheckMark className='check-login' /> &nbsp;Share NFT with friends</li>
                </ul>
              </Container>
            </Col>
          </Row>
          <Stack className="form-width login-footer">
            {!currentAccount ? <Button onClick={connect} className='send-button'>{loader ? <GridLoader color='white' style={{ fontSize: '0.5rem' }} /> : 'Login'}</Button> : <Button className='send-button' onClick={disconnect}>LogOut</Button>}
            <Link to='/landing' preventScrollReset={false}>
              <label className='d-flex align-items-center justify-content-center mb-4 '>
                <AiOutlineInfoCircle />
                &nbsp;Learn more about this wallet
              </label>
            </Link>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={ByDapps} alt='' style={{ width: '50%' }} />
            </div>
          </Stack>
        </Container>
      </div>
      <FooterDesktopCompleto />
    </>
  );

};

export default Login;
