import React, { useEffect, useState } from "react";
import Moralis from "moralis";
import { Link } from 'react-router-dom';
import axios from "axios";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PocLogo from "../../assets/logos/pocCompleto.png";
import SendIcon from "@mui/icons-material/Send";
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import "./AdminPanel.css";
import SendNotification from "./sendNotification/SendNotification";
import Templates from "./templates/Templates";
import ListOfNotifications from "./listOfNotifications/ListOfNotifications";
import { ethers } from "ethers";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

const AdminPanel = (props) => {


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const Web3Api = useMoralisWeb3Api();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerWidth = 240;

  const drawer = (
    <div>
      <Toolbar sx={{ backgroundColor: "#13054C" }}>
        <img
          src={PocLogo}
          alt=""
          height="65px"
          style={{ marginLeft: "-2rem" }}
        />
      </Toolbar>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  // GET BALANCE
  const fetchDefenderBalance = async () => {
    // get mainnet native balance for the current user
    const options = {
      address: '0xfa8073810542d5d1830c81525534f67128b34870',
      chain: Moralis.Chains.POLYGON_MAINNET,
    };
    const balance = await Web3Api.account.getNativeBalance(options);
    //console.log('balance', balance)
    
    const maticValue = ethers.utils.formatEther(balance.balance);
    console.log(maticValue);
  };

  useEffect(() => {
    fetchDefenderBalance()
  }, [])
  


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ backgroundColor: "#13054C" }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                color: "black",
              },
              "& .MuiDrawer-docked": {
                color: "black",
              },
            }}
          >
            {drawer}
            <List>
              <Link to='/admin-panel'>
              <ListItemButton>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Send Notification" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>

              <Link to='/admin-panel/list'>
              <ListItemButton>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="List of templates" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>
            </List>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
            <List>
              
            <Link to='/admin-panel'>
              <ListItemButton>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Send Notification" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>

              <Link to='/admin-panel/list'>
              <ListItemButton>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="List of templates" style={{color: 'black'}}/>
              </ListItemButton>
              </Link>
            </List>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <Toolbar />
          <SendNotification/>
         
          
        </Box>
      </Box>
    </>
  );
};

export default AdminPanel;
