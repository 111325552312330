const key = process.env.REACT_APP_PINATA_DTC_API_KEY;
const secret = process.env.REACT_APP_PINATA_DTC_SECRET;
const axios = require('axios');
var FormData = require('form-data');

export const pinJSONToIPFS = async(JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    return axios
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: key,
                pinata_secret_api_key: secret,
            }
        })
        .then(function (response) {
           return {
               success: true,
               pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }
        });
};

export const sendJSONToIPFS = async (json) => {
    if (json) {
        try {
            const resFile = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
                headers: {
                    'pinata_api_key': `${process.env.REACT_APP_PINATA_DTC_API_KEY}`,
                    'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_DTC_SECRET}`,
                    'Content-Type': 'application/json',                 },
                data: json,
            });

            const imgHash = `https://ipfs.io/ipfs/${resFile.data.IpfsHash}`;
            return imgHash
//Take a look at your Pinata Pinned section, you will see a new file added to you list.   
        } catch (error) {
            console.log("Error sending File to IPFS: ")
            console.log(error)
        }
    }
} 

export const sendFileToIPFS = async (file) => {
    if (file) {
        try {
            var data = new FormData();
            data.append('file', file, 'image.jpg');
            // data.append('pinataOptions', '{"cidVersion": 1}');
            // data.append('pinataMetadata', '{"name": "MyFile", "keyvalues": {"company": "Pinata"}}');            

            const resFile = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                headers: {
                    'pinata_api_key': `${process.env.REACT_APP_PINATA_DTC_API_KEY}`,
                    'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_DTC_SECRET}`,
                    'Content-Type': 'application/json',                 },
                data: data,
            });

            const imgHash = `https://digitaltradingcards.mypinata.cloud/ipfs/${resFile.data.IpfsHash}`;
            return imgHash
            //Take a look at your Pinata Pinned section, you will see a new file added to you list.   
        } catch (error) {
            console.log("Error sending File to IPFS: ")
            console.log(error)
        }
    }
} 

export const sendFileToIPFSEncriptado = async (file) => {
    if (file) {
        try {
            var data = new FormData();
            data.append('file', file);
        
            const resFile = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                headers: {
                    'pinata_api_key': `${process.env.REACT_APP_PINATA_DTC_API_KEY}`,
                    'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_DTC_SECRET}`,
                    'Content-Type': 'application/json',              
                },
                data: data,
            });

            const imgHash = `https://ipfs.io/ipfs/${resFile.data.IpfsHash}`; //`https://dappsfactory.mypinata.cloud/ipfs/${resFile.data.IpfsHash}`;
            return imgHash
            //Take a look at your Pinata Pinned section, you will see a new file added to you list.   
        } catch (error) {
            console.log("Error sending File to IPFS: ")
            console.log(error)
        }
    }
} 

export function base64toBlob(image, contentType) {
    const splitDataURI = image.split(',')
    
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(splitDataURI[1])
    // var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}