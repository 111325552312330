import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { UserContext } from '../../context/userContext';

const MintSuccess = () => {
    const { image } = useContext(UserContext);

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-center mt-5">
                    <img src={image} alt="200" width="300px" />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center mt-3">
                    <h1 className="display-5">Minting successful!</h1>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <h4 className="display-5">Minting successful!</h4>
                </Col>
            </Row>
        </Container >
    );
}

export default MintSuccess