import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./MisNFT.css";
import CardNFT from '../../utils/CardNFT/CardNFT'
import CardLoadMetadata from '../../utils/CardLoadMetadata/CardLoadMetadata'
import { Container, Row, Col } from "react-bootstrap";
import Navigation from "../navigation/Navigation";
import usePagination from "../../utils/pagination/pagination"
import GoBack from "../goBack/GoBack";
import FooterOpciones from '../footer-completo/footer-opciones'
import GridLoader from "react-spinners/GridLoader";
import BounceLoader from "react-spinners/BounceLoader";
import Pagination from '@mui/material/Pagination';
import NFTBigger from '../../assets/nftBigger.png'


//context
import { UserContext } from "../../context/userContext";
import { MdRefresh } from "react-icons/md";
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";
import BalanceHeader from "../balance-header/balance-header";

const MisNft = () => {
  const { listOfNft, provider, fetchNFTOwners } = useContext(UserContext);
  const urlParams = useParams()
  const url = urlParams["*"]

  //trim the URL from useParams hook
  const fields = url.split('/');
  const index = fields[0] + '/' + fields[1];

  const [initialLoading, setInitialLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);

  // Pagination
  const [page, setPage] = React.useState(1);
  const limit = 12;
  const _DATA = usePagination(listOfNft, limit);

  const [paginationLength, setPaginationLength] = useState(1);

  const handlePaginationChange = (event, value) => {
    setPaginationLoading(true)
    setPage(value);
    _DATA.jump(value)
    setTimeout(() => {
      setPaginationLoading(false)
    }, 1000)
  }

  useEffect(() => {
    setTimeout(() => {
      fetchNFTOwners();
    }, [1500])
  }, [])

  useEffect(() => {
    if (listOfNft.length >= 0) {
      setPaginationLength(listOfNft.length)
      setTimeout(() => {
        setInitialLoading(false);
      }, [1000])
    }
  }, [provider])




  //sacar el "!"
  if (initialLoading) {
    return <GridLoader color='#AE74FF' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
  } else {
    return (
      <>
        <div className='min-height-margin'>
          <Navigation />
          <BalanceHeader />
          <GoBack value={index === 'my-nfts/send' ? 'Select NFT to Transfer' : 'My NFTs'} link={index === 'my-nfts/send' ? '/send' : '/home'} />
          <Container className="mis-nft">
            <Row className="row-cards">
              {
                (paginationLoading) ?
                  (
                  <BounceLoader color='#AE74FF' size='100px'  />)
                  :
                  (listOfNft.length > 0 ?
                    (_DATA.currentData().map((nft, index) => (
                      (nft?.metadata === null && nft?.token_uri !== null) ?
                      
                        
                          <Col xs={6} sm={6} md={4} lg={3} xl={2} key={index}>
                            <CardNFT key={index} id={nft.token_hash} creator='creator' name={"We don't have the content for this NFT yet. Check back later."} img={"cargando"} address={nft.token_address} />
                          </Col>
                        
                        :
                        (nft.metadata === null && nft.token_uri === null) ?
                          
                            <Col xs={6} sm={6} md={4} lg={3} xl={2} key={index}>
                              <CardLoadMetadata _tokenId={nft.token_id} _tokenAddress={nft.token_address} />
                            </Col>
                         
                          :
                         
                            <Col xs={6} sm={6} md={4} lg={3} xl={2} key={index}>
                              {/*<CardNFT key={nft.token_hash} id={nft.token_hash} creator='creator' name={JSON.parse(nft?.metadata)?.name} img={(((JSON.parse(nft?.metadata)?.image)?.slice(0,7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) :  (((JSON.parse(nft?.metadata)?.image)?.slice(0,12)) === "ipfs://ipfs/") ? "https://ipfs.io/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : JSON.parse(nft?.metadata)?.image} link={url === 'my-nfts' ? `/my-nfts/${nft.token_hash}` : `/my-nfts/send/${nft.token_hash}`} />*/}
                              <CardNFT id={nft.token_hash} creator='creator' name={JSON.parse(nft?.metadata)?.name} img={(((JSON.parse(nft?.metadata)?.image)?.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : (((JSON.parse(nft?.metadata)?.image)?.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image} link={url === 'my-nfts' ? `/my-nfts/${nft.token_hash}` : `/my-nfts/send/${nft.token_hash}`} />
                            </Col>
                        
                        )
                      )
                    )
                    :
                    ((paginationLoading) ? (<></>) : (<>
                      <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <img src={NFTBigger} alt='nft' />
                        <p style={{ fontSize: '21px' }}>STILL NO NFTS HERE</p>
                      </div>
                    </>)
                    )
                  )
              }
            </Row>
            <Row>
              {(paginationLoading) ?
                (
                  null
                )
                :
                (
                  listOfNft.length === 0 ? null : <Pagination count={Math.max(Math.ceil(paginationLength / limit), 1)} page={page} onChange={handlePaginationChange} color="secondary" />
                )
              }
            </Row>
          </Container>
        </div>
        <FooterOpciones />
        <FooterDesktopCompleto />
      </>
    );
  }
};

export default MisNft;