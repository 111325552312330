import React, { useContext } from 'react';
import './Navigation-Landing.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";


import { UserContext } from "../../context/userContext";


const NavigationLanding = () => {

    let navigate = useNavigate();

    const goLogin = () => {
        navigate('/home');
      }

    return (
        <Container>
            <Row className='d-flex align-items-center pt-1 pb-1' style={{marginTop: '0.5rem', marginTop: '0.5rem', marginBottom: '0.5rem', marginLeft: '0.5rem'}}>
            <Col >
                <Link to='/home' className='logo'>
                    <label style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}> DApps</label><label style={{ fontSize: '18px' }}>Factory</label>
                </Link>
            </Col> 
            <Col style={{textAlign: 'right'}}>
                <Button onClick={goLogin} className='send-button-landing-2'>Login | Create your Wallet</Button>
            </Col> 
            </Row>
        </Container>
    )
}

export default NavigationLanding;