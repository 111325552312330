/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { ethers } from "ethers";
import { useMoralis } from "react-moralis";
import { UserContext } from "./userContext";
import SignClient from "@walletconnect/sign-client";
import LegacySignClient from '@walletconnect/client';

const WCContext = React.createContext([]);

WCContext.displayName = "WCContext";


export const WCProvider = ({ children }) => {

    const { isAuthenticated } = useMoralis();

    const { ethersProvider } = React.useContext(UserContext);

    const [signClient, setSignClient] = React.useState();
    const [legacySignClient, setLegacySignClient] = React.useState();
    const [session, setSession] = React.useState();

    // login (session_proposal)
    const [openSessionModal, setOpenSessionModal] = React.useState({ open: false, data: undefined });

    // sign transaction
    const [openTransactionModal, setOpenTransactionModal] = React.useState({ open: false, data: undefined, version: undefined });

    React.useEffect(() => {
        if (!signClient && ethersProvider) createClient();
        if (!legacySignClient) legacy_pair();
    }, [ethersProvider, isAuthenticated]);


    /* Sign Client */
    const createClient = async () => {

        if (!ethersProvider) return { success: false }

        try {

            const _client = await SignClient.init({
                projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
                metadata: {
                    name: "Piece of cake wallet",
                    description: "User friendly wallet | by DApps Factory",
                    url: "http://pieceofcakewallet.com/",
                    icons: ["https://pieceofcakewallet.com/static/media/Torta2.002870e4.png"],
                },
            });

            setSignClient(_client);

            // propuesta de sesion 
            _client.on("session_proposal", async ({ id, params }) => {
                setOpenSessionModal({ open: true, data: { id: id, params: params } });
            });

            // Handle session events, such as "chainChanged", "accountsChanged", etc.
            _client.on("session_event", (event) => {

                // interface Event {
                //   id: number;
                //   topic: string;
                //   params: {
                //     event: { name: string; data: any };
                //     chainId: string;
                //   };
                // }
                console.log("session_event", event);
            });

            // Handle session method requests, such as "eth_sign", "eth_sendTransaction", etc.
            _client.on("session_request", async (event) => {

                const request = event.params.request;
                const id = event.id;
                const topic = event.topic;

                console.log('transaction_requested: ', event);


                setOpenTransactionModal({
                    open: true,
                    data: {
                        request: request,
                        id: id,
                        topic: topic,
                    },
                    version: 2
                });

            });

            // React to session ping event
            _client.on("session_ping", (event) => {

                // interface Event {
                //   id: number;
                //   topic: string;
                // }
                console.log("session_ping", event);
            });

            // React to session delete event
            _client.on("session_delete", (event) => {

                console.log("session_delete", event);
                setSession(null);
            });

            return { success: true, client: _client };
        } catch (err) {
            console.log('error en el connect', err)
            throw err;
        }
    };

    const pair = async (_uri) => {
        if (typeof signClient === "undefined") throw new Error("WalletConnect is not initialized");

        try {
            // esto triggerea el session_proposal event
            await signClient.core.pairing.pair({ uri: _uri });
        } catch (e) {
            console.log('llega al catch, ', e)
            console.error(e);
        }
    };


    /* Version 1 */

    const legacy_pair = async (_uri) => {
        let _legacySignClient;
        try {
            if (!ethersProvider) return

            if (_uri) {
                await deleteCachedLegacySession()
                _legacySignClient = new LegacySignClient({ uri: _uri })
                console.log('El legacy client: ', _legacySignClient);
                setLegacySignClient(_legacySignClient)
            } else if (!_legacySignClient && await getCachedLegacySession()) {
                const session = await getCachedLegacySession()
                _legacySignClient = new LegacySignClient({ session });
                console.log('El legacy client cacheado: ', _legacySignClient);
                setLegacySignClient(_legacySignClient)
            } else {
                return
            }

            /* Eventos */

            _legacySignClient.on('session_request', async (error, payload) => {
                console.log('session_request', payload);
                if (error) {
                    throw new Error(`legacySignClient > session_request failed: ${error}`)
                };
                setOpenSessionModal({ open: true, data: payload });
            });

            _legacySignClient.on("call_request", (error, payload) => {
                if (!!error) {
                    throw error;
                };

                console.log('transaction required', payload)

                const request = payload;
                const id = payload.id;


                setOpenTransactionModal({
                    open: true,
                    data: {
                        request: request,
                        id: id,
                        topic: id,
                    },
                    version: 1
                });
            });

            _legacySignClient.on("session_update", (error, payload) => {
                console.log('session_update: ', payload)
            })

            _legacySignClient.on("wc_sessionUpdate", (error, payload) => {
                console.log('wc_sessionUpdate', payload)
            })

            _legacySignClient.on("wc_sessionRequest", (error, payload) => {
                console.log('wc_sessionRequest', payload);
            })

            _legacySignClient.on("connect", (error, payload) => {
                console.log('connect', payload)
            })

            _legacySignClient.on("disconnect", (error, payload) => {
                if (!!error) {
                    throw error;
                };

                if (payload.event === 'disconnect') setLegacySignClient(null);
            })


        } catch (error) {
            console.error(error)
        }
    };

    const accept_legacy_session = async () => {
        try {
            console.log('chainId: ', openSessionModal.data.params[0].chainId);
            const signer = ethersProvider.getSigner();
            const myAddress = await signer.getAddress();
            legacySignClient.approveSession({
                accounts: [myAddress],
                chainId: openSessionModal.data.params[0].chainId,
            });
            setOpenSessionModal({ open: false, data: undefined });
        } catch (error) {
            console.error(error)
        }
    };

    const reject_legacy_session = async () => {
        try {
            legacySignClient.rejectSession({ message: 'User rejected the connection' });
            setOpenSessionModal({ open: false, data: undefined });
        } catch (error) {
            console.error(error)
        }
    };

    const getCachedLegacySession = async () => {
        if (typeof window === 'undefined') return

        const local = window.localStorage ? window.localStorage.getItem('walletconnect') : null

        let session = null
        if (local) {
            try {
                session = JSON.parse(local)
            } catch (error) {
                throw error
            }
        }
        return session
    };

    const deleteCachedLegacySession = async () => {
        if (typeof window === 'undefined') return
        window.localStorage.removeItem('walletconnect')
    };

    /* Sesion */

    const accept_session = async () => {
        const params = openSessionModal.data.params;
        const id = openSessionModal.data.id;
        try {
            const signer = ethersProvider.getSigner();
            const myAddress = await signer.getAddress();
            const accounts = params.requiredNamespaces.eip155.chains.map(chain => `${chain}:${myAddress}`);
            // Approve session proposal, use id from session proposal event and respond with namespace(s) that satisfy dapps request and contain approved accounts
            const { /*topic,*/ acknowledged } = await signClient.approve({
                id: id,
                namespaces: {
                    eip155: {
                        accounts: accounts,
                        methods: params.requiredNamespaces.eip155.methods,
                        events: params.requiredNamespaces.eip155.events,
                        extension: params.requiredNamespaces.eip155.extension,
                    },
                },
            });

            // Optionally await acknowledgement from dapp
            const _session = await acknowledged();

            setSession(_session);
            setOpenSessionModal({ open: false, data: undefined });
        } catch (error) {
            console.error(error)
        }
    };

    const reject_session = async () => {
        const id = openSessionModal.data.id;
        try {
            await signClient.reject({
                id: id,
                reason: {
                    code: 1,
                    message: "User rejected",
                },
            });
            setOpenSessionModal(false);
        } catch (error) {
            console.error(error)
        }
    };

    const disconnect_session = async (_topic = undefined) => {
        try {
            if (_topic) await signClient.disconnect({ topic: _topic })
            else await legacySignClient.killSession();
        } catch (error) {
            console.error(error)
        }
    };

    /* Transacciones */

    const accept_transaction = async () => {
        try {
            const signer = ethersProvider.getSigner();
            let response;
            switch (openTransactionModal.data.request.method) {
                case "personal_sign":
                    const hash1 = ethers.utils.arrayify(...openTransactionModal.data.request.params);
                    response = await signer.signMessage(hash1)
                    break

                case "eth_sign":
                    const hash2 = ethers.utils.arrayify(openTransactionModal.data.request.params[1]);
                    response = await signer.signMessage(hash2);
                    break

                case "eth_sendTransaction":
                    const tx = {
                        to: openTransactionModal.data.request.params[0].to,
                        data: openTransactionModal.data.request.params[0].data,
                        value: openTransactionModal.data.request.params[0].value,
                        gasLimit: openTransactionModal.data.request.params[0].gasLimit,
                        gasPrice: openTransactionModal.data.request.params[0].gasPrice,
                        nonce: openTransactionModal.data.request.params[0].nonce,
                    };
                    const txResponse = await signer.sendTransaction(tx);
                    response = txResponse.hash;
                    break

                case "eth_signTransaction":
                    const privateKey = await ethersProvider.provider.request({
                        method: "eth_private_key"
                    });
                    const wallet = new ethers.Wallet(privateKey);

                    const tx2 = {
                        from: openTransactionModal.data.request.params[0].from,
                        to: openTransactionModal.data.request.params[0].to,
                        data: openTransactionModal.data.request.params[0].data,
                        value: openTransactionModal.data.request.params[0].value,
                        gasLimit: openTransactionModal.data.request.params[0].gasLimit,
                        gasPrice: openTransactionModal.data.request.params[0].gasPrice,
                        nonce: openTransactionModal.data.request.params[0].nonce,
                    }
                    response = await wallet.signTransaction(tx2);
                    break

                case "eth_signTypedData":
                    const encryptedMessage = JSON.parse(openTransactionModal.data.request.params[1]);
                    delete encryptedMessage.types.EIP712Domain;
                    response = await signer._signTypedData(encryptedMessage.domain, encryptedMessage.types, encryptedMessage.message);
                    break

                case "wallet_switchEthereumChain":
                    response = 'approve';
                    console.log('aca llega')
                    break

                default:
                    throw new Error('INVALID_METHOD')
            };

            const jsonresponse = {
                id: openTransactionModal.data.id,
                jsonrpc: "2.0",
                result: response,
            };

            if (openTransactionModal.version === 1) {
                legacySignClient.approveRequest({
                    id: openTransactionModal.data.id,
                    result: response,
                });
            } else {
                await signClient.respond({
                    topic: openTransactionModal.data.topic,
                    response: jsonresponse,
                });
            }

        } catch (error) {
            console.log(error);
        }
    };

    const reject_transaction = async () => {
        try {
            const jsonresponse = {
                id: openTransactionModal.data.id,
                jsonrpc: "2.0",
                result: "User rejected the request",
            };

            if (openTransactionModal.version === 1) {
                legacySignClient.rejectRequest({
                    id: openTransactionModal.data.id,
                    error: {
                        message: "User rejected the request"
                    }
                });
            } else {
                await signClient.respond({
                    topic: openTransactionModal.data.topic,
                    response: jsonresponse,
                });
            }


        } catch (error) {

        }
    };

    return (
        <WCContext.Provider value={{
            signClient,
            legacySignClient,
            session,
            createClient,
            pair,
            legacy_pair,
            openSessionModal,
            setOpenSessionModal,
            accept_session,
            reject_session,
            disconnect_session,
            accept_legacy_session,
            reject_legacy_session,
            openTransactionModal,
            setOpenTransactionModal,
            accept_transaction,
            reject_transaction,
        }}>
            {children}
            <w3m-modal></w3m-modal>
        </WCContext.Provider>
    );
};

export const useWalletConnectClient = () => {
    const context = React.useContext(WCContext);
    if (context === undefined) {
        throw new Error(
            "useWalletConnectClient must be used within a ClientContextProvider"
        );
    }
    return context;
};