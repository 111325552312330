import * as React from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { useWalletConnectClient } from '../../context/walletConnectContext';

const ConnectInput = () => {

    const { signClient, pair, createClient, legacy_pair } = useWalletConnectClient();

    const [uri, setUri] = React.useState();

    const connect = async (result) => {
        try {
            if (!!result) {
                /* version 1 */
                if (result.includes('@1?bridge')) await legacy_pair(result)
                /* version 2 */
                else if (signClient) await pair(result);
                else if (!signClient) await createClient()
            }
        } catch (err) {
            console.log(err)
        }
    };

    return (
        <>
            <Row className="justify-content-center">or use the WalletConnect URI</Row>
            <Row className="justify-content-center my-2 mx-3">
                <Form.Control
                    required
                    type="text"
                    placeholder="Uri"
                    name="uri"
                    id="uri"
                    onChange={(e) => setUri(e.target.value)}
                    style={{ maxWidth: '530px', minWidth: '350px' }}
                />
            </Row>
            <Row className="d-flex justify-content-center my-2 mx-3">
                <Button type="submit" className="w-50 send-nft" style={{ maxWidth: '530px', minWidth: '350px' }} onClick={() => connect(uri)}>Connect</Button>
            </Row>
        </>
    )
}

export default ConnectInput