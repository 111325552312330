import React, { useContext, useState, useEffect } from "react";
import "./recent-destinations.css";
// import "./carousel.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Container, Stack } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { AiOutlineRight } from 'react-icons/ai';



const RecentDestinations = ({ param, _title, setReceiver }) => {
  const { contacts } = useContext(UserContext);
  const [open, setOpen] = useState(false)
  const [contactIndex, setContactIndex] = useState(0)

  function handleCopy(address, index) {
    console.log(index)
    setContactIndex(index)
    setOpen(true)
    navigator.clipboard.writeText(address);
    // alert('copied');
    setTimeout(()=>{
      setOpen(false)
    }, 500)
    handlePaste();
  }

  function handlePaste() {
      navigator.clipboard
    .readText()
    .then((clipText) => setReceiver(clipText));
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5
    }
  };

  /* const chunks = contacts?.results?.map((item, index) => {
    return index % 5 === 0 ? contacts?.results?.slice(index, index + 5) : null;
  }).filter(item => item);

  useEffect(() => {
    console.log("chunks", contacts?.results, chunks)
  }, [contacts, chunks]) */

  return (
    <>
      {contacts?.results?.length > 0 && <Container>
        <h5 className="titleh5" style={_title && {textAlign: 'center'}}>{_title ?  _title : 'Recently Added'}</h5>
        
        <Carousel responsive={responsive} containerClass="container" infinite={true} arrows={false}>
            {contacts?.results?.map((contact, index) => (
              contact.attributes?.isActive === true &&
              <Tooltip title='Copied' open={contactIndex === index && open } key={index} disableFocusListener disableHoverListener disableTouchListener>
                <div className="contact" key={contact.attributes.contactAddress} onClick={(e) => handleCopy(contact.attributes.contactAddress, index)}>
                  <div className="circle">{contact.attributes.contactName.slice(0, 2)}</div>
                  <p>{contact.attributes.contactName}</p>
                  <p>{contact.attributes.contactAddress.slice(0, 3)}... {contact.attributes.contactAddress.slice(39)}</p>
                </div>
              </Tooltip>
            ))}
            {(param === "agenda" || (contacts?.results === [] && param === "send")) && (
          <div className="contact">
              <Tooltip title='Add Contact'>
                <Link to="/add-contact">
                  <div className="circle addContact">
                    <i className="fa-solid fa-user-plus"></i>
                  </div>
                  <p>Add</p>
                  <p>Contact</p>
                </Link>
              </Tooltip>
          </div>
            )}
        </Carousel>
        
      </Container>}
    </>
    // <>
    //   <Carousel showArrows={true}>
    //     <div>
    //         <img src="https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg" />
    //         <p className="legend">Legend 1</p>
    //     </div>
    //     <div>
    //         <img src="https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg" />
    //         <p className="legend">Legend 2</p>
    //     </div>
    //     <div>
    //         <img src="https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg" />
    //         <p className="legend">Legend 3</p>
    //     </div>
    //     <div>
    //         <img src="https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg" />
    //         <p className="legend">Legend 4</p>
    //     </div>
    // </Carousel>
    // </>
  );
};

export default RecentDestinations;