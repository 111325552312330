/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import Moralis from "moralis";
import { styled } from '@mui/material/styles';
import { Container, Stack, Form, Row, Col } from "react-bootstrap";
import Navigation from "../navigation/Navigation";
import { ethers } from "ethers";
import { encrypt as encryptAsymetric } from "eciesjs";
import CryptoJS from "crypto-js";
//import { generateEncryptionKey } from "symmetric-encrypt";
import { UserContext } from "../../context/userContext";
import { MessageContext } from "../../context/messageContext";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// import Fab from '@mui/material/Fab';
import FooterOpciones from '../footer-completo/footer-opciones';
import FooterDesktopCompleto from '../footer-desktop-completo/footer-desktop-completo';
import GoBack from '../goBack/GoBack';
import "./SendMessage.css";
import MenuItem from '@mui/material/MenuItem';
import Modales from "../../utils/modal/modal";
import { sendFileToIPFSEncriptado} from "../../utils/MakeNft/pinata";
// import ReactPlayer from 'react-player';
import { Button } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const CssTextField = styled(TextField)({
  // '& .MuiInputBase-input': { // color de los valores del input en formulario
  //   color: 'rgba(255, 255, 255, 0.5)'
  // },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgba(255, 255, 255, 0.5)',
  },
  '& .MuiTypography-root': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  '& .MuiTextField-root': {
    borderBottomColor: 'red',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  '& .MuiMenuItem-root': {
    color: 'black !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottomColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  }
});

// financiera 1 public key = 0x04998c071daf3d003ebee81244e6251ceedc6d0f34433143b6f0d7b0e695c157e0232901cbdaea6df92f6a4cac8d4aef29c8f022516196f7eece71a014a27c5d94
const SendMessage = () => {
  const [to, setTo] = useState('');
  const [contactos, setContactos] = useState([]);
  const [clavePublicaUsuario, setClavePublicaUsuario] = useState('');
  const [clavePublicaUsuarioFrom, setClavePublicaUsuarioFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  // user context
  const { currentAccount, user, ethersProvider } = useContext(UserContext);
  // message context
  const { sendEncryptedMessageContract, openMessageSent, openErrorMessage, openProcessingModal } = useContext(MessageContext);

  const [file, setFile] = useState();

  const encryptMessage = async (_linkToFile) => {

    //Primero creamos la clave simetrica para los mensajes
    const walletParaClave = ethers.Wallet.createRandom();
    const claveSimetricaSinEncriptar = walletParaClave.privateKey;
    console.log("ClaveSimetrica antes del sha256", claveSimetricaSinEncriptar);

    //Encriptamos la clave simetrica con encriptacion asimetrica correspondiente a cada usuario
    let _claveSimetricaEncriptadaReceiver = encryptAsymetric(clavePublicaUsuario, Buffer.from(claveSimetricaSinEncriptar));
    let _claveSimetricaEncriptadaSender = encryptAsymetric(clavePublicaUsuarioFrom, Buffer.from(claveSimetricaSinEncriptar));

    console.log("_claveSimetricaEncriptadaReceiver", _claveSimetricaEncriptadaReceiver.toString('hex'));
    console.log("_claveSimetricaEncriptadaSender", _claveSimetricaEncriptadaSender.toString('hex'));
    

    //Encriptamos las partes correspondientes mensaje con la clave simetrica
    //Ahora vamos a usar otra lbireria
    //const encryptSimetric = await generateEncryptionKey(claveSimetricaSinEncriptar);
    
    const date = Date.now();
    const dateToString = date.toString();

    const _firma = await firma(content, subject, _linkToFile, currentAccount, to, date)
    
    //var textoCifrado = CryptoJS.AES.encrypt(textoACifrar, claveSimetricaSinEncriptar).toString();

    const asuntoEncriptadoSimetrico = await CryptoJS.AES.encrypt(subject, claveSimetricaSinEncriptar).toString();
    const mensajeEncriptadoSimetrico = await CryptoJS.AES.encrypt(content, claveSimetricaSinEncriptar).toString();
    const archivoEncriptadoSimetrico = await CryptoJS.AES.encrypt(_linkToFile, claveSimetricaSinEncriptar).toString();
    const firmaEncriptadoSimetrico = await CryptoJS.AES.encrypt(_firma, claveSimetricaSinEncriptar).toString();
    const horaEncriptadoSimetrico = await CryptoJS.AES.encrypt(dateToString, claveSimetricaSinEncriptar).toString();

    console.log("asunto", asuntoEncriptadoSimetrico);
    console.log("mensaje", mensajeEncriptadoSimetrico);
    console.log("archivo", archivoEncriptadoSimetrico);
    console.log("firma", firmaEncriptadoSimetrico);
    console.log("hora", horaEncriptadoSimetrico);

    /*const JSONasuntoEncriptadoSimetrico = JSON.stringify(asuntoEncriptadoSimetrico);
    const JSONmensajeEncriptadoSimetrico = JSON.stringify(mensajeEncriptadoSimetrico);
    const JSONarchivoEncriptadoSimetrico = JSON.stringify(archivoEncriptadoSimetrico);
    const JSONfirmaEncriptadoSimetrico = JSON.stringify(firmaEncriptadoSimetrico);
    const JSONhoraEncriptadoSimetrico = JSON.stringify(horaEncriptadoSimetrico);*/

    /* let _encriptedSubject = encrypt(clavePublicaUsuario, Buffer.from(subject));
    let _encriptedMessage = encrypt(clavePublicaUsuario, Buffer.from(content)); 
    let _encriptedLink = encrypt(clavePublicaUsuario, Buffer.from(_linkToFile));  */

    //const info = [_encriptedMessage.toString('hex'), currentAccount, to, date.toString(), _encriptedSubject.toString('hex'), true, false/*, _encriptedLink.toString(), "firma"*/]

    /* let _encriptedSubjectSalida = encrypt(clavePublicaUsuarioFrom, Buffer.from(subject));
    let _encriptedMessageSalida = encrypt(clavePublicaUsuarioFrom, Buffer.from(content)); // aca se encripta el mensaje
    let _encriptedLinkSalida = encrypt(clavePublicaUsuarioFrom, Buffer.from(_linkToFile)); */
    //const infoSalida = [_encriptedMessageSalida.toString('hex'), currentAccount, to, date.toString(), _encriptedSubjectSalida.toString('hex'), true, false/*, _encriptedLinkSalida.toString(), "firma"*/]

    //Firmar data en crudo, sin los hashes respectivos a los usuarios
    //Firmar las sigs variables: content (es el mensaje), subject (es el asunto), _linkToFile, date, to, currentAccount

    //Deployar contrato para chequear la firma (recordatorio , no se va a chequear acá se hace en bandeja de entrada/salida)
    //Agarrar del ethers de userContext el signer (cuenta actual) y llamar a la siguiente función
    //https://docs.ethers.org/v5/api/signer/#Signer-signMessage

    //1ro probar si podemos hacer la firma sin distinguir entre metamask y web3
    //Si no se puede tenemos que dividir los casos y hacer los distintos llamados para sus respectivas firmas

    /*console.log(typeof (date))

    
    let _firmaEncriptada = encrypt(clavePublicaUsuario, Buffer.from(_firma));
    let _firmaEncriptadaSalida = encrypt(clavePublicaUsuarioFrom, Buffer.from(_firma));


    const infoMasFirma = [_encriptedMessage.toString('hex'), currentAccount, to, date.toString(), _encriptedSubject.toString('hex'), true, false, _encriptedLink.toString('hex'), _firmaEncriptada.toString('hex')]
    const infoSalidaMasFirma = [_encriptedMessageSalida.toString('hex'), currentAccount, to, date.toString(), _encriptedSubjectSalida.toString('hex'), true, false, _encriptedLinkSalida.toString('hex'), _firmaEncriptadaSalida.toString('hex')]
    */
    
    await chequeoFirma(content, subject, _linkToFile, currentAccount, to, date, _firma);

    /* string memory _encryptedSymetricKeyEnvio,       // Clave simetrica encriptada con la clave publica del msg.sender
    string memory _encryptedSymetricKeyRecepcion,   // Clave simetrica encriptada con la clave publica del Receptor
    address _addressTo,                             // Dirección de envío 
    string memory _asunto,                          // Encriptado por simentric key
    string memory _mensajeBody,                     // Encriptado por simentric key
    string memory _archivoAdjunto,                  // Encriptado por simentric key
    string memory _firmaMensaje,                    // Encriptado por simentric key 
    string memory _timeHash                         // Encriptado por simentric key */
    sendEncryptedMessageContract(_claveSimetricaEncriptadaSender.toString('hex'), _claveSimetricaEncriptadaReceiver.toString('hex'), to, 
    asuntoEncriptadoSimetrico, mensajeEncriptadoSimetrico, archivoEncriptadoSimetrico, firmaEncriptadoSimetrico, horaEncriptadoSimetrico);
  };

  const handleSubmit = async (event) => {
    try {
      console.log("archivo:",file)
      let linkToFile = "";
      
      if(file !== undefined){
        
        linkToFile = await sendFileToIPFSEncriptado(file);
        console.log("Archivo pero ahora ipfs",linkToFile);
      }
      if ((!!clavePublicaUsuario) && (!!clavePublicaUsuarioFrom) && (!!to) && (!!content) && (!!subject) && (!!currentAccount)) {
        if (linkToFile !== "") {
          encryptMessage(linkToFile);
          event.preventDefault();
          event.stopPropagation();
        } else {
          encryptMessage("");
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        alert("Falta setear algun parametro")
        console.log("Falta setear algun parametro")
      }
    } catch (e) {
      console.log(e)
    }
  };


  async function getPublicKeys() {
    try {
      const response = await Moralis.Cloud.run("GetContacts", { wallet: currentAccount });
      setContactos(response);
      setClavePublicaUsuarioFrom(user.attributes.publicKey)
    } catch (error) {
      console.error(error)
    }
  }


  //Funcion de la firma
  async function firma(_mensaje, _asunto, _link, _from, _to, _date) {
    const signer = ethersProvider.getSigner();

    let message = ethers.utils.solidityKeccak256(
      ['string', 'string', 'string', 'address', 'address', 'string'],
      [_mensaje, _asunto, _link, _from, _to, _date],
    )

    let arrayifyMessage = ethers.utils.arrayify(message)

    try {
      const response = await signer.signMessage(arrayifyMessage)

      return response
    } catch (error) {
      console.error(error)
    }
  }

  //Funcion de la firma
  async function chequeoFirma(_mensaje, _asunto, _link, _from, _to, _date, _signature) {
    let message = ethers.utils.solidityKeccak256(
      ['string', 'string', 'string', 'address', 'address', 'string'],
      [_mensaje, _asunto, _link, _from, _to, _date],
    )

    let arrayifyMessage = ethers.utils.arrayify(message)

    try {
      const response = ethers.utils.recoverAddress(ethers.utils.hashMessage(arrayifyMessage), _signature)

      return response
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    getPublicKeys()
  }, [currentAccount])


  const handlePublicKey = (item) => {
    setClavePublicaUsuario(item.user.attributes.publicKey)
    setTo(item.user.attributes.ethAddress)
  }

  // Escucha los cambios de los archivos
  const onChangeFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <>
      {openProcessingModal.open && <Modales _function={'processing'} _title={' '} _icon={'no'} _description={'Message being sent. Please wait.'} />}
      {openMessageSent.open && <Modales _function={'sent_encrypted_message'} _title={'Success'} _icon={'check'} />}
      {openErrorMessage.open && <Modales _function={'encrypted_message_error'} _title={'Error sending the message'} _icon={'error'} />}
      <Navigation pageWrapId={"page-wrap"} outerContainerId={"Home"} />
      <GoBack value="Encrypted Email" link="/email" />
      <Container
        className="centrar-componente"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <Stack style={{ textAlign: "center" }} gap={2}>
          <h3 className="title-with-gradient-underline my-4">
            Se<span>nd an encrypted messa</span>ge
          </h3>
          <Form
            // className="max-width-form form-send"
            noValidate
            id="SendForm"
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-2">
              <Row>
                <label htmlFor="fileInput" className="custom-file-upload d-flex justify-content-end align-items-center">
                  {/* {(file?.type === 'image/jpeg') || (file?.type === 'image/gif') || (file?.type === 'image/png') || (file?.type === 'image/svg+xml')
                    ? <img src={fileURL} width="auto" height="auto" alt="imagen" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                    : null
                  }
                  {file?.type === 'video/mp4'
                    ? <ReactPlayer
                      height='150px'
                      width='150px'
                      url={fileURL}
                      controls
                    />
                    : null
                  } */}
                  {/* {file?.type === 'application/pdf'
                    ? <object data={fileURL} aria-label="PDF attached" type="application/pdf" width="150px" height="150px" />
                    : null
                  } */}
                  <input className="d-none" type="file" accept="*" name="fileInput" id="fileInput" onChange={(e) => onChangeFile(e)} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <p className="attach-file" style={{ fontWeight: '600' }}><><AttachFileIcon sx={{ color: "#7963E0" }} /></>&nbsp;&nbsp;&nbsp;SEND&nbsp;&nbsp;&nbsp;<><SendOutlinedIcon /></></p>
                </label>
                {file
                  ? <p style={{ fontWeight: '400', textAlign: 'end', fontSize: '14px', fontStyle: 'italic' }}>{file?.name}</p>
                  : null
                }
              </Row>
              {/* FORM */}
              <Row>
                <Col className="mb-2 max-button-col">
                  <CssTextField className='send-msg-textField' variant="standard" value={currentAccount.slice(0, 10) + '.....' + currentAccount.slice(32)} focused InputProps={{
                    startAdornment: <InputAdornment position="start">From: &nbsp;&nbsp;</InputAdornment>,
                    autoComplete: 'off'
                  }} />

                  <CssTextField className='send-msg-textField' variant="standard" focused defaultValue="" select InputProps={{
                    startAdornment: <InputAdornment position="start">To: &nbsp;&nbsp;&nbsp;&nbsp;</InputAdornment>,
                    autoComplete: 'off',
                    style: { textAlign: 'start' }
                  }}>
                    {contactos?.map((item, index) => (

                      <MenuItem key={item.user.id} value={item.user.id} onClick={() => handlePublicKey(item)} className='menu-email'>
                        {item.contactName}
                      </MenuItem>
                    ))}
                  </CssTextField>


                  <CssTextField className='send-msg-textField' variant="standard" focused onChange={(e) => setSubject(e.target.value)} InputProps={{
                    startAdornment: <InputAdornment position="start">Subject: &nbsp;&nbsp;</InputAdornment>,
                    autoComplete: 'off'
                  }} />


                  <CssTextField className='send-msg-textField' variant="standard" focused multiline onChange={(e) => setContent(e.target.value)} rows={4} InputProps={{
                    startAdornment: <InputAdornment position="start">Write the text &nbsp;&nbsp;</InputAdornment>,
                    autoComplete: 'off'
                  }} />
                </Col>
              </Row>

              <Row className='d-flex justify-content-start'>
                <Button variant="contained" color="secondary" sx={{ borderRadius: '100rem', fontWeight: '700', lineHeight: '2', letterSpacing: '2px' }}
                  className={!((!!clavePublicaUsuario) && (!!clavePublicaUsuarioFrom) && (!!to) && (!!content) && (!!subject) && (!!currentAccount)) ? "w-auto px-5 py-2 ms-4" : "send-button w-auto px-5 py-2 ms-4"} onClick={handleSubmit}
                  disabled={!((!!clavePublicaUsuario) && (!!clavePublicaUsuarioFrom) && (!!to) && (!!content) && (!!subject) && (!!currentAccount))}>
                  Send
                </Button>
              </Row>

            </Form.Group>
          </Form>

        </Stack>

      </Container>
      <FooterOpciones />
      <FooterDesktopCompleto />
    </>
  );
};

export default SendMessage;




