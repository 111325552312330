import React, { useContext } from "react";
import './Receive.css'
import { Container, Stack } from 'react-bootstrap'
import Navigation from '../navigation/Navigation';
import GoBack from '../goBack/GoBack';
import { UserContext } from "../../context/userContext";
//import QRCode from "react-qr-code";
import FooterOpciones from "../footer-completo/footer-opciones";
import { QRCode } from 'react-qrcode-logo';
import Logo from '../../assets/icons/512x512.png'
import { CompartirAddresshatsapp } from "../../utils/Share-social"
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";
import BalanceHeader from "../balance-header/balance-header";

const Receive = () => {

  const { currentAccount } = useContext(UserContext);

  return (
    <>
      <div className='min-height-margin'>
        <Navigation />
        <BalanceHeader />
        <GoBack value="Receive" link='/home' />
        <Container className="receive in-the-middle">
          <Stack>
            <h3 style={{ margin: '0px', marginTop: '70px' }} className='title-with-gradient-underline'>M<span>y Addres</span>s</h3>
            <div className="qr-code my-5">
              <QRCode value={currentAccount} size='220' eyeRadius={1} logoImage={Logo} />
            </div>
          </Stack>
          <Stack className='address'>
            <div style={{ margin: '15px 0' }}>
              <span onClick={() => navigator.clipboard.writeText([currentAccount])}>{currentAccount}</span>
              <i onClick={() => navigator.clipboard.writeText([currentAccount])} className="fa-regular fa-copy" ></i>
              &nbsp;&nbsp;
              <CompartirAddresshatsapp _url={`${currentAccount}  https://pieceofcakewallet.com/address/${currentAccount}`} />
            </div>
          </Stack>
        </Container>
      </div>
      <FooterOpciones />
      <FooterDesktopCompleto />
    </>
  )
}

export default Receive