/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import GoBack from '../goBack/GoBack'
import Navigation from '../navigation/Navigation'
import './EmailOpen.css'
import { Row, Col, Stack } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import GridLoader from "react-spinners/GridLoader";
import FooterOpciones from '../footer-completo/footer-opciones';
import FooterDesktopCompleto from '../footer-desktop-completo/footer-desktop-completo';
import Avatar from '@mui/material/Avatar';
import { ethers } from 'ethers';
import ReactPlayer from 'react-player';
//import { decrypt as decryptSimetric } from "symmetric-encrypt";
import CryptoJS from "crypto-js";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const EmailOpenSalida = () => {

  let { indice } = useParams();
  const { messagesSent, decryptMessage, messagesSentV3DesencriptadoParcial, getMensajeDeEtiqueta } = useContext(MessageContext);
  const [message, setMessage] = React.useState({});
  const [loadingState, setLoadingState] = useState(true);
  const [video, setVideo] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [pdf, setPdf] = useState(false);
  const [noHayArchAdjunto, setNoHayArchAdjunto] = useState(false);

  /*useEffect(() => {
    if (messagesSent) setMessageInfo(messagesSent[indice])
  }, [messagesSent]);*/

  useEffect(() => {
    if (messagesSentV3DesencriptadoParcial) setMessageInfoV3(messagesSentV3DesencriptadoParcial[indice])
  }, [messagesSentV3DesencriptadoParcial]);

  /*const setMessageInfo = async (_message) => {
    try {

      const messageDecrypted = decryptMessage(_message.message);
      const linkDecrypted = decryptMessage(_message.link);
      const signatureDecrypted = decryptMessage(_message.signature);

      const _firma = await chequeoFirma(messageDecrypted, _message.subject, linkDecrypted, (_message.from).toLowerCase(), (_message.toAddress).toLowerCase(), parseInt(_message.date), signatureDecrypted)

      isVideo(linkDecrypted)

      setMessage({
        from: _message.from,
        toAddress: _message.toAddress,
        to: _message.to,
        subject: _message.subject,
        message: messageDecrypted,
        date: _message.date,
        link: linkDecrypted,
        signature: signatureDecrypted,
        addressFirma: _firma
      });

      setLoadingState(false)
    } catch (error) {
      console.error(error);
    }
  };*/

  const setMessageInfoV3 = async (_etiqueta) => {
    try {

      const mensaje = await getMensajeDeEtiqueta(_etiqueta.id);
      //console.log(mensaje)

      //La etiqueta ya viene ocn la clave simetrica desencriptada
      var bytes = CryptoJS.AES.decrypt(mensaje[1], _etiqueta.simetricKey)
      const messageDecryptedV2 = bytes.toString(CryptoJS.enc.Utf8);
      //console.log("body desencriptado", bodyDesencriptado)

      //const linkDecryptedV2 = await decryptSimetric(_message.simetricKey, JSON.parse(_message.link));
      bytes = CryptoJS.AES.decrypt(mensaje[2], _etiqueta.simetricKey)
      const linkDecryptedV2 = bytes.toString(CryptoJS.enc.Utf8);

      //const signatureDecryptedV2 = await decryptSimetric(_message.simetricKey, JSON.parse(_message.signature));
      bytes = CryptoJS.AES.decrypt(mensaje[3], _etiqueta.simetricKey)
      const signatureDecryptedV2 = bytes.toString(CryptoJS.enc.Utf8);

      const _firma = await chequeoFirma(messageDecryptedV2, _etiqueta.subject, linkDecryptedV2, (_etiqueta.from).toLowerCase(),
        (_etiqueta.toAddress).toLowerCase(), parseInt(_etiqueta.date), signatureDecryptedV2)

      await isVideo(linkDecryptedV2)

      setMessage({
        from: _etiqueta.from,
        toAddress: _etiqueta.toAddress,
        to: _etiqueta.to,
        subject: _etiqueta.subject,
        message: messageDecryptedV2,
        date: _etiqueta.date,
        link: linkDecryptedV2,
        signature: signatureDecryptedV2,
        addressFirma: _firma,
      });

      if (linkDecryptedV2 === "") {
        setNoHayArchAdjunto(true)
      }

      /* console.log("Se aplico el formato a video final", video);
      console.log("Se aplico el formato a pdf final", pdf);
      console.log("Se aplico el formato no hay arch final", noHayArchAdjunto); */
      setLoadingState(false)
    } catch (error) {
      console.error(error);
    }
  };

  //Funcion de la firma
  async function chequeoFirma(_mensaje, _asunto, _link, _from, _to, _date, _signature) {
    let message = ethers.utils.solidityKeccak256(
      ['string', 'string', 'string', 'address', 'address', 'string'],
      [_mensaje, _asunto, _link, _from, _to, _date],
    )

    //console.log('hash del mensaje', message)

    let arrayifyMessage = ethers.utils.arrayify(message)

    //console.log('arrayfy del mensaje',arrayifyMessage)

    try {
      const response = ethers.utils.recoverAddress(ethers.utils.hashMessage(arrayifyMessage), _signature)
      //console.log('address del firmante del mensaje', response)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const isVideo = async (_img) => {
    var req = await fetch(_img, { method: 'HEAD' });

    var parse = req.headers.get('content-type').split("/")

    //console.log("Formato", parse[0]);

    console.log("El formato completo es:", req.headers.get('content-type'));

    if (parse[0] == 'video') {
      //console.log("Entro en 1");
      setVideo(true);
      setPdf(false)
      /* console.log("Se aplico el formato a video", video);
      console.log("Se aplico el formato a pdf", pdf); */
    } else if (parse[0] == 'application') {
      //console.log("Entro en 2");
      setVideo(false)
      setPdf(true)
      /* console.log("Se aplico el formato a video", video);
      console.log("Se aplico el formato a pdf", pdf); */
    } else {
      //console.log("Entro en 3");
      setVideo(false)
      setPdf(false)
      /* console.log("Se aplico el formato a video", video);
      console.log("Se aplico el formato a pdf", pdf); */
    }

    setInitialLoading(false)
  };

  function stringAvatar(name) {
    if (ethers.utils.isAddress(name)) return `src="/broken-image.jpg"`
    let displayName;
    if (name.includes(' ')) {
      displayName = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    } else {
      displayName = `${name.slice(0, 2).toUpperCase()}`
    }
    return {
      sx: {
        bgcolor: '#7a67db',
      },
      children: displayName,
    };
  };

  React.useEffect(() => {
    console.log('asi esta el mensaje: ', message)
  }, [message])


  if (loadingState || !message) {
    return <GridLoader color='#AE74FF' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
  } else {
    return (
      <>
        <div className="min-height-margin">
          <Navigation />
          <GoBack value="Message" link="/email" />

          <div className="container-email">
            <Row className="email-transaccion-2">
              <Col xs={2} md={2} lg={1} className="d-flex justify-content-end">
                <Avatar {...stringAvatar(message.to)} />
              </Col>
              <Col xs={6} md={6} lg={8}>
                <Stack>
                  <label className='sender-email-window' style={{ wordBreak: 'break-all' }}>{message.to}</label>
                  <label className='subject-email-window' style={{ wordBreak: 'break-all' }}>{message.toAddress.slice(0, 5)}...{message.toAddress.slice(37)}
                    <ContentCopyIcon onClick={() => navigator.clipboard.writeText(message.toAddress)} style={{ width: '15px', marginLeft: '0.5rem', color: '#BB6DF3' }} />
                  </label>
                </Stack>

              </Col>
              <Col xs={3} md={3} lg={2}>
                <Stack>
                  <label style={{ fontSize: '14px', textAlign: 'end' }}>{new Date(parseInt((message.date))).getHours()}:{new Date(parseInt((message.date))).getMinutes() > 9 ? new Date(parseInt((message.date))).getMinutes() : '0' + new Date(parseInt((message.date))).getMinutes()}</label>
                  <label style={{ fontSize: '14px', textAlign: 'end', overflowX: 'hidden' }}>{new Date(parseInt((message.date))).getDate()}/{new Date(parseInt((message.date))).getMonth() + 1}/{new Date(parseInt((message.date))).getFullYear()}</label>
                </Stack>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <DeleteIcon style={{ border: '1px solid white', borderRadius: '50px', fontSize: '24px' }} />
              </Col>
            </Row>

            <div style={{ borderBottom: '0.1px solid #7a67db' }} />
            <div className='my-4 mx-2'>
              <h3 style={{ fontWeight: 'bold', fontSize: '24px' }}>{message.subject}</h3>
              <p style={{ fontSize: '14px', fontWeight: '600', wordBreak: 'break-all' }}><label style={{ color: '#9888E0' }}>Sign by:</label>&nbsp;{message.addressFirma}</p>
              <p style={{ color: '#B5B5B5', fontSize: '14px', fontWeight: 'bold', wordBreak: 'break-word' }}>{message.message}</p>
            </div>

            <div>
              {(!initialLoading) ?
                <>
                  <div className="img-container-email">
                    {video ?
                      <ReactPlayer
                        height='300px'
                        width='420px'
                        url={message.link}
                        loop
                        playing
                        controls
                      />
                      :
                      pdf ?
                        <div className='w-100 my-3 text-center'>
                          <p>
                            <a className="link-archive" href={message.link} target="_blank" rel="noreferrer">
                              <h4 style={{fontSize: '1.2rem', textAlign: 'center'}}>Click to Download</h4>
                              <div style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center', border: '2px solid #770c95', borderRadius: '1rem', height: '5rem', width: '5rem'}}>
                                <ArrowDownwardIcon style={{fontSize: '3rem'}} />
                              </div>
                            </a>
                          </p>
                        </div>
                        :
                        noHayArchAdjunto ?
                          <></>
                          :
                          <img src={message.link} id='imagePrev' className='nftImage' alt='Img attached' />
                    }
                  </div>
                </>
                :
                <>
                  <GridLoader color='#AE74FF' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />
                </>
              }
              {message.link ?
                <>
                  <div className='w-100 my-3 text-center'>
                    <p><a className="link-archive" href={message.link} target="_blank" rel="noreferrer">Click here if you can't see the file or if you want to see the file in a new tab</a></p>
                  </div>
                </>
                : null}
            </div>

          </div>
          <FooterOpciones />
          <FooterDesktopCompleto />
        </div>
      </>
    )
  }
}

export default EmailOpenSalida