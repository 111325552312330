import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Container, Col, Row, Stack, Button} from 'react-bootstrap'
import Checkbox from '@mui/material/Checkbox';
import {GiCheckMark} from 'react-icons/gi'
import Torta from '../../assets/TortaSola.png'
import Logo from '../../assets/PoC.png'
import Big from '../../assets/landing/landingHome.png'
import './FirstTime.css'

const FirstTime = () => {
    const [checked, setChecked] = React.useState(false);
    let navigate = useNavigate();

    const handleChange = (event) => {
        setChecked(event.target.checked);
      };

    const handleClick = () =>{
        navigate('/home')
    }

  return (
   <>
    <Container>
        <Row  className='row-header-landing'>
            <Col xs={3} md={4} style={{padding: '0px'}} className='col-torta'>
                <img src={Torta} alt='' className='torta-first'/>
            </Col>
            <Col xs={9} md={8} className='col-logo'>
                <img src={Logo} alt='' className='logo-first'/>
            </Col>
        </Row>
        <Row>
            <Col>
            <Stack>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem'}}>
                    <img src={Big} alt='' className='landing-img'/>
                </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: '1rem'}}>
                <ul className='list-ul'>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;View NFT</li>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;Create NFT</li>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;Send NFT</li>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;Share NFT with friends</li>
                </ul>
            </div>
              </Stack>
            </Col>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
                &nbsp;&nbsp;<span>I have read and agree the <a href='/termsandconditions' style={{color: 'blue'}}>Terms and Conditions</a> and the <a href='/privacypolicy'  style={{color: 'blue'}}>Privacy Policy</a></span>
            </div>
            <div style={{width: '80%', display: 'flex', justifyContent: 'center'}}>
                <Button className='accept-terms-button' disabled={!checked} style={{color: 'white', fontWeight: 'bold', marginTop: '1rem', width: '80%', marginBottom: '2rem'}} onClick={handleClick}>
                    accept
                </Button>
            </div>
        </Row>
    </Container>
   </>
  )
}

export default FirstTime