import * as React from "react";
import { ethers } from "ethers";
import "./BandejaDeEntrada.css";
import { Avatar, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputGroup from "react-bootstrap/InputGroup";
import { Container, Row, Col, Stack, Form } from "react-bootstrap";
import { MessageContext } from "../../context/messageContext";
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';

const BandejaDeSalida = ({ messagesSent }) => {

  const { pageSent, setPageSent, messagesSentLenght, limit } = React.useContext(MessageContext);

  const handlePaginationChange = (event, value) => {
    setPageSent(value);
  };

  let navigate = useNavigate();

  function handleClick(index) {
    navigate(`/message-sent/${index}`);
  };

  function stringAvatar(name) {
    if (ethers.utils.isAddress(name)) return `src="/broken-image.jpg"`
    let displayName;
    if (name.includes(' ')) {
      displayName = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    } else {
      displayName = `${name.slice(0, 2).toUpperCase()}`
    }
    return {
      sx: {
        bgcolor: '#7a67db',
      },
      children: displayName,
    };
  };

  return (
    <>
      <div className="min-height-margin">
        <Stack className="transacciones">
          <div className="container d-xxl-none d-xl-none d-lg-none">
            {/* <EmailNavigation /> */}
            <InputGroup className="mb-3 mt-2">
              <Form.Control type="text" placeholder="Search Messages" className="search-email" />
            </InputGroup>
          </div>

          <div>
            <Container>
              {messagesSent?.map((message, index) => (
                <Row className="transaccion email-transaccion" key={index}>
                  <Col xs={2} lg={1} className="justify-content-center">
                    <Avatar {...stringAvatar(message.to)} />
                  </Col>
                  <Col xs={7} lg={9} style={{ display: "flex", alignItems: "center" }}>
                    <Stack>
                      <label className='sender-email' style={{ wordBreak: 'break-all' }}>To: {message.to}</label>
                      <label className='subject-email' onClick={() => handleClick(index)}>Asunto: {message.subject}</label>
                      <label className='msg-email' style={{ wordBreak: 'break-all' }} onClick={() => handleClick(index)}>Message: ***************************</label>
                      <label className='msg-email-decript' style={{ wordBreak: 'break-all' }} onClick={() => handleClick(index)}>Click Here to decrypt message</label>
                    </Stack>
                  </Col>
                  <Col xs={3} lg={2}>
                    <Stack style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <label style={{ fontSize: '10px' }}>{new Date(parseInt((message.date))).getDate() > 9 ? new Date(parseInt((message.date))).getDate() : '0' + new Date(parseInt((message.date))).getDate()}/{new Date(parseInt((message.date))).getMonth() + 1}/{new Date(parseInt((message.date))).getFullYear()}</label>
                      <label style={{ fontSize: '10px' }}>{new Date(parseInt((message.date))).getHours()}:{new Date(parseInt((message.date))).getMinutes() > 9 ? new Date(parseInt((message.date))).getMinutes() : '0' + new Date(parseInt((message.date))).getMinutes()}</label>
                    </Stack>
                  </Col>
                </Row>
              ))}
              <Row className='d-flex justify-content-center my-3'>
                <Pagination count={Math.max(Math.ceil(messagesSentLenght / limit), 1)}
                    page={Number(pageSent)} onChange={handlePaginationChange} color="secondary" />
              </Row>
              <Row className='d-flex justify-content-center my-3'>
                <Link to='/send-message' style={{textAlign: 'right'}}>
                  <Fab variant="extended" style={{backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c)', fontWeight: '850', zIndex: '0'}} /*className='btn-write'*/>
                    <EditIcon sx={{ mr: 1 }}/>
                      Create
                  </Fab>
                </Link>
              </Row>
            </Container>
          </div>
        </Stack>

      </div>
    </>
  );
};

export default BandejaDeSalida;
