import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import "./SendSelect.css";
import { Container, Col, Row } from "react-bootstrap";

import NFTBig from "../../assets/NFT-Big.png";
import MaticBig from "../../assets/MaticBig.png";
import FooterOpciones from "../footer-completo/footer-opciones";
import BalanceHeader from "../balance-header/balance-header";
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";

const SendSelect = () => {

  return (
    <>
      <div className='min-height-margin'>
        <Navigation pageWrapId={"page-wrap"} outerContainerId={"Home"} />

        <BalanceHeader />

        <GoBack value="Send" link="/home" />

        <Container
          className="d-flex flex-column align-items-center justify-content-evenly"
          style={{ minHeight: "calc(50vh - 0px)" }}
        >
          <h4 className="title-with-gradient-underline-send">
            Selec<span>t the token type t</span>o send
          </h4>
          <Row>
            <Col lg="6">
              <Link to="/my-nfts/send">
                <div className="Box-send">
                  <img src={NFTBig} alt="Nfts" width="50%" />
                </div>
              </Link>
            </Col>
            <Col lg="6">
              <Link to="/send-token">
                <div className="Box-send">
                  <img
                    src={MaticBig}
                    alt="Nfts"
                    width="50%"
                    style={{ marginBottom: "1rem" }}
                  />
                  <label>MATIC</label>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterOpciones />
      <FooterDesktopCompleto />
    </>
  );
};

export default SendSelect;
