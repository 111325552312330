
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { Link, useParams } from 'react-router-dom'
import { ethers } from 'ethers';
import Modales from "../../utils/modal/modal";
import { Form, Button } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import Scanner from "../../components/scanner/scanner";
import { useState, useMemo } from "react";
import SendButton from "../stepsModal/sendButton";

const TransferNFT = ({ _nft, receiver, setReceiver }) => {
  const indexParams = useParams();
  const index = indexParams["*"]
  const { currentAccount, modal, loader, isVisible, setIsVisible, sendAddress, refreshInput, setRefreshInput, errorFunds } = useContext(UserContext);


  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [modalIsShownEmail, setModalIsShownEmail] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nftType, setNftType] = useState("");
  const [nftAmount, setNftAmount] = useState("");
  const [isValid, setIsValid] = useState(false)
  const [hideQr, setHideQr] = useState(true)

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  
  const checkType = () => {
    const nft_type = _nft.contract_type;
    setNftType(nft_type);
  }

  const esEntero = (numero) => {
    if (numero % 1 == 0) {
        return true
    } else {
        return false
    }
  }
  
  const checkAmount = () => {
    const nft_amount = _nft.amount;
    setNftAmount(nft_amount);
  }

  // comunication with modal submit
  const modalToParent = () => {
    setModalIsShown(false);
  };



  const camaraToAddContact = (dataReceiveFromParent) => {
    setReceiver(dataReceiveFromParent);
    document.getElementById('addressTransferNft').value = dataReceiveFromParent;
    
  };

  useEffect(() => {
    if(receiver !== ''){
      setHideQr(true)
    }
  }, [receiver])


  useEffect(()=>{
    if(refreshInput){
      document.getElementById('emailTransferNft').value = "";
      document.getElementById('amountTransferNft').value = "";
      setRefreshInput(false)
    }
  }, [refreshInput])

   useEffect(() => {
     const fields = index.split('/');
     const url = fields[0] + '/' + fields[1]
    if (url === 'my-nfts/send') {
        // alert("oka")
        submitNFT(false);
    }
  }, [index, isVisible, nftType])
    
    // form submit 
    const handleSubmit = (event) => {
      
      event.preventDefault();
      const form = event.currentTarget;
      console.log(form)
      if (form.checkValidity() && ethers.utils.isAddress(receiver)) {
        if(nftType === "ERC1155" && (parseInt(amount) > parseInt(nftAmount))){
          return
        }
        submitNFT(true);
      }
      else if(form.checkValidity() && email !== ''){
        setModalIsShownEmail(true)
        //handleSend(_nft)
        console.log(email)
        submitNFTEmail()
      }
      event.stopPropagation();
      setFormSubmitted(true);
    };
    
    useEffect(()=>{
      if(receiver === '' && sendAddress === ''){
        setIsVisible(false);
      }
    }, [])
    
    useEffect(()=>{
      if(modalIsShownEmail){
        setModalIsShownEmail(false);
      }
    }, [email, amount])


    const submitNFT = (state) => {
      if (!isVisible) {
        setIsVisible(true)
        setIsShown(false)
      }
      if (isVisible) {
        console.log("NFT", _nft.token_address, Number(_nft.token_id), currentAccount, receiver);
        console.log(modal);
      setModalIsShown(state);
    }
  }

  
  const submitNFTEmail = () => {
    
    if (!isVisible) {
      setIsVisible(true)
      setIsShown(false)
      setModalIsShownEmail(false)
    }
    if (isVisible) {
      console.log("NFT", _nft.token_address, Number(_nft.token_id), currentAccount, email);
      setModalIsShownEmail(true);
    }
  }
  
  useEffect(() => {
    checkType()
    checkAmount()
  }, []);
  
  useEffect(() => {
    if(receiver !== '' || sendAddress !== '') {
      setIsShown(false)
      //setIsVisible(true)
    }
  }, [receiver]);

  const validate = () => {
    if(nftType ==="ERC721"){
      return (ethers.utils.isAddress(receiver)) || (isValidEmail(email) );
    } else{
      return (ethers.utils.isAddress(receiver) && parseInt(amount) > 0 && parseInt(amount) <= parseInt(nftAmount) && esEntero(parseFloat(amount))) || (isValidEmail(email) && parseInt(amount) > 0 && parseInt(amount) <= parseInt(nftAmount) && esEntero(parseFloat(amount)));
    }
  }


  useEffect(() => {
    const isValid = validate();
    setIsValid(isValid);
    esEntero(parseInt(amount))
    /*console.log(esEntero(2))
    console.log(esEntero(2.3))*/
  }, [receiver, email, amount]);
  
  return (
    
    <div className="transferirNft">
      {/* {error && <ErrorMessage error={error} />} */}
      {!hideQr ? <Scanner scannerToParent={camaraToAddContact} /> : null}
      {/*Function para transferir 721 */}
      {isVisible && nftType ==="ERC721" && <Form className="max-width-form" noValidate id="transferNftForm" autoComplete="off" onSubmit={handleSubmit}>
        <Form.Group className="mb-2 mt-2">
          <InputGroup hasValidation className="mb-4 mt-3">
            <Form.Control
              type="text"
              placeholder="Address"
              id="addressTransferNft"
              value={receiver}
              disabled={email !== '' ? true : false}
              onChange={(e) => {
                setReceiver(e.target.value);
                console.log("Receiver: " + receiver);
              }}
              isInvalid={!ethers.utils.isAddress(receiver) && (receiver.length || formSubmitted) && email === ''}
              isValid={ethers.utils.isAddress(receiver)}
            />
            
            
              <InputGroup.Text id="basic-addon1" onClick={()=>setHideQr(!hideQr)}><i className="fa-solid fa-camera"></i></InputGroup.Text>
           
            <Form.Control.Feedback type="invalid">Invalid Address</Form.Control.Feedback>
          </InputGroup>
          <InputGroup hasValidation className="mb-4 mt-3">
            <Form.Control
                type="text"
                placeholder="Mail"
                disabled={receiver !== '' ? true : false}
                id="emailTransferNft"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                isInvalid={email.length > 0 && isValidEmail(email) === false}
                isValid={isValidEmail(email) === true}
              
              />
            <Form.Control.Feedback type="invalid">Invalid Email</Form.Control.Feedback>
          </InputGroup>
          {isShown  && (
            <Scanner scannerToParent={camaraToAddContact} />
          )}
          <Button className="max-width-form w-100 mt-3 send-button" disabled={!isValid} type="submit">Send</Button>
          {(errorFunds) &&
                <Modales 
                  modalToParent={modalToParent}
                  _icon={'check'}
                  _title={"Transaction cancelled"}
                  _text={'Insufficient funds.'}
                  /* _text={"There was an error sending your Matic, please check if you have enought balance for this transaction."} */
                ></Modales>
              }

          {(modalIsShown) && <Modales modalToParent={modalToParent}
            _from={currentAccount}
            _to={receiver} 
            _token_id={_nft.token_id}
            _token_address={_nft.token_address}
            _function={"transfer_nft_721"}
            _name={JSON.parse(_nft.metadata).name} 
            _icon={"no"}
            _title={"Please Confirm Transaction"}
          ></Modales>}

          {(modalIsShownEmail) && 
            <SendButton modalToParent={modalToParent}
            _amount={amount}
            _from={currentAccount}
            _to={receiver}
            _emailTo={email}
            _tokenId={_nft.token_id}
            _contractAddress={_nft.token_address}
            _function={"transfer_nft_1155"}
            _nft={_nft}
            _currentUser={currentAccount}
            />
          } 

          {(modal === true) &&
            <Modales modalToParent={modalToParent}
              _text={"¡NFT TRANSFERIDO! \n" +
                "Address: " + receiver}
              _icon={true}
              _function=""
            ></Modales>
          }
        </Form.Group>
        </Form>}


        {/* TRANSFERIR 1155 */}


        {isVisible && nftType ==="ERC1155" && <Form className="max-width-form" noValidate id="transferNftForm" onSubmit={handleSubmit}>
        <Form.Group className="mb-2 mt-2">
        <InputGroup hasValidation className="mb-4 mt-3">
            <Form.Control
              type="text"
              placeholder="Address"
              id="addressTransferNft"
              disabled={email !== '' ? true : false}
              value={receiver}
              onChange={(e) => {
                setReceiver(e.target.value);
                console.log("Receiver: " + e.target.value);
              }}
              isInvalid={!ethers.utils.isAddress(receiver) && (receiver.length || formSubmitted) && email === ''}
              isValid={ethers.utils.isAddress(receiver)}
            />
            
            
            <InputGroup.Text id="basic-addon1" onClick={()=>setHideQr(!hideQr)}><i className="fa-solid fa-camera"></i></InputGroup.Text>
          
            <Form.Control.Feedback type="invalid">Invalid Address</Form.Control.Feedback>
          </InputGroup>
          <InputGroup hasValidation className="mb-4 mt-3">
            <Form.Control
                type="text"
                
                placeholder="Mail"
                disabled={receiver !== '' ? true : false}
                id="emailTransferNft"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                isInvalid={email.length > 0 && isValidEmail(email) === false}
                isValid={isValidEmail(email) === true}
              />

            <Form.Control.Feedback type="invalid">Invalid Email</Form.Control.Feedback>
          </InputGroup>
          {(isShown) && (
            <Scanner scannerToParent={camaraToAddContact} />
          )}

          <Form.Control
              type="number"
              placeholder="Amount"
              required
              id="amountTransferNft"
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              isInvalid={((parseInt(amount) <= 0 || (parseInt(amount) > parseInt(nftAmount)) || !esEntero(parseFloat(amount)))) && amount !== ""}
            />
          
          <Button className="max-width-form w-100 mt-3 send-button" disabled={!isValid} type="submit">Send</Button>
          {(errorFunds) &&
                <Modales 
                  modalToParent={modalToParent}
                  _icon = "check"
                  _title={"Transaction cancelled"}
                  _text={'Insufficient funds.'}
                  /* _text={"There was an error sending your Matic, please check if you have enought balance for this transaction."} */
                ></Modales>
              }
          
          {(modalIsShown) && <Modales modalToParent={modalToParent}
            _amount={amount}
            _from={currentAccount}
            _to={receiver}
            _emailTo={email}
            _name={JSON.parse(_nft.metadata).name} 
            _token_id={_nft.token_id}
            _token_address={_nft.token_address}
            _function={"transfer_nft_1155"}
            _icon={"no"}
            _title={"Please Confirm Transaction"}
          ></Modales>}
          {/* @facu */}

          {(modalIsShownEmail) && 
            <SendButton modalToParent={modalToParent}
            _amount={amount}
            _from={currentAccount}
            _to={receiver}
            _emailTo={email}
            _tokenId={_nft.token_id}
            _contractAddress={_nft.token_address}
            _function={"transfer_nft_1155"}
            _nft={_nft}
            _currentUser={currentAccount}
            />
          }


          {(modal === true) &&
            <Modales modalToParent={modalToParent}
              _text={"¡NFT TRANSFERIDO! \n" +
                "Address: " + receiver}
              _icon={true}
              _function=""
            ></Modales>
          }
          
        </Form.Group>
        </Form>}
      {!isVisible && <Button
        variant="outline-light"
        className="mt-3 mb-2 send-nft"
 			onClick={() => submitNFT(true)}>        Transfer
      </Button>}
    </div>
  );
};

export default TransferNFT;