import React, { useContext, useEffect, useState} from "react";
import "./Balance.css";
import {  Row, Col, Stack } from "react-bootstrap";
import Matic from "../../assets/Matic.png";
import NFT from "../../assets/NFT.png";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import { UserContext } from "../../context/userContext";
import FooterOpciones from "../footer-completo/footer-opciones";
import { useMoralisQuery } from "react-moralis";
import usePagination from "../../utils/pagination/pagination"
import Pagination from '@mui/material/Pagination';
import GridLoader from "react-spinners/GridLoader";
import Moralis from 'moralis';
import { useNavigate} from 'react-router-dom';
import Button from "react-bootstrap/Button";
import BalanceHeader from "../balance-header/balance-header";
import FooterDesktopCompleto from "../footer-desktop-completo/footer-desktop-completo";
//import InputDataDecoder from "ethereum-input-data-decoder";

const BalancePositive = () => {
  const {  listOfNft, provider, balanceInMatic, transactions, currentAccount } = useContext(UserContext);
  const [loadingState, setLoadingState] = useState(true)
  const [user, setUser] = useState([])
  const [contratoDappsFactory, setContratoDappsFactory] = useState([])
  const [decodeTransactions, setDecodeTransactions] = useState([])
  const [transaccionesFiltradas, setTransaccionesFiltradas] = useState([])
  const InputDataDecoder = require('ethereum-input-data-decoder');
  const abi_1155Balance = require('../../utils/contracts/erc1155Balance.json');
  let navigate = useNavigate();
  


  const fetchTransactionsPositive = async () => {
    console.log(currentAccount)
    const user = await Moralis.Cloud.run("GetTransactions+Info", {address: currentAccount ? (currentAccount).toLowerCase() : ""})
    const _contratoDappsFactory = await Moralis.Cloud.run("GetTransactions+Info", {address: "0x8b7855f52df16aef1a5f64b7016cea7b01ce876b" ? ("0x8b7855f52df16aef1a5f64b7016cea7b01ce876b").toLowerCase() : ""})
    console.log(user)
    setUser(user)
    console.log(_contratoDappsFactory)
    setContratoDappsFactory(_contratoDappsFactory)
    const decoder = new InputDataDecoder(abi_1155Balance);
    let _decodeTransactions = [];
    for(const transaction of _contratoDappsFactory){
      const data = transaction.attributes.input
      const result = decoder.decodeData(data);
      result.block_timestamp = (transaction.attributes.block_timestamp).toString();
      _decodeTransactions.push(result)
      console.log(result);
    }
    setDecodeTransactions(_decodeTransactions)
  }

  const goBalanceNegative = () => {
    navigate('/balance');
  }
  
  const filterTransactions = (transactions) => {
    let _transaccionesFiltradas = [];
    for(let i = 0; i < transactions.length; i++) {
      console.log(transactions[i].method === 'safeTransferFrom')
      if(transactions[i].method === 'safeTransferFrom') {
        console.log(("0x" + transactions[i].inputs[1]).toString() === (currentAccount).toString())
        if(((transactions[i].inputs[1]).toString()).toLowerCase() === (((currentAccount).toString()).slice(2)).toLowerCase()){
          _transaccionesFiltradas.push(transactions[i]);
        }
      }
    }
    setTransaccionesFiltradas(_transaccionesFiltradas)
  }


  useEffect(() => {

      fetchTransactionsPositive()
      filterTransactions(decodeTransactions)
      setTimeout(() => {
        setLoadingState(false);
      }, [2000])
    //}
  }, [currentAccount])

  useEffect(() => {
    console.log(decodeTransactions)
    filterTransactions(decodeTransactions)
  }, [decodeTransactions])

  useEffect(() => {
    console.log(transaccionesFiltradas)
    //setLoadingState(false)
  }, [transaccionesFiltradas])


  
  const conversorMatic = 10**18;

  if (loadingState) return (
    <>
      <div style={{textAlign: 'center'}}>Loading</div>
      <GridLoader color='#AE74FF' style={{position: 'fixed',margin: 'auto', inset: '45%'}} />
    </>
  )
   else
  return (
    <>
      <div className='min-height-margin'>
        <Navigation />
        <GoBack value="Balance" link='/home' />
        <BalanceHeader />
        
        <Stack className="transacciones">
          <div className="container">
            <h5 style={{fontSize: '1.5rem'}}>Latest Matic Transactions</h5>
            {/*<i className="fa-solid fa-sliders"></i>*/}
            {<Button onClick={goBalanceNegative} style={{background: 'darkred'}}>See Negative Balance</Button>}
          </div>
          <div>
            {user?.map((transaction, index) => (
              <Row className="transaccion" key={index}>
                  <div className="container">
                    <Col md={6} xs={6}>
                      <h6 className="wordbreak">Matic recived from:</h6>
                      <h6 className="wordbreak">{((transaction.attributes?.from_address).toString()).slice(0,4) + '...' + ((transaction.attributes?.from_address).toString()).slice(36)}</h6>
                      <span>{(transaction.attributes?.block_timestamp.toString()).slice(0,21)}</span>
                    </Col>
                    <span style={{color: 'lawngreen', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(transaction.attributes?.value / conversorMatic)} MATIC</span>
                  </div> 
                </Row>
            ))}
          </div>
        </Stack>
        <Stack className="transacciones">
          <div className="container">
            <h5 style={{fontSize: '1.5rem'}}>Latest NFT Transactions</h5>
            <div className="circle">
                <img src={NFT} alt="Nfts" width="30px" height="30px" />
            </div>
          </div>
          <div>
            {transaccionesFiltradas?.map((transaction, index) => (
              <Row className="transaccion" key={index}>
                  <div className="container">
                    <Col md={6} xs={6}>
                      <h6 className="wordbreak">NFT(s) recived from:</h6>
                      <h6 className="wordbreak">{'0x' + ((transaction.inputs[0])?.toString()).slice(0,2) + '...' + ((transaction.inputs[0])?.toString()).slice(34)}</h6>
                      <span>{(transaction.block_timestamp).slice(0,21)}</span>
                    </Col>
                    <span style={{color: 'lawngreen', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{parseInt(transaction.inputs[3])} NFT(s)</span>
                  </div> 
                </Row>
            ))}
          </div>
        </Stack>
        <FooterOpciones />
        <FooterDesktopCompleto />
      </div>
    </>
  );
};

export default BalancePositive;

