import React from "react";
import {FacebookShareButton, FacebookIcon, LinkedinIcon, LinkedinShareButton, TwitterShareButton, TwitterIcon, WhatsappIcon, WhatsappShareButton, TelegramIcon, TelegramShareButton, EmailIcon, EmailShareButton} from "react-share";
import Facebook from '../assets/facebook.png';
import Telegram from '../assets/telegram.png';
import Whatsapp from '../assets/whatsapp.png';
import Linkedin from '../assets/linkedin.png';
import Twitter from '../assets/twitter.png';
import Email from '../assets/mail.png';
//const url = "https://ipfs.perceptionface.com/ipfs/QmU3cYZJTMWsnQCsaH3Ct6qoB6D5Gh5bWPmsjQzCdmk4Aj";

export function SocialMediaFacebook({_url}) {
    return (
        <FacebookShareButton 
             url={_url}
             hashtag={"#PieceOfCakeWallet"}>
                <div style={{border: '2px solid #8744DE', borderRadius: '100rem' }}>
                    <img src={Facebook} alt='' style={{margin: '0.5rem', width: '21px', height: '21px'}}/>
                </div>
        </FacebookShareButton>
    );
}

export function SocialMediaLinkedin({_url}) {
    return (
        <LinkedinShareButton 
            url={_url}
            title={"See my NFT created at: "}>
            <div style={{border: '2px solid #8744DE', borderRadius: '100rem' }}>
                <img src={Linkedin} alt='' style={{margin: '0.5rem', width: '21px', height: '21px'}}/>
            </div>
        </LinkedinShareButton>
    );
}

export function SocialMediaTwitter({_url}) {
    return (
        <TwitterShareButton 
            url={_url}
            title={"See my NFT created at:  "}
            hashtags={["NFTS", "PieceOfCakeWallet"]}>
            <div style={{border: '2px solid #8744DE', borderRadius: '100rem' }}>
                <img src={Twitter} alt='' style={{margin: '0.5rem', width: '21px', height: '21px'}}/>
            </div>
        </TwitterShareButton>
    );
}

export function SocialMediaWhatsapp({_url}) {
    return (
        <WhatsappShareButton 
            url={_url}
            title={"See my NFT created at:  "}>
            <div style={{border: '2px solid #8744DE', borderRadius: '100rem' }}>
                <img src={Whatsapp} alt='' style={{margin: '0.5rem', width: '21px', height: '21px'}}/>
            </div>
        </WhatsappShareButton>
    );
}

export function SocialMediaTelegram({_url}) {
    return (
        <TelegramShareButton 
            url={_url}
            title={"See my NFT created at:  "}>
            <div style={{border: '2px solid #8744DE', borderRadius: '100rem' }}>
                <img src={Telegram} alt='' style={{margin: '0.5rem', width: '21px', height: '21px'}}/>
            </div>
        </TelegramShareButton>
    );
}

export function SocialMediaEmail({_url}) {
    return (
        <EmailShareButton 
            url={_url}
            subject={"See my NFT created at:  "}
            body={"Join us!"}>
            <div style={{border: '2px solid #8744DE', borderRadius: '100rem' }}>
                <img src={Email} alt='' style={{margin: '0.5rem', width: '21px', height: '21px'}}/>
            </div>
        </EmailShareButton>
    );
}


export function CompartirAddresshatsapp({_url}) {
    return (
        <WhatsappShareButton
            url={_url}
            title={"Here is my address: "}>
            <WhatsappIcon size={30} round={true}/>
        </WhatsappShareButton>
    );
}
