/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./userContext";
import { ethers } from "ethers";
import { contractAddressEncriptado, contractABIEncriptado } from '../utils/contracts/encryptedMessages';
import Moralis from "moralis";
import { decrypt as decryptAsymetric } from "eciesjs";
//import { decrypt as decryptSimetric } from "symmetric-encrypt";
import CryptoJS from "crypto-js";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {

  const { ethersProvider, currentAccount, getMaxGas, privateKey, getContactName } = useContext(UserContext);

  //Version 3
  //Enviados
  const [messagesSentV3, setMessagesSentV3] = React.useState([]);
  const [messagesSentV3DesencriptadoParcial, setMessagesSentV3DesencriptadoParcial] = React.useState([]);
  //const [messagesCompletosSentV2Desencriptado, setMessagesCompletosSentV2Desencriptado] = React.useState([]);
  //Recibidos
  const [messagesReceivedV3, setMessagesReceivedV3] = React.useState([]);
  const [messagesReceivedV3DesencriptadoParcial, setMessagesReceivedV3DesencriptadoParcial] = React.useState([]);
  //const [messagesCompletosReceivedV2Desencriptado, setMessagesCompletosReceivedV2Desencriptado] = React.useState([]);

  const [encriptedMessages, setEncriptedMessages] = useState();
  const [encriptedMessagesSalida, setEncriptedMessagesSalida] = useState();
  // messages
  const [messagesSent, setMessagesSent] = React.useState([]);
  const [pageSent, setPageSent] = React.useState(null);
  const [messagesSentLenght, setMessagesSentLength] = React.useState(null);

  const [messagesReceived, setMessagesReceived] = React.useState([]);
  const [pageReceived, setPageReceived] = React.useState(null);
  const [messagesReceivedLenght, setMessagesReceivedLength] = React.useState(null);

  const limit = 5;
  // modales
  const [openMessageSent, setOpenMessageSent] = useState({ open: false });
  const [openProcessingModal, setOpenProcessingModal] = useState({ open: false });
  const [openErrorMessage, setOpenErrorMessage] = useState({ open: false, data: undefined });
  // booleano de carga
  const [waitLoader, setWaitLoader] = useState(true);

  //Funcion que se utiliza para obtener los headerfront de los mensajes recibidos
  //Los mensajes se obtienen a partir de la función getMessagesOfBlockchainPaginado
  const getReceivedMessages = async (_page) => {
    const mensajitos = await getMessagesOfBlockchainPaginado(_page,5,0);
    console.log("received", mensajitos)
    setMessagesReceivedV3(mensajitos);
  };

  const getLengthReceivedMessages = async () => {
    try {
      const signer = await ethersProvider.getSigner();
      let contract = new ethers.Contract(contractAddressEncriptado, contractABIEncriptado, signer);
      const tx = await contract.getTodosMensajesEnviadosRecibidos(0);
      console.log(tx.length);
      setMessagesReceivedLength(tx.length)
    } catch (error) {
      console.log(error)
    }
  };

  const getMensajeDeEtiqueta = async (_id) => {
    try {
      const signer = await ethersProvider.getSigner();
      let contract = new ethers.Contract(contractAddressEncriptado, contractABIEncriptado, signer);
      const tx = await contract.getMensaje(_id);
      console.log(tx);
      return tx;
    } catch (error) {
      console.log(error)
    }
  };


  //Una vez cambiado el estado mediante getReceivedMessages se llama a esta función para desencriptar parcialmente los mensajes
  //Esta función se llama mediante un use efects
  const set_encryptedMessageReceivedV3 = async (_encriptedMessages) => {
    try {
      let aux = [];

      for (const headerFront of _encriptedMessages) {

        //La estructura de header front del contrato
        /* 
        struct HeaderFront{
          EtiquetaLink etiquetaEncriptada;
          string asuntoSymetric;
          string timeSymetric;
        }
        */

        const fromName = await getContactName((headerFront[0][2]).toLowerCase());

        const simetricKeyDecripted = decryptMessage(headerFront[0][0]);
        
        var bytes = CryptoJS.AES.decrypt(headerFront[1], simetricKeyDecripted)
        const asuntoDesencriptado = bytes.toString(CryptoJS.enc.Utf8);
        /*console.log("asunto Encriptado",message[3])
        console.log("Asunto bytes", bytes)
        console.log("Asunto desenc", asuntoDesencriptado)*/
        bytes = CryptoJS.AES.decrypt(headerFront[2], simetricKeyDecripted)
        const timeDesencriptado = bytes.toString(CryptoJS.enc.Utf8);
        /*console.log("Time desenc", timeDesencriptado)
        console.log("Time bytes", bytes)
        console.log("Time encriptado", message[4])*/


        const aux2 = {
          //moralisId: message.id,
          to: currentAccount,
          from: fromName !== undefined ? fromName.attributes.contactName : (headerFront[0][2]).toLowerCase(),
          fromAddress: headerFront[0][2],
          date: timeDesencriptado,
          subject: asuntoDesencriptado,
          message: "",
          open: false,
          link: "",
          signature: "",
          simetricKey: simetricKeyDecripted,
          id: parseInt(headerFront[0][1]),
        };

        aux.push(aux2)
      };
      setMessagesReceivedV3DesencriptadoParcial(aux);
    } catch (error) {
      console.error(error)
    }
  };


  //Una vez cambiado el estado mediante getSentMessages se llama a esta función para desencriptar parcialmente los mensajes
  //Esta función se llama mediante un use efects
  const set_encryptedMessageSentV3 = async (_encriptedMessagesSalida) => {
    try {
      let aux = [];
      for (const headerFront of _encriptedMessagesSalida) {

        /* 
          struct HeaderFront{
            EtiquetaLink etiquetaEncriptada;
            string asuntoSymetric;
            string timeSymetric;
          }
        */

        const fromName = await getContactName((headerFront[0][2]).toLowerCase());
        const simetricKeyDecripted = decryptMessage(headerFront[0][0]);
        //console.log("Asimectric", simetricKeyDecripted)
        /* const asuntoDesencriptado = await decryptSimetric(simetricKeyDecripted, JSON.parse(message[3][0]));
        console.log("simetric",asuntoDesencriptado)
        
        const timeDesencriptado = await decryptSimetric(simetricKeyDecripted, JSON.parse(message[3][4]));
        console.log("simetric",timeDesencriptado) */

        
        var bytes = CryptoJS.AES.decrypt(headerFront[1], simetricKeyDecripted)
        const asuntoDesencriptado = bytes.toString(CryptoJS.enc.Utf8);

        bytes = CryptoJS.AES.decrypt(headerFront[2], simetricKeyDecripted)
        const timeDesencriptado = bytes.toString(CryptoJS.enc.Utf8);



        const aux2 = {
          to: fromName !== undefined ? fromName.attributes.contactName : (headerFront[0][2]).toLowerCase(),
          toAddress: headerFront[0][2],
          from: currentAccount,
          date: timeDesencriptado,
          subject: asuntoDesencriptado,
          message: "",
          link: "",
          signature: "",
          simetricKey: simetricKeyDecripted,
          id: parseInt(headerFront[0][1]),
        };

        aux.push(aux2)
      }
      setMessagesSentV3DesencriptadoParcial(aux)
    } catch (error) {
      console.error(error)
    }
  };
  

  /*const getTodosLosSentDeLaPag = async () => {
    let aux = [];
    for (const message of messagesSentV2) {
      const mensaje = await getMensajeDeEtiqueta(message.idMensaje)
      const mensajeConcat = message.concat(mensaje)
      aux.push(mensajeConcat);
      console.log(aux)
    }
    //setMessagesCompletosSentV2(aux)
    await set_encryptedMessageSalidaV2(aux)
  }

  const getTodosLosReceivedDeLaPag = async () => {
    let aux = [];
    for (const message of messagesReceivedV2) {
      const mensaje = await getMensajeDeEtiqueta(message.idMensaje)
      const mensajeConcat = message.concat(mensaje)
      aux.push(mensajeConcat);
      console.log(aux)
    }
    //setMessagesReceivedCompletosV2(aux)
    await set_encryptedMessageV2(aux)
  }*/

  //Funcion que se utiliza para obtener los headerfront de los mensajes enviados
  //Los mensajes se obtienen a partir de la función getMessagesOfBlockchainPaginado
  const getSentMessages = async (_address, _page) => {
    const mensajitos = await getMessagesOfBlockchainPaginado(_page,5,1);
    console.log("sent", mensajitos)
    setMessagesSentV3(mensajitos);
  };

  const getLengthSentMessages = async (_address) => {
    try {
      const signer = await ethersProvider.getSigner();
      let contract = new ethers.Contract(contractAddressEncriptado, contractABIEncriptado, signer);
      const tx = await contract.getTodosMensajesEnviadosRecibidos(1);
      console.log(tx.length);
      setMessagesSentLength(tx.length)
    } catch (error) {
      console.log(error)
    }
  };

  const decryptMessage = (_mensajeEncriptado) => {
    if (privateKey !== undefined) {
      const _decryptMessage = decryptAsymetric(
        privateKey, // revisar seguridad de esto
        Buffer.from(_mensajeEncriptado.toString(), "hex")
      );
      return _decryptMessage.toString();
    } else {
      console.log("Llega mal la private key");
    }
  };

  const set_encryptedMessage = async (_encriptedMessages) => {
    try {
      let aux = [];

      for (const message of _encriptedMessages) {

        const fromName = await getContactName((message.attributes.from).toLowerCase());
        const subjectDecrypted = decryptMessage(message.attributes.subjectHash);

        const aux2 = {
          moralisId: message.id,
          to: message.attributes.to,
          from: fromName !== undefined ? fromName.attributes.contactName : message.attributes.from,
          fromAddress: message.attributes.from,
          date: message.attributes.timeHash,
          subject: subjectDecrypted,
          message: message.attributes.messageHash,
          open: message.attributes.open,
          link: message.attributes.linkHash,
          signature: message.attributes.signatureHash,
        };

        aux.push(aux2)
      };
      setMessagesReceived(aux);
    } catch (error) {
      console.error(error)
    }
  };

  const set_encryptedMessageSalida = async (_encriptedMessagesSalida) => {
    try {
      let aux = [];
      for (const message of _encriptedMessagesSalida) {

        const fromName = await getContactName((message.attributes.to).toLowerCase());
        const subjectDecrypted = decryptMessage(message.attributes.subjectHash)

        const aux2 = {
          to: fromName !== undefined ? fromName.attributes.contactName : message.attributes.to,
          toAddress: message.attributes.to,
          from: message.attributes.from,
          date: message.attributes.timeHash,
          subject: subjectDecrypted,
          message: message.attributes.messageHash,
          link: message.attributes.linkHash,
          signature: message.attributes.signatureHash,
        };

        aux.push(aux2)
      }
      setMessagesSent(aux)
    } catch (error) {
      console.error(error)
    }
  };

  const sendEncryptedMessageContract = async (_claveSimetricaEncriptadaSender, _claveSimetricaEncriptadaReceiver, to, asuntoEncriptadoSimetrico, mensajeEncriptadoSimetrico, archivoEncriptadoSimetrico, firmaEncriptadoSimetrico, horaEncriptadoSimetrico) => {
    console.log("_claveSimetricaEncriptadaSender", _claveSimetricaEncriptadaSender)
    console.log("_claveSimetricaEncriptadaReceiver", _claveSimetricaEncriptadaReceiver)
    console.log("to", to)
    console.log("asuntoEncriptadoSimetrico", asuntoEncriptadoSimetrico)
    console.log("mensajeEncriptadoSimetrico", mensajeEncriptadoSimetrico)
    console.log("archivoEncriptadoSimetrico", archivoEncriptadoSimetrico)
    console.log("firmaEncriptadoSimetrico",firmaEncriptadoSimetrico)
    console.log("horaEncriptadoSimetrico", horaEncriptadoSimetrico)
    try {
      setOpenProcessingModal({ open: true });
      const signer = await ethersProvider.getSigner();
      let fastestGas = await getMaxGas();
      let contract = new ethers.Contract(contractAddressEncriptado, contractABIEncriptado, signer);
      const tx = await contract.enviarMensaje(
        _claveSimetricaEncriptadaSender,
        _claveSimetricaEncriptadaReceiver,
        to,
        asuntoEncriptadoSimetrico,
        mensajeEncriptadoSimetrico,
        archivoEncriptadoSimetrico,
        firmaEncriptadoSimetrico,
        horaEncriptadoSimetrico,
        { gasPrice: fastestGas }
      );
      await tx.wait();
      setOpenMessageSent({ open: true });
      setOpenProcessingModal({ open: false });
    } catch (error) {
      setOpenErrorMessage({ open: true, data: undefined })
      console.log(error)
      console.error('Error al enviar el mensaje: ', error);
    }
  };

  // Si el FlagEnvioRecepcion es igual a cero implica que quiere los mensajes recibidos, si es distinto de 0 quiere los enviados
  const getMessagesOfBlockchainPaginado = async (_numeroPagina, _largoPagina, _flag) => {
    try {
      const signer = await ethersProvider.getSigner();
      let contract = new ethers.Contract(contractAddressEncriptado, contractABIEncriptado, signer);
      const tx = await contract.getPaginaMensajesEnviadosRecibidos(_numeroPagina, _largoPagina, _flag);
      //Tx es la estructura header front
      return tx;
    } catch (error) {
      console.log(error)
    }
  };

  /*const listenMessageReceivedEvent = async () => {
    const signer = ethersProvider.getSigner();
    let contract = new ethers.Contract(contractAddressEncriptado, contractABIEncriptado, signer);
    const myAddress = await signer.getAddress();
    let filter = contract.filters.messageReceived(
      null,
      myAddress,
      null,
      null,
      null,
      null,
    );
    // EVENTO
    contract.on(filter, async (_from, _to, _subjectHash, _messageHash, _time, _visibility) => {
      alert(`Nuevo mensaje recibido de ${_from.slice(0, 5)}...${_from.slice(37)}`);
      window.location.reload();
    });
  };*/

  const set_email_as_open = async (_moralisId) => {
    try {
      const response = await Moralis.Cloud.run("SetAsOpen", { moralisId: _moralisId });
      if (!response.success) throw Error(JSON.stringify(response.error));
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (currentAccount && privateKey) {
      getLengthSentMessages(currentAccount);
      getLengthReceivedMessages(currentAccount);
    }
  }, [privateKey, currentAccount])

  useEffect(() => {
    if (currentAccount && privateKey && pageSent !== null) {
      getSentMessages(currentAccount, pageSent);
      localStorage.oldPageSent = pageSent;
    }
  }, [privateKey, currentAccount, pageSent]);

  useEffect(() => {
    if (currentAccount && privateKey && pageReceived !== null) {
      getReceivedMessages(pageReceived);
      localStorage.oldPageReceived = pageReceived;
      setWaitLoader(false);
    }
  }, [privateKey, currentAccount, pageReceived]);

  useEffect(() => {
    if (messagesSentV3 && privateKey) {
      console.log(messagesSentV3)
      set_encryptedMessageSentV3(messagesSentV3)
    }
  }, [privateKey, messagesSentV3]);

  useEffect(() => {
    if (messagesReceivedV3 && privateKey) {
      console.log(messagesReceivedV3);
      set_encryptedMessageReceivedV3(messagesReceivedV3)
    }
  }, [privateKey, messagesReceivedV3]);

  useEffect(() => {
    if (messagesSentV3DesencriptadoParcial) {
      console.log("Enviados Desencriptado Parcial", messagesSentV3DesencriptadoParcial);
    }
  }, [messagesSentV3DesencriptadoParcial]);

  useEffect(() => {
    if (messagesReceivedV3DesencriptadoParcial) {
      console.log("Recibidos Desencriptado Parcial", messagesReceivedV3DesencriptadoParcial);
    }
  }, [messagesReceivedV3DesencriptadoParcial]);

  /*React.useEffect(() => {
    if (ethersProvider) listenMessageReceivedEvent();
  }, [ethersProvider])*/

  React.useEffect(() => {
    if (pageSent === null) {
      if (localStorage.oldPageSent != null) setPageSent(localStorage.oldPageSent)
      else setPageSent(1)
    }
  }, []);

  React.useEffect(() => {
    if (pageReceived === null) {
      if (localStorage.oldPageReceived != null) setPageReceived(localStorage.oldPageReceived)
      else setPageReceived(1)
    }
  }, []);



  return (
    <MessageContext.Provider
      value={{
        decryptMessage,
        sendEncryptedMessageContract,
        getSentMessages,
        messagesReceived,
        messagesReceivedV3DesencriptadoParcial,
        encriptedMessages,
        messagesSent,
        messagesSentV3DesencriptadoParcial,
        encriptedMessagesSalida,
        openMessageSent,
        openErrorMessage,
        openProcessingModal,
        //listenMessageReceivedEvent,
        waitLoader,
        set_email_as_open,
        /* paginacion */
        pageReceived,
        setPageReceived,
        messagesReceivedLenght,
        pageSent,
        setPageSent,
        messagesSentLenght,
        limit,
        getMensajeDeEtiqueta,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
