import React from "react";
import "./GoBack.css";
// import { Container, Row, Col } from 'react-bootstrap'
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const GoBack = ({value, link}) => {
  const navigate = useNavigate();
  return (
    <div className="goBack" onClick={() => navigate(link)}>
      <Container>
      <i className="fa-solid fa-chevron-left"></i>
        <h5>{value}</h5>
      </Container>
    </div>
  );
};

export default GoBack;
