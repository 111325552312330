import * as React from 'react'
import { Container } from 'react-bootstrap'
import ConnectScanner from '../components/wallet-connect/ConnectScanner'
import FooterOpciones from '../components/footer-completo/footer-opciones'
import FooterDesktopCompleto from "../components/footer-desktop-completo/footer-desktop-completo";
import GoBack from '../components/goBack/GoBack'
import Navigation from '../components/navigation/Navigation'
import RecentDestinations from '../components/recent-destinations/recent-destinations'
import ConnectInput from '../components/wallet-connect/ConnectInput'
import { useWalletConnectClient } from '../context/walletConnectContext'
import WalletsConnected from '../components/wallet-connect/WalletsConnected'
import SessionProposal from '../components/wallet-connect/SessionProposal'
import BalanceHeader from '../components/balance-header/balance-header';

const WalletConnect = () => {

  const { signClient, openSessionModal, legacySignClient } = useWalletConnectClient();

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 66px)", marginBottom: "66px" }}>
        <Navigation />
        <BalanceHeader />
        <GoBack value="Wallet Connect" link='/home' />
        {/* <RecentDestinations param="connect-wallet" /> */}
        <Container className="p-4 d-flex flex-column justify-content-center align-items-center">
          {
            openSessionModal.open
              ? <SessionProposal />
              : signClient?.session.values.length > 0
                ? <WalletsConnected _sessions={signClient?.session.values} _version={2} />
                : legacySignClient?.session.connected
                  ? <WalletsConnected _sessions={[legacySignClient.session]} _version={1} />
                  : <>
                    <h3 className="text-center title-with-gradient-underline px-2 mb-4">
                      Connect with WalletConnect to <span>make transactions</span>
                    </h3>
                    <ConnectScanner />
                    <ConnectInput />
                  </>
          }
        </Container>
      </div>
      <FooterOpciones />
      <FooterDesktopCompleto />
    </>
  )
}

export default WalletConnect